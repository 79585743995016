import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Errors from '@/Errors'
import Spinner from '@/Spinner'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'

export const initialValues = {
  name: '',
}
export const rules = {
  name: 'required',
}
const Edit = () => {
  const {__} = useTranslations()
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues,
    rules,
  })
  const isLoading = false

  const submit = () => {
    console.log({values})
  }

  if (isLoading) return <Spinner />

  return (
    <div className='p-5'>
      <h2 className='font-semibold text-lg'>{__('Edit')}</h2>
      <Errors errors={errors} />
      <FormGroup label={__('Name')}>
        <Input
          value={values.name}
          onChange={(value: any) => setValue('name', value)}
        />
      </FormGroup>
      <div className='mt-5'>
        <Button onClick={submit}>{__('Save')}</Button>
      </div>
    </div>
  )
}
export default Edit
