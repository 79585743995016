import {ReactNode} from 'react'

type FieldGroupProps = {
  label: string
  children: ReactNode
}
const FieldGroup = ({label, children}: FieldGroupProps) => {
  return (
    <div className='flex justify-between items-center'>
      <b className='font-bold'>{label}:</b>
      <span className='text-lg'>{children}</span>
    </div>
  )
}
export default FieldGroup
