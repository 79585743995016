import {Outlet} from 'react-router-dom'
import Layout from './Layout'

const Index = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  )
}
export default Index
