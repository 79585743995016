import View from '@/View'
import {getGrid, getSquaresToReveal} from '../helpers'
import GridComponent from '@/Grid'
import Column from '@/Column'

const grid = getGrid(10, 10)

type Props = {
  width: number
  revealed: number[]
}
const Grid = ({width, revealed}: Props) => {
  const size = width / 10
  return (
    <Column>
      {grid.map((cols, i) => {
        return (
          <GridComponent
            cols='10'
            className='flex-1'
            key={i}
          >
            {cols.map((item, j) => {
              if (revealed.includes(item.index)) {
                return <View key={j} />
              }
              return (
                <View
                  key={j}
                  className={`${item.shade}`}
                >
                  {/* {i}-{j} */}
                </View>
              )
            })}
          </GridComponent>
        )
      })}
    </Column>
  )
}

export default Grid
