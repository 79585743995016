import {parse, format} from 'date-fns'
import {zeroPad} from 'src/utils/formatHelpers'

export const ISO_FORMAT = 'yyyy-MM-dd'
export const TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const OUTPUT_FORMAT = 'dd/MM/yyyy'
export const LONG_FORMAT = 'dd/MM/yyyy HH:mm'

export const formatDate = (
  date: Date | null = null,
  displayFormat = OUTPUT_FORMAT
) => {
  return format(date || new Date(), displayFormat)
}

export const fromIso = (
  dateString: string | null = null,
  displayFormat = OUTPUT_FORMAT
) => {
  const parsedDate = dateString
    ? parse(dateString, ISO_FORMAT, new Date())
    : new Date()
  return format(parsedDate, displayFormat)
}

export const fromTimestamp = (
  dateString: string | null = null,
  displayFormat = OUTPUT_FORMAT
) => {
  const parsedDate = dateString
    ? parse(dateString, TIMESTAMP_FORMAT, new Date())
    : new Date()
  return format(parsedDate, displayFormat)
}

export const parseDate = (
  dateString: string | null = null,
  format = OUTPUT_FORMAT
) => {
  return dateString ? parse(dateString, format, new Date()) : new Date()
}

const getTimeFormat = (time: string) => {
  switch (time.length) {
    case 5:
      return 'HH:mm'
    case 8:
      return 'HH:mm:ss'
  }
  return null
}

export const parseTime = (time?: string, format?: string | null) => {
  if (time && !format) format = getTimeFormat(time)
  return time && format ? parse(time, format, new Date()) : new Date()
}

export const getTimeFromMilliseconds = (s: number) => {
  let ms = s % 1000
  s = (s - ms) / 1000
  let secs = s % 60
  s = (s - secs) / 60
  let mins = s % 60
  let hrs = (s - mins) / 60
  // return (hrs > 0 ? zeroPad(hrs, 2) + ':' : '') + zeroPad(mins, 2) + ':' + zeroPad(secs, 2)
  return zeroPad(hrs, 2) + ':' + zeroPad(mins, 2) + ':' + zeroPad(secs, 2)
  // return new Date(milliseconds).toISOString().slice(11, -1)
}

// NOTE: little hack function to trim off the time in a date time string
export const trimDate = (date: string, length: number = 10) =>
  date && date.length > length ? date.substring(0, length) : date
// NOTE: little hack function to trim off the sections in a time string
export const trimTime = (time: string, length: number = 5) =>
  time && time.length > length ? time.substring(0, length) : time
