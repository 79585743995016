import {useNavigate} from 'react-router-dom'
import {QuizQuestion} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import {numberToWord} from 'src/utils/numberHelpers'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import SkipLives from './SkipLives'
import Row from '@/Row'
import Column from '@/Column'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'

type Props = {
  questions: QuizQuestion[]
  next: () => void
  lives?: number
}
const SkipIntro = ({questions, next, lives = 3}: Props) => {
  const {__} = useTranslations()
  const navigate = useNavigate()
  const footer = (
    <Row>
      <Button
        key={0}
        className='m-2 flex-grow'
        variant='outline'
        onClick={() => navigate(-1)}
      >
        {__('Back')}
      </Button>
      <Button
        key={1}
        className='m-2 flex-grow'
        onClick={next}
      >
        {__('Next')}
      </Button>
    </Row>
  )
  return (
    <ScrollView footer={footer}>
      <Column className='p-5 h-full'>
        <Heading
          size='h2'
          className='mb-20 mt-20'
        >
          {__('Are you sure you want to skip this list?')}
        </Heading>
        <Column className='text-center'>
          <Paragraph className='text-xl'>
            {__('You are about to take a test on all the words in this list.')}
          </Paragraph>
          <Paragraph className='text-xl'>
            {__('You only have :lives lives, so make them count!', {
              lives: __(numberToWord(lives)),
            })}
          </Paragraph>
        </Column>
        <SkipLives
          lives={lives}
          maxLives={lives}
        />
      </Column>
    </ScrollView>
  )
}
export default SkipIntro
