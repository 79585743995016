import {
  QUESTION_TYPE_FREE_TEXT,
  QUESTION_TYPE_LISTEN,
  QUESTION_TYPE_TRANSLATE_IN,
  QUESTION_TYPE_TRANSLATE_OUT,
} from '../helpers'
import FreeText from './question-types/FreeText'
import Listen from './question-types/Listen'
import Template from './question-types/Template'
import TranslateIn from './question-types/TranslateIn'
import TranslateOut from './question-types/TranslateOut'

type Props = {
  question: any
  translations?: any
  words?: any
  value: any
  setValue: any
  submit: any
  submitted: any
}
const Question = ({question, ...props}: Props) => {
  switch (question.questionType.slug) {
    case QUESTION_TYPE_TRANSLATE_IN:
      return (
        <TranslateIn
          key={question.id}
          question={question}
          translations={props.translations}
          {...props}
        />
      )
    case QUESTION_TYPE_LISTEN:
      return (
        <Listen
          key={question.id}
          question={question}
          translations={props.translations}
          {...props}
        />
      )
    case QUESTION_TYPE_TRANSLATE_OUT:
      return (
        <TranslateOut
          key={question.id}
          question={question}
          words={props.words}
          {...props}
        />
      )
    case QUESTION_TYPE_FREE_TEXT:
      return (
        <FreeText
          key={question.id}
          question={question}
          words={props.words}
          {...props}
        />
      )
  }
  if (question.questionTypeTemplate) {
    // console.log({i: question.index, question})
    return (
      <Template
        // key={question.questionTypeTemplate.id}
        key={question.id}
        // key={question?.index}
        template={question.questionTypeTemplate}
        question={question}
        {...props}
      />
    )
  }
  return null
}

export default Question
