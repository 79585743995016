import {api as generated} from './quiz.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Lists', 'Words'],
  endpoints: {
    createQuiz: {
      invalidatesTags: ['Lists', 'Words'],
    },
  }
})
