import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Toast as ToastType} from 'src/utils/hooks/useToast'

type Props = {
  show: boolean
  toast: ToastType
  remove: (id: string) => void
}
const Toast = ({toast, show, remove}: Props) => {
  if (!show) return
  return (
    <div className='alert alert-success text-white whitespace-normal flex justify-between'>
      <div>{toast.message}</div>
      <button onClick={() => remove(toast.id)}>
        <FontAwesomeIcon icon={icon({name: 'times'})} />
      </button>
    </div>
  )
}
export default Toast
