import {useTranslations} from 'src/utils/hooks'
import Button from './Button'
import classNames from 'classnames'

type Props = {
  options: Array<string>
  value: string
  setValue: any
  disabled: boolean
  onClick?: (item: any) => void
}
const ButtonOptions = ({
  options,
  value,
  setValue,
  disabled,
  ...props
}: Props) => {
  const {__} = useTranslations()
  return (
    // <div space={3} flexWrap={1} justifyContent='center'>
    <div className='justify-center items-center flex flex-wrap'>
      {options.map((item, i) => {
        return (
          <Button
            key={i}
            className={classNames('m-2', {
              'btn-outline': value !== item,
            })}
            // size='lg'
            // mb={3}
            // variant={value === item ? 'solid' : 'outline'}
            onClick={() => {
              setValue(item)
              if (props.onClick) props.onClick(item)
            }}
          >
            {item}
          </Button>
        )
      })}
    </div>
  )
}
export default ButtonOptions
