import React, {useEffect, useState} from 'react'
import sample from 'lodash/sample'

import {useTranslations} from 'src/utils/hooks'

import MultipleChoice from '../answer-types/Multiplechoice'
import {QuestionTypeTemplate, Word} from 'src/queries/schema.generated'
import {ANSWER_TYPE_FREE_TEXT} from '~/quizzes/helpers'
import __, {replaceStrings} from 'src/utils/lang'
import Input from '@/form/Input'
import Button from '@/Button'
import {useFocus} from 'src/utils/useFocus'
import FreeTextInput from '../answer-types/FreeTextInput'
import View from '@/View'
import Column from '@/Column'
import QuestionTitle from './includes/QuestionTitle'

const getTemplateQuestionText = (
  template: QuestionTypeTemplate,
  word: Word
) => {
  return replaceStrings(template.question, word)
}

type AnswerProps = {
  template: any
  value: any
  setValue: any
  submitted: any
  submit: any
}

const AnswerControl = ({
  template,
  value,
  setValue,
  submitted,
  submit,
}: AnswerProps) => {
  const {__} = useTranslations()
  if (template.answer_type_id === ANSWER_TYPE_FREE_TEXT + '') {
    return (
      <FreeTextInput
        value={value}
        setValue={setValue}
        submit={submit}
      />
    )
  }
  return (
    <View className='mx-10'>
      <MultipleChoice
        template={template}
        value={value}
        setValue={setValue}
        submitted={submitted}
      />
    </View>
  )
}

type Props = {
  question: any
  template: any
  value: any
  setValue: any
  submit: any
  submitted: any
}
const Template = ({
  template,
  question,
  value,
  setValue,
  submit,
  submitted,
}: Props) => {
  const {__} = useTranslations()
  const questionText = getTemplateQuestionText(template, question.word)

  return (
    <Column className='h-full justify-between'>
      <QuestionTitle question={questionText} />
      <View className='mx-4 flex-1'>
        <AnswerControl
          template={template}
          value={value}
          setValue={setValue}
          submit={submit}
          submitted={submitted}
        />
      </View>
    </Column>
  )
}

export default Template
