import Heading from '@/Heading'
import Image from '@/Image'
import Link from '@/Link'
import Spinner from '@/Spinner'
import View from '@/View'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useParams} from 'react-router-dom'
import {api} from 'src/queries/picture.enhanced'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import Attribution from './includes/Attribution'
import WebPage from './includes/WebPage'

const Show = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {id} = useParams()
  const {data, isLoading} = api.endpoints.picture.useQuery({id})
  const width = 100

  if (isLoading || !data?.picture) return <Spinner />

  const {picture} = data

  return (
    <>
      <Image
        className='w-full'
        src={picture.url}
        alt={picture.title}
      />
      <Attribution picture={picture} />
      <View className='p-3'>
        <Heading
          size='h2'
          className='mt-0'
        >
          {picture.title}
        </Heading>
        <View className='whitespace-pre-wrap'>{picture.description}</View>
        {picture.link && (
          <View className='mt-3'>
            <Link
              className='link-primary'
              target='_blank'
              href={picture.link}
            >
              <FontAwesomeIcon icon={icon({name: 'link'})} /> Wikipedia
            </Link>
          </View>
        )}
      </View>
    </>
  )
}

export default Show
