import {api as generated} from './word.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Lists', 'Levels', 'revision-words'],
  endpoints: {
    word: {
      providesTags: ['revision-words', 'Lists'],
    },
    moveWord: {
      invalidatesTags: ['Lists'],
    },
    sortWords: {
      invalidatesTags: ['Lists'],
    },
    createWord: {
      invalidatesTags: ['Lists'],
    },
    updateWord: {
      invalidatesTags: ['Lists'],
    },
    deleteWord: {
      invalidatesTags: ['Lists'],
    },
  },
})
