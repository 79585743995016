import React, {useState} from 'react'
import {useTranslations} from 'src/utils/hooks'
import Button from './Button'
import classNames from 'classnames'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'

type Props = {
  options: Array<string>
  value: string
  setValue: any
  disabled: boolean
}
const ButtonGroup = ({options, value, setValue, disabled}: Props) => {
  const {__} = useTranslations()
  const {playSound} = useSounds()
  return (
    <div className='join flex'>
      {options.map((item, i) => {
        return (
          <Button
            key={i}
            className={classNames('join-item flex-1', {
              'btn-outline': value !== item,
            })}
            onClick={() => {
              playSound(Sound.Tap)
              setValue(item)
            }}
            disabled={disabled}
          >
            {item}
          </Button>
        )
      })}
    </div>
  )
  // return (
  //   <Button.Group isAttached isDisabled={disabled}>
  //     {options.map((item, i) => {
  //       return (
  //         <Button key={i}
  //           size='lg'
  //           flex={1}
  //           variant={value === item ? 'solid' : 'outline'}
  //           onPress={() => setValue(item)}>
  //           {item}
  //         </Button>
  //       )
  //     })}
  //   </Button.Group>
  // )
}
export default ButtonGroup
