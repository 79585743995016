import {useState} from 'react'

import {api} from 'src/queries/word.enhanced'
import {Word} from 'src/queries/schema.generated'

type Props = {
  word: Word
  close: () => void
}
const Component = ({word, close}: Props) => {
  const [updateWord] = api.endpoints.updateWord.useMutation()
  const [deleteWord] = api.endpoints.deleteWord.useMutation()
  const [newName, setNewName] = useState<string>(word.word)
  const submit = () => {
    if (!newName) return
    const input = {
      word: newName,
    }
    updateWord({id: word.id, input})
    close()
  }
  return (
    <div className='flex flex-row justify-between gap-2'>
      <input
        className='border px-3 py-1 flex-1'
        type='text'
        placeholder='Add new word'
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') submit()
        }}
      />
      <button
        className='border px-2'
        onClick={() => close()}
      >
        Cancel
      </button>
      <button
        className='bg-green-600 text-white px-2'
        onClick={submit}
      >
        Update
      </button>
      <button
        className='bg-red-600 text-white px-2'
        onClick={() => {
          deleteWord({id: word.id})
          close()
        }}
      >
        Delete
      </button>
    </div>
  )
}
export default Component
