import {ReactNode, createContext, useContext, useEffect, useState} from 'react'
import orderBy from 'lodash/orderBy'
import {useSelector} from 'react-redux'

const synth = window.speechSynthesis

interface SpeechContextType {
  playWord: any
  init: any
  voice: any
  voices: any
  setVoice: any
  enableSpeech: any
}
export const SpeechContext = createContext<SpeechContextType>(
  {} as SpeechContextType
)

type ProviderProps = {
  children: ReactNode
}
export const SpeechProvider = ({children}: ProviderProps) => {
  const [init, setInit] = useState(false)
  const [voice, setVoice] = useState<SpeechSynthesisVoice>()
  const [voices, setVoices] = useState<SpeechSynthesisVoice[]>()
  const {enableSpeech} = useSelector(({preferences}) => preferences)

  useEffect(() => {
    if (!init) {
      setInit(true)
      setSelectedVoices(synth.getVoices())
    }
    synth.onvoiceschanged = function () {
      setSelectedVoices(synth.getVoices())
    }
    // setVoice({
    //   name: 'de-DE',
    //   voiceURI: 'de-DE',
    //   lang: 'de-DE',
    //   localService: true,
    //   default: true,
    // })
  }, [])

  // NOTE: doesn't work
  // useEffect(() => {
  //   if (init && voice) {
  //     // playWord('Hallo')

  //     const timer = setTimeout(() => {
  //       // console.log('testing', voice)
  //       if (!synth.speaking) {
  //         // console.log('no')
  //         // playWord(' ')
  //         playWord('Hallo')
  //         // synth.cancel()
  //       }
  //     }, 1000)
  //     // const utterThis = new SpeechSynthesisUtterance()
  //     // utterThis.text = 'Hallo'
  //     // utterThis.voice = voice
  //     // synth.speak(utterThis)
  //     // synth.cancel()
  //     return () => clearTimeout(timer)
  //   }
  // }, [init, voice])

  const setSelectedVoices = (voices: SpeechSynthesisVoice[]) => {
    setVoices(orderBy(voices, 'lang'))
    const germanVoices = orderBy(
      voices.filter(
        (x) =>
          ['de-DE', 'de_DE'].includes(x.lang) &&
          !x.voiceURI.includes('apple.eloquence')
      ),
      // voices.filter((x) => x.name === 'Anna'),
      'localService' // Choose remote one if available - more natural
    )
    // console.log({voices, germanVoices})
    // const germanVoices = voices.filter(x => x.name ==)
    // if (germanVoices.length === 0) {
    // const testVoices = voices.filter(
    //   (x) => x.lang === 'de-DE' || x.lang === 'de_DE'
    // )
    // setVoice(testVoices?.[0])
    // } else {
    setVoice(germanVoices?.[0])
    // }
  }

  const playWord = (word: string) => {
    if (!voice) return
    const utterThis = new SpeechSynthesisUtterance()
    utterThis.text = word
    utterThis.voice = voice
    utterThis.lang = 'de_DE' // Required for Chrome Android?
    // console.log('speaking', word)
    synth.speak(utterThis)
  }
  const state = {
    playWord,
    init,
    voice,
    voices,
    setVoice,
    enableSpeech,
  }
  return (
    <SpeechContext.Provider value={state}>{children}</SpeechContext.Provider>
  )
}

const useSpeech = () => {
  const {playWord, init, voice, voices, setVoice, enableSpeech} =
    useContext(SpeechContext)

  return {
    playWord,
    init,
    voice,
    voices,
    setVoice,
    enableSpeech,
  }
}
export default useSpeech
