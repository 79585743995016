import classNames from 'classnames'
import {Variant, ViewProps} from './types'

const getDefaults = (variant: Variant) => {
  // const map = {
  //   primary: 'progress progress-primary',
  //   warning: 'progress progress-warning',
  // }
  switch (variant) {
    case 'primary':
      return 'progress progress-primary'
    case 'warning':
      return 'progress progress-warning'
  }
}

interface Props extends ViewProps {
  variant?: Variant
  value: number
}
const Progress = ({variant = 'primary', value, ...props}: Props) => {
  const defaults = getDefaults(variant)
  const className = classNames(defaults, props.className)
  return (
    <progress
      className={className}
      value={value}
      max='100'
    />
  )
}
export default Progress
