import View from '@/View'
import {getPathColour} from '../helpers'

type Props = {
  status: string
  variation: 'l' | 'r'
  locked: boolean
}
const PracticePath = ({status, variation, locked}: Props) => {
  const colour = getPathColour(status)

  return (
    <View className='z-0 relative'>
      <View
        className={`absolute border-0 border-b-2 w-1/3 top-12 ${colour} ${
          variation === 'r' ? 'left-1/2' : 'right-1/2'
        }`}
      />
    </View>
  )
}

export default PracticePath
