import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useTranslations} from 'src/utils/hooks'
import {numberToWord} from 'src/utils/numberHelpers'
import Icon from '@/Icon'
import View from '@/View'
import Span from '@/Span'

type Props = {
  maxLives: number
  lives: number
}
const SkipLives = ({maxLives, lives}: Props) => {
  const {__} = useTranslations()
  const remaining = lives
  const lost = maxLives - remaining
  return (
    <View className='m-10 text-3xl text-center'>
      {[...Array(lives).keys()].map((x, i) => {
        return (
          <Span
            key={i}
            className='mx-3 text-error'
          >
            <FontAwesomeIcon
              size='lg'
              icon={icon({name: 'heart'})}
            />
          </Span>
        )
      })}

      {[...Array(lost).keys()].map((x, i) => {
        return (
          <Span
            key={i + lives}
            className='mx-3 text-gray-300'
          >
            <FontAwesomeIcon
              size='lg'
              icon={icon({name: 'heart'})}
            />
          </Span>
        )
      })}
    </View>
  )
}
export default SkipLives
