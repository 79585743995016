import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {api} from 'src/queries/question.enhanced'
import {api as practiceApi} from 'src/queries/practice-session.enhanced'
import {useTranslations} from 'src/utils/hooks'
import Spinner from '@/Spinner'
import {getEvenChunks} from './helpers'
import PageTitle from '@/PageTitle'
import {List, QuizQuestion} from 'src/queries/schema.generated'
import QuestionChunks from './includes/QuestionChunks'
import {useForm} from 'src/utils/form'
import Results from './Results'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import {selectEnableSpeech} from 'src/reducers/preferences'
import {useSelector} from 'react-redux'
import Intro from './includes/Intro'
import NoQuestions from './includes/NoQuestions'

const CreatePracticeQuiz = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {practiceSessionId} = useParams()
  const {values, setValue} = useForm()
  const [showIntro, setShowIntro] = useState(false)
  const [timeStarted, setTimeStarted] = useState<number>(0)
  const [timeFinished, setTimeFinished] = useState<number>(0)
  const [showResults, setShowResults] = useState(false)
  const [correctQuestions, setCorrectQuestions] = useState<Array<string>>([])
  const {data: practiceData} = practiceApi.endpoints.practiceSession.useQuery({
    id: practiceSessionId,
  })
  const [trigger, {data, isLoading}] = api.endpoints.nextQuestions.useMutation()
  const enableSpeech = useSelector(selectEnableSpeech)

  useEffect(() => {
    if (!practiceData?.practiceSession) return
    setShowIntro(!!practiceData.practiceSession.intro)
    let questionTypes = practiceData.practiceSession.question_types || []
    if (!enableSpeech) questionTypes = questionTypes.concat(['!listen'])
    trigger({
      practiceSessionId,
      questionTypes,
    })
  }, [practiceData])

  useEffect(() => {
    setTimeStarted(Date.now())
  }, [])

  const setFinished = () => {
    setTimeFinished(Date.now())
    setShowResults(true)
  }

  const addCorrect = (id: string | string[]) => {
    setCorrectQuestions(correctQuestions.concat(Array.isArray(id) ? id : [id]))
  }

  const practice = practiceData?.practiceSession
  if (isLoading || !data || !practice) return <Spinner />

  const title = __('Quiz') + ' - ' + practice.name

  const chunks = [data?.nextQuestions]

  // console.log(data?.nextQuestions?.[0]?.word?.word)
  // console.log(chunks)

  // if (data?.nextQuestions.length === 0) return null

  if (showIntro && practiceData?.practiceSession?.intro) {
    return (
      <Intro
        title={practiceData.practiceSession.name || ''}
        intro={practiceData.practiceSession.intro}
        next={() => setShowIntro(false)}
      />
    )
  }

  if (data?.nextQuestions && data.nextQuestions.length === 0) {
    return <NoQuestions />
  }

  if (showResults) {
    return (
      <Results
        // listId={practice.list_id || ''}
        listId={practice.list_id || ''}
        practiceSessionId={practiceSessionId}
        questions={data?.nextQuestions as QuizQuestion[]}
        values={values}
        correctQuestions={correctQuestions}
        time={timeFinished - timeStarted}
      />
    )
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <QuestionChunks
        chunks={chunks}
        // list={(practice.list as List) || null}
        // listId={practice.list_id}
        practiceSessionId={practiceSessionId}
        values={values}
        setValue={setValue}
        setFinished={setFinished}
        addCorrect={addCorrect}
      />
    </>
  )
}

export default CreatePracticeQuiz
