import classNames from 'classnames'
import {HeadingSize, ViewProps} from './types'
import View from './View'

const getDefaults = (size: HeadingSize) => {
  const map = {
    h1: 'text-3xl font-semibold text-center mt-8 mb-4',
    h2: 'text-2xl font-semibold text-center mt-5 mb-3',
    h3: 'text-xl font-semibold text-center mt-3 mb-2',
    h4: 'text-lg font-bold mt-2 mb-1',
    h5: 'text-md font-bold',
    h6: 'text-sm font-bold',
  }
  return map[size]
}

interface Props extends ViewProps {
  size: HeadingSize
}
const Heading = ({size, children, ...props}: Props) => {
  const defaults = getDefaults(size)
  const className = classNames(defaults, props.className)
  const Tag = size
  return (
    <Tag
      {...props}
      className={className}
    >
      {children}
    </Tag>
  )
}
export default Heading
