import Button from '@/Button'
import Column from '@/Column'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'
import Progress from '@/Progress'
import Row from '@/Row'
import ScrollView from '@/ScrollView'
import View from '@/View'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {api as revisionApi} from 'src/queries/revision-word.enhanced'
import {Word} from 'src/queries/schema.generated'
import {api} from 'src/queries/word.enhanced'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import useToast from 'src/utils/hooks/useToast'
import ResetStatsButton from './includes/ResetStatsButton'
import WordPanel from './includes/WordPanel'

const Show = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {id, wordId} = useParams()
  const {data, isLoading, error} = api.endpoints.word.useQuery({id: wordId})
  const [addToRevisionList] =
    revisionApi.endpoints.createRevisionWord.useMutation()
  const [removeFromRevisionList] =
    revisionApi.endpoints.deleteRevisionWord.useMutation()
  const [inList, setInList] = useState<boolean>(false) // Set optimistic updates
  const {add: addToast} = useToast()

  useEffect(() => {
    if (data?.word) {
      setInList(!!data.word.revisionWord)
    }
  }, [data?.word])

  if (!data) return null
  const word = data.word as Word
  if (!word) return null
  const score = word.score
  // const score = {
  //   phase_1: 60,
  //   phase_2: 30,
  // }

  const listId = null
  // console.log({word})
  const footer = (
    <Row>
      <ResetStatsButton word={word} />
    </Row>
  )

  return (
    <ScrollView footer={footer}>
      <Column className='p-8 justify-around'>
        <WordPanel word={word} />
        {/* {score && (
            <View>
              {score.phase_1} : {score.phase_2}
            </View>
          )} */}
        {score ? (
          <Column className='gap-3'>
            <Heading size='h4'>{__('Progress')}:</Heading>
            {score?.phase_1 ? (
              <Progress
                variant='primary'
                value={score.phase_1}
              />
            ) : null}
            {score?.phase_2 ? (
              <Progress
                variant='warning'
                value={score.phase_2}
              />
            ) : null}
          </Column>
        ) : null}
        <Column className='py-3'>
          {inList ? (
            <View className='sm:flex sm:flex-row sm:justify-between sm:items-center'>
              <Paragraph>{__('This word is in your revision list')}.</Paragraph>
              <Button
                className='btn-outline btn-sm'
                onClick={() => {
                  setInList(false)
                  addToast(
                    __(':word has been removed from your revision list.', {
                      word: word.full_word,
                    })
                  )
                  if (word.revisionWord)
                    removeFromRevisionList({id: word.revisionWord.id})
                }}
              >
                {__('Remove')}
              </Button>
            </View>
          ) : (
            <View className='sm:flex sm:flex-row sm:justify-between sm:items-center'>
              <Paragraph>
                {__('This word is not in your revision list')}.
              </Paragraph>
              <Button
                className='btn-outline btn-sm'
                onClick={() => {
                  setInList(true)
                  addToast(
                    __(':word has been added to your revision list.', {
                      word: word.full_word,
                    })
                  )
                  addToRevisionList({
                    word_id: word.id,
                    list_id: listId || null,
                  })
                }}
              >
                {__('Add to list')}
              </Button>
            </View>
          )}
        </Column>
      </Column>
    </ScrollView>
  )
}

export default Show
