import {useTranslations} from 'src/utils/hooks'
import {Translation} from 'src/queries/schema.generated'
import FreeTextInput from '../answer-types/FreeTextInput'
import Column from '@/Column'
import QuestionTitle from './includes/QuestionTitle'
import View from '@/View'

const ANSWER_NUMBER = 7

// English > German
const FreeText = ({
  id,
  question,
  submit,
  value,
  setValue,
  submitted,
  words,
}: any) => {
  const {__} = useTranslations()
  const word = question.word.translations
    .map((translation: Translation) => translation.word)
    .join('; ')

  return (
    <Column className='h-full justify-between'>
      <QuestionTitle question={word} />

      <View className='mx-4 flex-1'>
        <FreeTextInput
          key={question.id}
          value={value}
          setValue={setValue}
          submit={submit}
        />
      </View>
    </Column>
  )
}

export default FreeText
