import {useEffect, useRef, useState} from 'react'
import {getPathColour} from '../helpers'
import View from '@/View'

type Props = {
  status: string
  variation: 'l' | 'r'
  locked: boolean
}
const ListPath = ({variation, locked, status}: Props) => {
  const colour = getPathColour(status)
  const borders = variation === 'r' ? 'border-l-0' : 'border-r-0'
  const [width, setWidth] = useState<number>(0)
  const ref = useRef(null) as any

  useEffect(() => {
    if (!ref.current) return
    const resizeObserver = new ResizeObserver(() => {
      setWidth(ref.current?.offsetWidth || 0)
    })
    resizeObserver.observe(ref.current)
    return () => resizeObserver.disconnect() // clean up
  }, [])

  const cellWidth = width / 3

  const containerStyle = {
    width: width / 2 - cellWidth / 2,
    marginLeft: cellWidth / 2,
    marginRight: cellWidth / 2,
  }
  const pathStyle = {
    left: variation === 'r' ? width / 2 - cellWidth / 2 : 0,
  }
  return (
    <div ref={ref}>
      {/* Using div instead of function component to avoid using forwardRef */}
      <View
        className={`relative z-0`}
        style={containerStyle}
      >
        <View
          className={`absolute border-2 border-b-0 w-full rounded-3xl h-28 -top-12 z-0 ${colour} ${borders}`}
          style={pathStyle}
        />
      </View>
    </div>
  )
}

export default ListPath
