import {ReactNode, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import PageTitle from './PageTitle'

type Props = {
  header?: any
  children: ReactNode
  footer?: any
  ref?: any
  onScrollToBottom?: Function
  height?: string
  title?: string
}

const FloatIcon = ({icon, position}: {icon: any; position?: string}) => {
  return (
    <div className='relative'>
      <div
        className={classNames(
          'absolute opacity-30',
          position || 'bottom-0 right-0'
        )}
      >
        <FontAwesomeIcon
          icon={icon}
          size='2x'
        />
      </div>
    </div>
  )
}

const ScrollView = ({
  header,
  title,
  children,
  footer,
  onScrollToBottom,
  height = 'h-full',
}: Props) => {
  const scrollRef = useRef(null)
  const [showUpArrow, setShowUpArrow] = useState(false)
  const [showDownArrow, setShowDownArrow] = useState(false)
  const checkScrollPosition = () => {
    if (scrollRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = scrollRef.current
      if (scrollTop === 0) {
        setShowUpArrow(false)
      } else {
        setShowUpArrow(true)
      }
      if (scrollTop + clientHeight === scrollHeight) {
        // Reached bottom
        if (onScrollToBottom) onScrollToBottom()
        setShowDownArrow(false)
      } else {
        setShowDownArrow(true)
      }
    }
  }
  useEffect(() => {
    checkScrollPosition()
  }, [children])
  return (
    <>
      {title && <PageTitle>{title}</PageTitle>}
      <div className={`${height} flex flex-col`}>
        {header && <div className=''>{header}</div>}
        {showUpArrow && (
          <FloatIcon
            icon={icon({name: 'caret-up'})}
            position='top-0 right-0'
          />
        )}
        <div
          ref={scrollRef}
          onScroll={() => checkScrollPosition()}
          className='grow overflow-y-auto scrollbar-hide'
        >
          {children}
        </div>
        {showDownArrow && (
          <FloatIcon
            icon={icon({name: 'caret-down'})}
            position='bottom-0 right-0'
          />
        )}
        {footer && <>{footer}</>}
      </div>
    </>
  )
}

export default ScrollView
