import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type UpdateTranslationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SaveTranslationInput;
}>;


export type UpdateTranslationMutation = { __typename?: 'Mutation', updateTranslation?: { __typename?: 'Translation', id: string } | null };

export type TranslationsQueryVariables = Types.Exact<{
  language_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type TranslationsQuery = { __typename?: 'Query', translations: Array<{ __typename?: 'Translation', id: string, word: string, word_id: string, translatedWord?: { __typename?: 'Word', word_type_id?: string | null, id: string, word: string } | null }> };

export type TranslationQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type TranslationQuery = { __typename?: 'Query', translation?: { __typename?: 'Translation', id: string, word: string, word_id: string, translatedWord?: { __typename?: 'Word', word_type_id?: string | null, id: string, word: string } | null } | null };

export type QuizTranslationsMutationVariables = Types.Exact<{
  language_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type QuizTranslationsMutation = { __typename?: 'Mutation', quizTranslations: Array<{ __typename?: 'Translation', id: string, word: string, word_id: string, translatedWord?: { __typename?: 'Word', word_type_id?: string | null, id: string, word: string } | null }> };


export const UpdateTranslationDocument = `
    mutation updateTranslation($id: ID!, $input: SaveTranslationInput!) {
  updateTranslation(id: $id, input: $input) {
    id
  }
}
    `;
export const TranslationsDocument = `
    query translations($language_id: ID, $list_id: ID, $limit: Int) {
  translations(language_id: $language_id, list_id: $list_id, limit: $limit) {
    id
    word
    word_id
    translatedWord {
      word_type_id
      id
      word
    }
  }
}
    `;
export const TranslationDocument = `
    query translation($id: ID) {
  translation(id: $id) {
    id
    word
    word_id
    translatedWord {
      word_type_id
      id
      word
    }
  }
}
    `;
export const QuizTranslationsDocument = `
    mutation quizTranslations($language_id: ID, $list_id: ID, $limit: Int) {
  quizTranslations(language_id: $language_id, list_id: $list_id, limit: $limit) {
    id
    word
    word_id
    translatedWord {
      word_type_id
      id
      word
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateTranslation: build.mutation<UpdateTranslationMutation, UpdateTranslationMutationVariables>({
      query: (variables) => ({ document: UpdateTranslationDocument, variables })
    }),
    translations: build.query<TranslationsQuery, TranslationsQueryVariables | void>({
      query: (variables) => ({ document: TranslationsDocument, variables })
    }),
    translation: build.query<TranslationQuery, TranslationQueryVariables | void>({
      query: (variables) => ({ document: TranslationDocument, variables })
    }),
    quizTranslations: build.mutation<QuizTranslationsMutation, QuizTranslationsMutationVariables | void>({
      query: (variables) => ({ document: QuizTranslationsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


