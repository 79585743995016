import {useDispatch, useSelector} from 'react-redux'
import {selectSearch, setValue} from 'src/reducers/search'

const Search = () => {
  const dispatch = useDispatch()
  const search = useSelector(selectSearch)

  return (
    <div>
      <div className='flex gap-2 items-center'>
        Search:
        <input
          className='border px-3 py-1 flex-1'
          type='text'
          name='word'
          placeholder='Word'
          value={search.word}
          onChange={(e) => dispatch(setValue('word', e.target.value))}
          autoComplete='off'
        />
        <input
          className='border px-3 py-1 flex-1'
          type='text'
          placeholder='Translation'
          name='translation'
          value={search.translation}
          onChange={(e) => dispatch(setValue('translation', e.target.value))}
          autoComplete='off'
        />
      </div>
    </div>
  )
}
export default Search
