import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {useState} from 'react'

import Button from '@/Button'
import Column from '@/Column'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'
import Pressable from '@/Pressable'
import Row from '@/Row'
import ScrollView from '@/ScrollView'
import Span from '@/Span'
import Spinner from '@/Spinner'
import View from '@/View'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {api as questionTypesApi} from 'src/queries/question-type.generated'
import {api} from 'src/queries/revision-word.enhanced'
import {QuestionType} from 'src/queries/schema.generated'
import {selectQuestiontypes} from 'src/reducers/preferences'
import {revisionListQuiz, revisionListSettings} from 'src/routes/revision-lists'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import WordRow from '~/words/includes/WordRow'
import {getQuestionTypeName} from './helpers'

// import ConfirmRemoveModal from './includes/ConfirmRemoveModal'
// import Word from 'features/words/includes/Word'

const RevisionList = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const navigate = useNavigate()
  const {data, isLoading, refetch} = api.endpoints.revisionWords.useQuery()
  const [remove, {isLoading: removeIsLoading}] =
    api.endpoints.deleteRevisionWord.useMutation()
  const {data: questionTypeData} =
    questionTypesApi.endpoints.questionTypes.useQuery()
  const [removeItem, setRemoveItem] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(false) // NOTE: had to put this in to fix a glitch when an item is removed
  const questionTypes = useSelector(selectQuestiontypes) as string[]

  if (loading || isLoading || removeIsLoading || !data) return <Spinner />

  const {revisionWords} = data

  const questionTypesFilter = (questionTypeData?.questionTypes || [])
    .filter((x: QuestionType) => (questionTypes || []).includes(x.slug))
    .map((x) => getQuestionTypeName(x.name))
    .sort()

  if (revisionWords.length === 0) {
    return (
      <Column className='mt-8 mx-5 sm:mx-8 gap-2 italic'>
        <Paragraph>
          {__('There are no words added to the revision list yet')}.
        </Paragraph>
        <Paragraph>
          {__('To add a word to the list, select the list icon')}{' '}
          <Span className='text-primary'>
            <FontAwesomeIcon icon={icon({name: 'list'})} />{' '}
          </Span>
          {__('next to it when reviewing a word list or answers')}.
        </Paragraph>
      </Column>
    )
  }

  const footer = (
    <Button
      className='m-2'
      onClick={() => navigate(revisionListQuiz())}
    >
      {__('Start quiz')}
    </Button>
  )

  return (
    <ScrollView
      footer={footer}
      title={__('Revision list')}
    >
      <View>
        <Row className='justify-center items-center gap-3'>
          <Heading size='h2'>{__('Revision list')}</Heading>
          <Link
            to={revisionListSettings()}
            className='text-primary text-sm mt-2'
          >
            <FontAwesomeIcon icon={icon({name: 'cog'})} />
          </Link>
        </Row>
        <Column>
          {questionTypes.length > 0 ? (
            <View className='flex flex-col mx-3 sm:gap-4 sm:flex-row sm:items-center sm:justify-between'>
              <View>{__('Filtered for question types')}:</View>
              <View className='text-sm italic sm:text-right'>
                {questionTypesFilter.join('; ')}{' '}
                <Link
                  className='text-primary'
                  to={revisionListSettings()}
                >
                  [{__('Edit')}]
                </Link>
              </View>
            </View>
          ) : null}
          {revisionWords.map((word, i) => {
            if (!word?.word) return null
            return (
              <WordRow
                key={i}
                word={word.word}
                isRevisionList={true}
              />
            )
          })}
        </Column>
      </View>
    </ScrollView>
  )
}
export default RevisionList
