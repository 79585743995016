import {ReactNode} from 'react'
import {KeyLabel} from 'src/utils/form/utils/types'

type Props = {
  items: KeyLabel[]
  placeholder?: string
  value: string
  onChange: (value: any) => void
}
const Select = ({items, placeholder, value, onChange}: Props) => {
  return (
    <select
      className='select select-bordered w-full max-w-xs'
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    >
      {items.map((item, i) => {
        return (
          <option
            key={i}
            value={item.key}
          >
            {item.label}
          </option>
        )
      })}
    </select>
  )
}
export default Select
