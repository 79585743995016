import {ReactNode} from 'react'
import Column from '@/Column'
import Toasts from '@/Toasts'
import View from '@/View'
import Row from '@/Row'
import TopNav from './TopNav'

const Layout = ({children}: {children: ReactNode}) => {
  return (
    <Row className='w-full h-full justify-center bg-slate-100'>
      <Column className='w-full sm:w-[640px] shadow-lg bg-white'>
        <TopNav />
        <Toasts />
        <View className='overflow-y-auto h-[calc(100dvh-66px)] pb-4'>
          {children}
        </View>
      </Column>
    </Row>
  )
}
export default Layout
