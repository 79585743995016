import useToast from 'src/utils/hooks/useToast'
import Toast from './Toast'

const Toasts = () => {
  const {remove, queue} = useToast()
  return (
    <div className='toast toast-top toast-center z-10 w-full px-10'>
      {queue.map((toast, i) => (
        <Toast
          key={toast.id}
          toast={toast}
          show={true}
          remove={remove}
        />
      ))}
    </div>
  )
}
export default Toasts
