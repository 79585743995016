import {differenceInDays} from 'date-fns'
import groupBy from 'lodash/groupBy'
import mean from 'lodash/mean'
import sample from 'lodash/sample'
import shuffle from 'lodash/shuffle'
import sortBy from 'lodash/sortBy'
import sum from 'lodash/sum'
import {
  Question,
  QuestionType,
  Quiz,
  QuizQuestion,
  Word,
  WordType,
} from 'src/queries/schema.generated'
import {v4 as uuid4} from 'uuid'

import {
  TIMESTAMP_FORMAT,
  parseDate,
  formatDate,
  trimDate,
} from 'src/utils/dateHelpers'
import {chunk} from 'lodash'
import {cleanString, isSimilar, normalizeString} from 'src/utils/stringHelpers'

export const PROGRESS_STATUS_LOCKED = 1
export const PROGRESS_STATUS_READY = 2
export const PROGRESS_STATUS_IN_PROGRESS = 3
export const PROGRESS_STATUS_COMPLETE = 4

export const ANSWER_TYPE_MULTIPLE_CHOICE = 1
export const ANSWER_TYPE_FREE_TEXT = 2

export const QUESTION_TYPE_TRANSLATE_IN = 'translate-in'
export const QUESTION_TYPE_TRANSLATE_OUT = 'translate-out'
export const QUESTION_TYPE_LISTEN = 'listen'
export const QUESTION_TYPE_FREE_TEXT = 'free-text'

export const WORD_TYPE_NOUN = 1

export const PASS_THRESHOLD = 100
export const NUMBER_OF_QUESTIONS = 10
export const PERCENT_THRESHOLD = 75

// export const createPracticeQuestions = (words: Array<Word>, questionTypes: Array<QuestionType>) => {
//   // console.log(JSON.stringify(words[0], null, 2))
//   words = words.filter(word => (word.score?.phase_1 || 0) >= PASS_THRESHOLD)
//   // console.log('count', words.length)
//   // console.log({words})
//   // const words =
//   return createQuestions(words, questionTypes)
// }

const getRandomQuestion = (
  word: Word,
  questionTypes: Array<QuestionType>,
  i = 0
): any => {
  const questionType = sample(getQuestionTypesForWord(word, questionTypes))
  if (!questionType) return null
  // const questionType = questionTypes.find(type => type.slug === questionTypeSlug)
  const options = getQuestionTypeOptions(questionType, word)
  if (!options.accepted_answers && i < 3) {
    return getRandomQuestion(word, questionTypes, i + 1)
  }
  if (!options.accepted_answers) return null
  const template = sample(questionType.templates)
  return {
    questionType,
    template,
    ...options,
  }
}

export const createQuestions = (
  fromWords: Array<any>,
  questionTypes: Array<any>
) => {
  // Sort the words and take a sample that's double the question count (if possible)
  // To create a randomised list, but of items in priority order
  const wordsWithScores = fromWords.map((x) => ({
    ...x,
    phase1Score: x.score?.phase_1 || 0,
    phase2Score: x.score?.phase_2 || 0,
  }))
  const words = sortBy(wordsWithScores, ['phase1Score', 'phase2Score'])

  const count = words.length
  const sampleSize = Math.min(count, NUMBER_OF_QUESTIONS * 1.5)
  const sampleList = sampleSize < count ? words.slice(0, sampleSize) : words
  const number = Math.min(count, NUMBER_OF_QUESTIONS)
  const items = shuffle(sampleList).slice(0, number)

  return items
    .map((word) => {
      const questionData = getRandomQuestion(word, questionTypes)
      if (!questionData) return null
      return {
        id: uuid4(),
        word,
        word_id: word.id,
        question_type_id: questionData?.questionType?.id || null,
        ...questionData,
      }
    })
    .filter((x) => x)
}

const getQuestionTypesForWord = (
  word: Word,
  questionTypes: Array<QuestionType>
) => {
  return questionTypes.filter(
    (type) => !type.word_type_id || type.word_type_id === word.word_type_id
  )
}

const getQuestionTypeOptions = (questionType: QuestionType, word: Word) => {
  switch (questionType?.slug) {
    case 'german-definite-article-nominative':
      return {
        accepted_answers: word.article,
      }
    case 'german-indefinite-article-nominative':
      return {
        accepted_answers: word.article === 'die' ? 'eine' : 'ein',
      }
    case 'german-definite-article-accusative':
      return {
        accepted_answers: word.article === 'der' ? 'den' : word.article,
      }
    case 'german-plurals':
      return {
        accepted_answers: word.plural,
      }
    case QUESTION_TYPE_TRANSLATE_IN:
      return {
        accepted_answers: (word.translations || [])
          .map((translation) => translation?.word)
          .join('|'),
      }
    case QUESTION_TYPE_TRANSLATE_OUT:
      return {
        accepted_answers: word.word,
      }
  }
  return {}
}

const last7Days = (date: string) =>
  differenceInDays(new Date(), parseDate(date, TIMESTAMP_FORMAT)) <= 7
const last30Days = (date: string) =>
  differenceInDays(new Date(), parseDate(date, TIMESTAMP_FORMAT)) <= 30

export const calculateWordScores = (
  questions: Array<Question>,
  allQuizzes: Array<Quiz>
) => {
  // console.log({quiz, allQuizzes})
  // console.log(JSON.stringify(questions, null, 2))
  const words = questions.map((x) => x.word).filter((x) => x) as Word[]
  const date = formatDate(null, TIMESTAMP_FORMAT)
  return words.map((word) => {
    const phase_1 = calculatePhase1(word, allQuizzes)
    const phase_2 = calculatePhase2(word, allQuizzes)
    return {
      word_id: word.id,
      phase_1,
      phase_2,
      phase_1_completed_at: phase_1 >= PASS_THRESHOLD ? date : null,
      phase_2_completed_at: phase_2 >= PASS_THRESHOLD ? date : null,
    }
  })
}

export const calculatePhase1 = (word: Word, allQuizzes: Array<Quiz>) => {
  if ((word?.score?.phase_1 || 0) === 100) return 100 // Don't process if already passed
  const questionsWithWord = allQuizzes
    .filter((quiz) => last7Days(quiz.date))
    .flatMap((x: any) => x.questions.filter((y: any) => y.word_id === word.id))
  const correct = questionsWithWord.filter((x) => x.correct).length
  return calculateScore(questionsWithWord.length, correct)
}

export const calculatePhase2 = (word: Word, allQuizzes: Array<Quiz>) => {
  if ((word?.score?.phase_2 || 0) === 100) return 100 // Don't process if already passed
  if (!word?.score || !word.score?.phase_1_completed_at) return 0 // Don't process if phase 1 not passed
  const questionsInRange = allQuizzes.filter((quiz) => {
    const isSincePhase1 =
      trimDate(quiz.date) > trimDate(word.score?.phase_1_completed_at)
    // console.log(trimDate(quiz.date), trimDate(word.score.phase_1_completed_at), isSincePhase1)
    return isSincePhase1 && last30Days(quiz.date)
  })
  // console.log(JSON.stringify({word, questionsInRange}, null, 2))
  const mapped = questionsInRange
    .map((quiz) => {
      const answers = (quiz?.questions || []).filter(
        (item: any) => item.word_id === word.id
      )
      return {
        date: trimDate(quiz.date),
        total: answers.length,
        correct: answers.map((x: any) => x.correct).length,
      }
    })
    .filter((x) => x.total)
  // console.log(JSON.stringify(mapped, null, 2))
  // const getDistinctValues = groupBy(questionsInRange, (item) => trimDate(item))
  const grouped = groupBy(mapped, 'date')
  const distinctValues = Object.values(grouped).map((items: Array<any>) => {
    return {
      total: mean(items.map((x) => x.total)),
      correct: mean(items.map((x) => x.correct)),
    }
  })
  const total = sum(distinctValues.map((x) => x.total))
  const correct = sum(distinctValues.map((x) => x.correct))
  return calculateScore(total, correct)
}

// export const calculateScore = (questions): number => {
export const calculateScore = (total: number, correct: number): number => {
  // const total = questions.length
  // const correct = questions.filter(x => x.correct).length
  const incorrect = total - correct
  const percent = Math.floor((correct / total) * 100)
  // console.log({correct, total, incorrect, percent})
  if (incorrect === 0) return Math.min((correct / 5) * 100, 100)
  if (correct >= 8 && percent >= PERCENT_THRESHOLD) return 100
  const totalRequired = Math.max(
    8,
    (incorrect / (100 - PERCENT_THRESHOLD)) * 100
  )
  // console.log({totalRequired})
  return Math.round((correct / totalRequired) * 100)
  // return {
  //   // total,
  //   correct,
  //   incorrect,
  //   // percent,
  //   phase_1: Math.round(correct / totalRequired * 100)
  // }
}

export const getChunkSize = (total: number, maxSize: number) => {
  if (total < maxSize + 1) return total
  if (total % maxSize === 0) return maxSize // bias 4 over 3 (e.g. for 12)
  if (total % (maxSize - 1) === 0) return maxSize - 1
  if (total % maxSize > 1) return maxSize
  if (total % (maxSize - 1) > 1) return maxSize - 1
  // if (total === 13) return 3
  return maxSize
}

export const getEvenChunks = (items: any[], maxSize: number) => {
  // console.log({maxSize})
  const chunkSize = getChunkSize(items.length, maxSize)
  let chunks = chunk(items, chunkSize)
  if (chunks.length > 2 && chunks[chunks.length - 1].length < 3) {
    let penultimateChunk = chunks[chunks.length - 2]
    let lastChunk = chunks[chunks.length - 1]
    let lastItem = penultimateChunk[penultimateChunk.length - 1]
    penultimateChunk = penultimateChunk.slice(0, -1)
    lastChunk = [lastItem, ...lastChunk]
    // console.log(penultimateChunk, lastChunk, lastItem)
    chunks = [...chunks.slice(0, -2), penultimateChunk, lastChunk]
  }
  return chunks
}

export const isCorrect = (
  answer: string,
  acceptedAnswers: string,
  questionType?: string
) => {
  const clean = normalizeString(answer)
  const accepted = (acceptedAnswers || '').split('|').map(normalizeString)

  // console.log({clean, accepted})
  switch (questionType) {
    case QUESTION_TYPE_FREE_TEXT:
      return accepted.some((item) => isSimilar(clean, item))
  }
  return accepted.includes(clean)
}

export const isExactMatch = (answer: string, acceptedAnswers: string) => {
  const clean = cleanString(answer)
  const accepted = (acceptedAnswers || '').split('|').map(cleanString)
  return accepted.includes(clean)
  // return accepted.find((x) => x === clean) || null
}

export const getAcceptedAnswers = (acceptedAnswers: string): string[] => {
  const items = acceptedAnswers.split('|')
  return items || []
}
