import {Link} from 'react-router-dom'
import {Picture} from 'src/queries/schema.generated'
import {picturesShow} from 'src/routes/pictures'
import Image from '@/Image'

type Props = {
  picture: Picture
}
const ListItem = ({picture}: Props) => {
  return (
    <Link to={picturesShow(picture.id)}>
      <Image
        className='object-cover h-full w-full'
        src={picture.url}
        alt={picture.title}
      />
    </Link>
  )
}
export default ListItem
