import {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {List, Translation} from 'src/queries/schema.generated'
import {api} from 'src/queries/word.enhanced'
import {selectSearch} from 'src/reducers/search'
import SearchResultItem from './SearchResultItem'

const SearchResults = () => {
  const search = useSelector(selectSearch)
  const [trigger, {data, isLoading, error}] =
    api.endpoints.searchWords.useLazyQuery()
  useEffect(() => {
    trigger(search)
  }, [search])

  if (!data?.searchWords) return null
  return (
    <div>
      {data.searchWords?.map((word: any, i: number) => {
        return (
          <SearchResultItem
            key={i}
            word={word}
          />
        )
      })}
    </div>
  )
}
export default SearchResults
