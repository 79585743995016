import {api as generated} from './level.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Levels', 'Lists'],
  endpoints: {
    levels: {
      providesTags: ['Levels'],
    },
    level: {
      providesTags: ['Levels'],
    },
    sortListsAlphabetic: {
      invalidatesTags: ['Levels'],
    },
  },
})
