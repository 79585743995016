import {url} from 'src/config'
import Dashboard from '~/lists/Dashboard'
import Words from '~/lists/Words'
import WordsShow from '~/words/Show'
import CreateListQuiz from '~/quizzes/CreateListQuiz'

export default [
  // {
  //   path: 'lists/:id',
  //   element: <Show />,
  //   children: [
  //     {path: '', element: <Dashboard />},
  //     {path: 'words', element: <Words />},
  //   ],
  // },
  {path: 'lists/:id', element: <Dashboard />},
  {path: 'lists/:id/words', element: <Words />},
  {path: 'lists/:id/words/:wordId', element: <WordsShow />},
  {path: 'words/:wordId', element: <WordsShow />},
  {path: 'lists/:listId/quiz', element: <CreateListQuiz />},
  {path: 'lists/:listId/skip', element: <CreateListQuiz skip={true} />},
]

export const listsShow = (id: string) => `${url}lists/${id}`
export const listsWords = (id: string) => `${url}lists/${id}/words`
export const wordsShow = (id: string, listId?: string) => {
  if (listId) return `${url}lists/${listId}/words/${id}`
  return `${url}words/${id}`
}
export const quizzesCreate = (listId: string) => `${url}lists/${listId}/quiz`
export const quizzesSkip = (listId: string) => `${url}lists/${listId}/skip`
