import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useParams} from 'react-router-dom'
import {api} from 'src/queries/word.enhanced'
import {api as revisionApi} from 'src/queries/revision-word.enhanced'
import {useTranslations, useSpeech} from 'src/utils/hooks'
import Button from '@/Button'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {Translation, Word} from 'src/queries/schema.generated'
import {DirectiveLocation} from 'graphql'
import FieldGroup from '@/FieldGroup'
import {useEffect, useState} from 'react'
import useToast from 'src/utils/hooks/useToast'
import ScrollView from '@/ScrollView'
import View from '@/View'
import Pressable from '@/Pressable'
import Heading from '@/Heading'
import Column from '@/Column'

type Props = {
  word: Word
}
const WordPanel = ({word}: Props) => {
  const {__} = useTranslations()
  const {
    playWord,
    voices,
    voice,
    setVoice,
    init: speechInit,
    enableSpeech,
  } = useSpeech()

  useEffect(() => {
    if (!speechInit || !enableSpeech) return
    playWord(word.full_word)
  }, [word.id, speechInit])

  const score = word.score
  const debugSpeech = false

  return (
    <View className={debugSpeech ? 'h-full' : ''}>
      <Heading size='h1'>
        {word.full_word}{' '}
        <Pressable
          className='text-primary'
          onClick={() => playWord(word.full_word)}
        >
          <FontAwesomeIcon icon={icon({name: 'ear-listen'})} />
        </Pressable>
      </Heading>
      {word.plural && (
        <Heading size='h3'>
          die {word.plural}{' '}
          <Pressable
            className='text-primary'
            onClick={() => playWord('die ' + word.plural)}
          >
            <FontAwesomeIcon icon={icon({name: 'ear-listen'})} />
          </Pressable>
        </Heading>
      )}
      <Column className='flex-1 gap-3'>
        <View className='italic text-2xl text-center'>
          <>
            {(word.translations || ([] as Translation[]))
              .map((x: any) => x.word)
              .join('; ')}
          </>
          <>
            {debugSpeech && (
              <Pressable
                className='text-primary ml-1'
                onClick={() =>
                  word?.translations &&
                  playWord(
                    word.translations.map((x) => x?.word || '')?.join(' ')
                  )
                }
              >
                <FontAwesomeIcon icon={icon({name: 'ear-listen'})} />
              </Pressable>
            )}
          </>
        </View>
        {word.wordType && (
          <FieldGroup label={__('Word type')}>{word.wordType.name}</FieldGroup>
        )}
        {/* {word.plural && (
          <FieldGroup label={__('Plural')}>{word.plural}</FieldGroup>
        )} */}
        {debugSpeech && (
          <View>
            <View>{voice?.name}</View>
            {voices?.map((x: any, i: number) => {
              return (
                <View key={i}>
                  <Pressable
                    className='btn btn-link'
                    onClick={() => setVoice(x)}
                  >
                    {x.lang} {x.name} {x.voiceURI} {x.localService ? '[l]' : ''}{' '}
                    {x.default ? '[d]' : ''}
                  </Pressable>
                </View>
              )
            })}
          </View>
        )}
      </Column>
    </View>
  )
}
export default WordPanel
