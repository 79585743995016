import React, {ReactNode, useEffect, useRef} from 'react'
import useTranslations from 'src/utils/hooks/useTranslations'
import Button from './Button'

type Props = {
  visible: boolean
  setVisible: Function
  title: string
  children: ReactNode
  actions?: Array<any>
  size?: any
  preventClose?: boolean
}
const Modal = ({
  visible,
  setVisible,
  title,
  children,
  actions,
  size,
  ...props
}: Props) => {
  const {__} = useTranslations()

  const modalRef = useRef(null) as any

  useEffect(() => {
    if (!modalRef.current) return
    visible ? modalRef.current.showModal() : modalRef.current.close()
  }, [visible])

  const handleClose = () => {
    if (setVisible) setVisible(false)
  }

  const handleEsc = (event: any) => {
    event.preventDefault()
    handleClose()
  }

  return (
    <dialog
      ref={modalRef}
      id='my_modal_1'
      className='modal'
      onCancel={handleEsc}
    >
      <form
        method='dialog'
        className='modal-box'
      >
        <h3 className='font-bold text-lg mb-8 text-center'>{title}</h3>
        {children}
        <div className='modal-action'>
          {actions || (
            <Button
              className='btn-ghost'
              onClick={() => setVisible(false)}
            >
              {__('OK')}
            </Button>
          )}
        </div>
      </form>
    </dialog>
  )
}

export default Modal
