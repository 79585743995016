import classNames from 'classnames'
import {ViewProps} from './types'

interface Props extends ViewProps {
  src: string
  alt: string
}
const Image = ({src, alt, ...props}: Props) => {
  const defaults = ''
  const className = classNames(defaults, props.className)
  return (
    <img
      {...props}
      src={src}
      alt={alt}
      className={className}
    />
  )
}
export default Image
