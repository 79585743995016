import Button from '@/Button'
import Column from '@/Column'
import Paragraph from '@/Paragraph'
import {useNavigate} from 'react-router-dom'
import {useTranslations} from 'src/utils/hooks'

const NoQuestions = () => {
  const {__} = useTranslations()
  const navigate = useNavigate()
  return (
    <Column className='h-full px-5 pb-10 justify-center items-center gap-6'>
      <Paragraph className='text-center'>
        {__('There are no valid questions for the criteria you provided.')}
      </Paragraph>
      <Paragraph className='text-center'>{__('Please try again.')}</Paragraph>
      <Button
        className='mt-3'
        onClick={() => navigate(-1)}
      >
        {__('Go back')}
      </Button>
    </Column>
  )
}
export default NoQuestions
