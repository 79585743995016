import {useState} from 'react'
import {Translation, Word} from 'src/queries/schema.generated'
import EditTranslation from './EditTranslation'

type Props = {
  word: Word
  editing: boolean
  setEditing: (value: boolean) => void
}
const Component = ({word, editing, setEditing}: Props) => {
  // const [editing, setEditing] = useState(false)

  if (!word.translations || (word?.translations || []).length === 0) return null

  if (editing) {
    return (
      <div>
        {word.translations.map((translation: any, i: number) => {
          return (
            <EditTranslation
              key={i}
              translation={translation}
              close={() => setEditing(false)}
            />
          )
        })}
      </div>
    )
  }
  return (
    <div onClick={() => setEditing(true)}>
      {word.translations.map((translation: any) => translation.word).join('; ')}
    </div>
  )
}

export default Component
