import {useEffect, useRef, useState} from 'react'
import {arrayMoveImmutable} from 'array-move'

import {api} from 'src/queries/list.enhanced'
import {api as levelApi} from 'src/queries/level.enhanced'
import {Level, List} from 'src/queries/schema.generated'

import ListComponent from '~/admin/lists/includes/List'
import {cancelDefault} from 'src/utils'
import NewList from '../../lists/includes/NewList'
import Edit from './Edit'

type Props = {
  level: Level
  version: number
  lists: Array<any>
}
const Component = ({level, version, lists}: Props) => {
  const [showLists, setShowLists] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [listIds, setListIds] = useState<Array<string>>(
    lists.map((list) => list.id)
  )
  const [updateList] = api.endpoints.updateList.useMutation()
  const [sortLists] = api.endpoints.sortLists.useMutation()
  const [sortListsAlphabetic] =
    levelApi.endpoints.sortListsAlphabetic.useMutation()
  const [newName, setNewName] = useState<string>('')
  const [trigger, {data, isLoading, error}] =
    levelApi.endpoints.level.useLazyQuery()

  useEffect(() => {
    setListIds(lists.map((list) => list.id))
  }, [lists])
  // const [height, setHeight] = useState(0)
  // const ref = useRef(null)

  // const lists = data?.level?.lists
  const itemHeight = 42 // TODO
  const itemsHeight = lists.length * itemHeight // TODO

  const handleDrop = (e: React.DragEvent) => {
    const type = e.dataTransfer.getData('entityType')
    const id = e.dataTransfer.getData('entityId')
    console.log('handleOnDrop', {type, id})
    switch (type) {
      case 'list':
        const input = {
          level_id: level.id,
          version,
        }
        return updateList({id, input})
      // case 'word':
      //   console.log('move word to list', id, item.id)
    }
    // console.log('dropped', e, type, id)
  }

  const toggleLists = () => {
    if (!showLists) trigger({id: level.id})
    setShowLists(!showLists)
  }

  const sortList = (fromIndex: number, toIndex: number) => {
    const ids = arrayMoveImmutable(listIds, fromIndex, toIndex)
    sortLists({ids})
    setListIds(ids)
    // console.log(listIds, ids)
  }
  return (
    <div className='border p-1 bg-slate-50'>
      <div className={`flex justify-between border p-2`}>
        <h3 className='text-xl font-bold mx-1'>
          Level {level.name}
          {lists && <span> ({lists?.length})</span>}
        </h3>
        <div className='flex gap-2'>
          {/* <button
            className='text-sm font-normal px-2 bg-blue-600 text-white'
            onClick={() => setShowEdit(!showEdit)}
          >
            Edit
          </button> */}
          {/* <button
            className='text-sm font-normal border px-2'
            onClick={() => sortListsAlphabetic({id: level.id})}
          >
            Sort
          </button> */}
          <button
            className='text-sm font-normal border px-2'
            onClick={() => toggleLists()}
          >
            {showLists ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      {showEdit ? (
        <div>
          <Edit />
        </div>
      ) : (
        showLists && (
          <>
            <ul
              className='bg-white border'
              style={{minHeight: itemsHeight + itemHeight * 2}}
              onDrop={handleDrop}
              onDragOver={cancelDefault}
            >
              {lists.map((list: any, i) => {
                return (
                  <ListComponent
                    key={list.id}
                    item={list}
                    index={i}
                    sortList={sortList}
                  />
                  // <div key={i}>{list.name}</div>
                )
              })}
            </ul>
            <NewList
              level={level}
              version={version}
            />
          </>
        )
      )}
    </div>
  )
}
export default Component
