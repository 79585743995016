import {api as generated} from './list.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Levels', 'Lists'],
  endpoints: {
    list: {
      providesTags: ['Lists'],
    },
    createList: {
      invalidatesTags: ['Levels'],
    },
    updateList: {
      invalidatesTags: ['Levels'],
      // async onQueryStarted({ id, input }, { dispatch, queryFulfilled }) {
      //   const patchResult = dispatch(
      //     api.util.updateQueryData('list', id as any, (draft) => {
      //       Object.assign(draft, input)
      //     })
      //   )
      //   console.log({id, input})
      //   try {
      //     await queryFulfilled
      //   } catch {
      //     patchResult.undo()
      //   }
      // },
    },
    deleteList: {
      invalidatesTags: ['Levels'],
    },
    sortLists: {
      invalidatesTags: ['Levels'],
    },
    sortWordsAlphabetic: {
      invalidatesTags: ['Lists'],
    },
    saveListProgress: {
      invalidatesTags: ['Lists'],
    },
  },
})
