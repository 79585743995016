import Button from '@/Button'
import Column from '@/Column'
import Errors from '@/Errors'
import Heading from '@/Heading'
import ScrollView from '@/ScrollView'
import Spinner from '@/Spinner'
import FormContainer from '@/form/FormContainer'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'
import pick from 'lodash/pick'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {api} from 'src/queries/user.generated'
import {selectUser, setToken, setUser} from 'src/reducers/user'
import {index} from 'src/routes/app'
import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'

export const initialValues = {
  name: '',
  email: '',
  password: '',
  password_confirm: '',
}

export const rules = {
  name: 'required',
  email: 'required|email',
  password: 'required|min:8',
  password_confirm: 'required',
}

const CreateAccount = () => {
  const {__} = useTranslations()
  const [update, {isLoading}] = api.endpoints.createUser.useMutation()
  const user = useSelector(selectUser)
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues,
    rules,
  })
  const navigate = useNavigate()
  // const [apiErrors, setApiErrors] = useState([])
  const dispatch = useDispatch()

  const submit = () => {
    const passwordMatchError =
      values.password !== values.password_confirm
        ? __('The passwords need to match')
        : null
    if (validate(passwordMatchError ? {general: passwordMatchError} : {})) {
      const input = pick(values, ['email', 'name', 'password'])
      console.log({input})
      update({input})
        // .then((response: any) => {
        //   console.log(JSON.stringify(response, null, 2))
        // })
        .then(({data: {createUser: response}}: any) => {
          if (response.status !== 'success') {
            const errors =
              response.errors && response.errors.length > 0
                ? response.errors
                : [
                    __(
                      'There was a problem submitting this data. Please try again.'
                    ),
                  ]
            setErrors({api: errors.join('\n')})
          } else {
            // console.log(JSON.stringify(response, null, 2))
            dispatch(setUser(response.user))
            dispatch(setToken(response.token.access_token))
            navigate(index())
          }
        })
    }
  }

  if (isLoading) return <Spinner />

  const footer = (
    <Button
      className='m-2'
      onClick={submit}
    >
      {__('Create account')}
    </Button>
  )
  return (
    <ScrollView
      footer={footer}
      title={__('Create an account')}
    >
      <FormContainer>
        <Errors errors={errors} />
        <Heading size='h3'>{__('Create an account')}</Heading>

        <FormGroup label={__('Name')}>
          <Input
            value={values.name}
            onChange={(value: any) => setValue('name', value)}
          />
        </FormGroup>
        <FormGroup label={__('Email')}>
          <Input
            type='email'
            value={values.email}
            onChange={(value: any) => setValue('email', value)}
          />
        </FormGroup>
        <FormGroup label={__('Password')}>
          <Input
            type='password'
            value={values.password}
            onChange={(value: any) => setValue('password', value)}
          />
        </FormGroup>
        <FormGroup label={__('Confirm password')}>
          <Input
            type='password'
            value={values.password_confirm}
            onChange={(value: any) => setValue('password_confirm', value)}
          />
        </FormGroup>
      </FormContainer>
    </ScrollView>
  )
}
export default CreateAccount
