import {useTranslations} from 'src/utils/hooks'
import Grid from './Grid'
import {Link} from 'react-router-dom'
import {picturesShow} from 'src/routes/pictures'
import Attribution from '~/pictures/includes/Attribution'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import RevealButton from './RevealButton'
import View from '@/View'
import Image from '@/Image'
import Row from '@/Row'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'
import Span from '@/Span'

type Props = {
  list: any
}
const Picture = ({list}: Props) => {
  const {__} = useTranslations()

  const width = 100
  const adjustedWidth = width - 22
  const isRevealed = list.pieces_revealed.length >= 100

  if (list.picture && isRevealed)
    return (
      <View className='mt-3'>
        <Link to={picturesShow(list.picture.id)}>
          <Span className='block w-full h-0 aspect-w-1 aspect-h-1'>
            <Image
              className='object-cover'
              src={list.picture.url}
              alt={list.picture.title}
            />
          </Span>
        </Link>
        <Attribution picture={list.picture} />
        <Row className='justify-center items-center mt-2 gap-2'>
          <Span className='text-xl font-bold'>{list.picture.title}</Span>
          <Link to={picturesShow(list.picture.id)}>
            <Span className='text-primary'>
              <FontAwesomeIcon icon={icon({name: 'info-circle'})} />
            </Span>
          </Link>
        </Row>
      </View>
    )
  return (
    <>
      <Paragraph className='text-center'>
        {__('Answer questions to reveal the hidden picture')}!
      </Paragraph>
      <View className='w-full h-0 aspect-w-1 aspect-h-1 bg-gray-200'>
        {list.picture?.url ? (
          <Image
            className='object-cover'
            src={list.picture.url}
            alt={list.picture.title}
          />
        ) : (
          <Image
            className='object-cover'
            src={'/resources/map-germany-political.png'}
            alt={__('Political map of Germany')}
          />
        )}
        <Grid
          width={adjustedWidth}
          revealed={list.pieces_revealed}
        />
      </View>
    </>
  )
}
export default Picture
