import {Link, useNavigate} from 'react-router-dom'
import {api} from 'src/queries/level.enhanced'
import {List} from 'src/queries/schema.generated'
import {listsShow} from 'src/routes/lists'
import Spinner from '@/Spinner'
import TopNav from '~/layout/TopNav'
import ListItem from './includes/ListItem'
import {useState} from 'react'
import Select from '@/form/Select'
import LevelSelect from './includes/LevelSelect'
import {useSelector} from 'react-redux'
import {selectLevel} from 'src/reducers/user'
import Button from '@/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useTranslations} from 'src/utils/hooks'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'
import {resourceUrl} from 'src/config'
import Row from '@/Row'
import View from '@/View'

const colours = [
  'primary',
  // 'secondary',
  // 'tertiary',

  // 'danger',
  // 'error',
  // 'success',
  // 'warning',
]

const Index = () => {
  const {__} = useTranslations()
  const levelId = useSelector(selectLevel)
  const {data, isLoading, error} = api.endpoints.level.useQuery({
    id: levelId || '1',
  })
  const {playSound} = useSounds()

  const navigate = useNavigate()
  if (isLoading || !data?.level) return <Spinner />
  const {level} = data
  const {lists} = level
  if (!lists) return null

  return (
    <>
      <View className='text-center my-3'>
        <LevelSelect />
      </View>
      <View className='bg-gradient-to-b from-white via-purple-200 to-white'>
        {lists.map((list: any, index) => {
          if (!list) return null // Not sure why this could be undefined?
          const colour = colours[index % colours.length] || 'gray'
          const isDisabled = list.statusName === 'Locked'
          const canAccess = !isDisabled
          return (
            <ListItem
              key={index}
              variation={index % 2 ? 'r' : 'l'}
              colour={colour}
              record={list}
              onClick={() => {
                if (canAccess) navigate(listsShow(list.id))
              }}
              isFirst={index === 0}
              isLast={index === lists.length - 1}
            />
          )
        })}
      </View>
    </>
  )
}
export default Index
