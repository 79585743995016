import {useState} from 'react'
import QuestionChunk from './QuestionChunk'
import {List} from 'src/queries/schema.generated'
import SkipLives from './SkipLives'

type Props = {
  chunks: any[]
  listId?: any
  practiceSessionId?: any
  list?: List | null
  skip?: Boolean
  values: any
  maxLives?: number
  lives?: number
  setValue: (key: string, value: any) => void
  setFinished: () => void
  addCorrect: (id: string | string[]) => void
  addIncorrect?: (id: string | string[]) => void
}
const QuestionChunks = ({
  chunks,
  listId,
  practiceSessionId,
  list,
  values,
  setValue,
  setFinished,
  addCorrect,
  addIncorrect,
  skip = false,
  maxLives = 0,
  lives = 0,
}: Props) => {
  const [chunkIndex, setChunkIndex] = useState<number>(0)

  const questions = chunks[chunkIndex]
  // console.log({chunk})
  const next = () => {
    // console.log('next chunk', chunkIndex)
    if (chunkIndex < chunks.length - 1) {
      setChunkIndex(chunkIndex + 1)
    } else {
      // console.log('NO!')
      setFinished()
    }
  }

  return (
    <>
      <QuestionChunk
        questions={questions}
        next={next}
        list={list}
        listId={listId}
        skip={skip}
        values={values}
        setValue={setValue}
        // setFinished={setFinished}
        addCorrect={addCorrect}
        addIncorrect={addIncorrect}
      >
        {skip && (
          <SkipLives
            maxLives={maxLives}
            lives={lives}
          />
        )}
      </QuestionChunk>
    </>
  )
}

export default QuestionChunks
