import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from 'src/reducers'
import {selectQuestiontypes, setQuestionTypes} from 'src/reducers/preferences'
import {api} from 'src/queries/question-type.generated'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import useTranslations from 'src/utils/hooks/useTranslations'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import FormContainer from '@/form/FormContainer'
import CheckboxList from '@/CheckboxList'
import {useForm} from 'src/utils/form'
import Spinner from '@/Spinner'
import sortBy from 'lodash/sortBy'
import {QuestionType} from 'src/queries/schema.generated'
import {getQuestionTypeName} from './helpers'

const Settings = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const navigate = useNavigate()
  const preferences = useSelector(({preferences}: RootState) => preferences)
  const questionTypes = useSelector(selectQuestiontypes)
  const {data, isLoading, error} = api.endpoints.questionTypes.useQuery()
  const {values, add, remove} = useForm({
    initialValues: {selected: questionTypes || []},
  })
  const dispatch = useDispatch()

  if (isLoading || !data) return <Spinner />

  const items = sortBy(
    data.questionTypes.filter((x) => !!x.apply_to_words),
    'id'
  ).map((type: QuestionType) => ({
    label: getQuestionTypeName(type.name),
    description: type.description || '',
    key: type.slug,
  }))

  const save = () => {
    // console.log('selected', values.selected)
    dispatch(setQuestionTypes(values.selected))
    navigate(-1)
  }

  const footer = (
    <Button
      className='m-2'
      onClick={save}
    >
      {__('Save')}
    </Button>
  )
  return (
    <ScrollView footer={footer}>
      <FormContainer className='mt-5'>
        <CheckboxList
          items={items}
          value={values.selected}
          add={(value: string) => add('selected', value)}
          remove={(value: string) => remove('selected', value)}
        />
      </FormContainer>
    </ScrollView>
  )
}

export default Settings
