import Grid from '@/Grid'
import Row from '@/Row'
import Stat from '@/Stat'
import View from '@/View'
import {useTranslations} from 'src/utils/hooks'

type Props = {
  correctQuestions: any
  questions: any
  time: any
}
const ResultStats = ({correctQuestions, questions, time}: Props) => {
  const {__} = useTranslations()
  return (
    <View className='mt-3 text-center'>
      <Grid
        cols='3'
        className='gap-2'
      >
        <Stat
          bg='bg-emerald-500'
          label={__('Time')}
        >
          {time.replace(/^00\:/, '')}
        </Stat>

        <Stat
          bg='bg-indigo-500'
          label={__('Questions')}
        >
          {correctQuestions.length} / {questions.length}
        </Stat>

        <Stat
          bg='bg-fuchsia-500'
          label={__('% correct')}
        >
          {Math.round((correctQuestions.length / questions.length) * 100)} %
        </Stat>
      </Grid>
    </View>
  )
}
export default ResultStats
