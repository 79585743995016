import {useEffect, useRef, useState} from 'react'
import {arrayMoveImmutable} from 'array-move'

import {api} from 'src/queries/list.enhanced'
import {Level, List} from 'src/queries/schema.generated'

import ListComponent from '~/admin/lists/includes/List'
import {cancelDefault} from 'src/utils'

type Props = {
  level: Level
  version: number
}
const Component = ({level, version}: Props) => {
  const [createList] = api.endpoints.createList.useMutation()
  const [newName, setNewName] = useState<string>('')
  const addList = () => {
    if (!newName) return
    const input = {
      name: newName,
      level_id: level.id,
      version,
    }
    console.log({input})
    setNewName('')
    createList({input})
  }
  return (
    <div className='flex flex-row justify-between gap-2'>
      <input
        className='border px-3 py-1 flex-1'
        type='text'
        placeholder='Add new list'
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') addList()
        }}
      />
      {newName && (
        <button
          className='bg-green-600 text-white px-2'
          onClick={addList}
        >
          Add
        </button>
      )}
    </div>
  )
}
export default Component
