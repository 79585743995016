import {
  ComponentProps,
  ComponentType,
  ReactNode,
} from 'react'

import {HelmetProvider} from 'react-helmet-async'
import {Provider as ReduxProvider} from 'react-redux'
import {ToastProvider} from 'src/utils/hooks/useToast'

import {store} from './reducers/index'
import {SoundsProvider} from './utils/hooks/useSounds'
import {SpeechProvider} from './utils/hooks/useSpeech'
import {TranslationProvider} from './utils/hooks/useTranslations'

type Providers = [ComponentType<any>, ComponentProps<any>?][]

const combineProviders = (providers: Providers) =>
  providers.reduce(
    (AccumulatedProviders: any, [Provider, props = {}]) =>
      ({children}: {children: ReactNode}) =>
        (
          <AccumulatedProviders>
            <Provider {...props}>
              <>{children}</>
            </Provider>
          </AccumulatedProviders>
        ),
    ({children}: {children: ReactNode}) => <>{children}</>
  ) as any

const Providers = combineProviders([
  [ReduxProvider, {store}],
  [TranslationProvider, {locale: 'en'}],
  [SpeechProvider],
  [SoundsProvider],
  [HelmetProvider],
  [ToastProvider],
])
export default Providers
