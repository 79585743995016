import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '.'

export const initialState = {
  questionTypes: [],
  enableSounds: true,
  enableSpeech: true,
}

const slice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    clearAll: () => initialState,
    setQuestionTypes: (state: any, {payload}) => {
      return {...state, questionTypes: payload}
    },
    setEnableSounds: (state: any, {payload}) => ({
      ...state,
      enableSounds: payload,
    }),
    setEnableSpeech: (state: any, {payload}) => ({
      ...state,
      enableSpeech: payload,
    }),
  },
})

// selectors
export const selectQuestiontypes = ({preferences}: RootState) =>
  preferences.questionTypes
export const selectEnableSounds = ({preferences}: RootState) =>
  preferences.enableSounds
export const selectEnableSpeech = ({preferences}: RootState) =>
  preferences.enableSpeech

export const {clearAll, setQuestionTypes, setEnableSounds, setEnableSpeech} =
  slice.actions

export default slice.reducer
