import {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

import {api} from 'src/queries/question.enhanced'
import {api as listApi} from 'src/queries/list.enhanced'
import {useTranslations} from 'src/utils/hooks'
import Spinner from '@/Spinner'
import {getEvenChunks} from './helpers'
import PageTitle from '@/PageTitle'
import {List, QuizQuestion} from 'src/queries/schema.generated'
import QuestionChunks from './includes/QuestionChunks'
import {useForm} from 'src/utils/form'
import Results from './Results'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import {useSelector} from 'react-redux'
import {selectEnableSpeech} from 'src/reducers/preferences'
import SkipIntro from './includes/SkipIntro'
import SkipFailedModal from './includes/SkipFailedModal'
import NoQuestions from './includes/NoQuestions'

type Props = {
  skip?: boolean
}
const CreateListQuiz = ({skip = false}: Props) => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {listId} = useParams()
  const {values, setValue} = useForm()
  const [timeStarted, setTimeStarted] = useState<number>(0)
  const [timeFinished, setTimeFinished] = useState<number>(0)
  const [showResults, setShowResults] = useState(false)
  const [showSkipFailedModal, setShowSkipFailedModal] = useState(false)
  const [correctQuestions, setCorrectQuestions] = useState<Array<string>>([])
  const {data: listData} = listApi.endpoints.list.useQuery({id: listId})
  const [trigger, {data, isLoading}] = api.endpoints.nextQuestions.useMutation()
  const [showIntro, setShowIntro] = useState(skip)
  const [lives, setLives] = useState<number | null>(null)
  const [maxLives, setMaxLives] = useState<number | null>(null)
  const enableSpeech = useSelector(selectEnableSpeech)
  const navigate = useNavigate()

  useEffect(() => {
    trigger({listId, skip, questionTypes: enableSpeech ? [] : ['!listen']})
  }, [])

  useEffect(() => {
    if (skip && data?.nextQuestions) {
      // TODO: handle different numbers of lives depending on size of list
      const lives = 3
      setLives(lives)
      setMaxLives(lives)
    }
  }, [skip, data?.nextQuestions])

  useEffect(() => {
    setTimeStarted(Date.now())
  }, [])

  const setFinished = () => {
    setTimeFinished(Date.now())
    setShowResults(true)
  }

  const addCorrect = (id: string | string[]) => {
    setCorrectQuestions(correctQuestions.concat(Array.isArray(id) ? id : [id]))
  }

  if (isLoading || !data || !listData?.list?.name) return <Spinner />

  const getChunks = () => {
    if (skip) return [data?.nextQuestions]
    switch (listData?.list?.stage) {
      case 1:
        return getEvenChunks(data?.nextQuestions, 4)
      case 2:
        return getEvenChunks(
          data?.nextQuestions,
          Math.min(6, Math.ceil((data?.nextQuestions?.length || 1) / 2))
        )
    }
    return [data?.nextQuestions]
  }
  // console.log(listData?.list?.stage)

  const title = __('Quiz') + ' - ' + listData.list.name

  const chunks = getChunks()

  // console.log({correctQuestions})
  // console.log({chunks})
  // console.log(data?.nextQuestions?.[0]?.word?.word)

  if (data?.nextQuestions && data.nextQuestions.length === 0) {
    return <NoQuestions />
  }

  if (showIntro) {
    return (
      <SkipIntro
        questions={data?.nextQuestions as QuizQuestion[]}
        lives={lives || 3}
        next={() => setShowIntro(false)}
      />
    )
  }

  if (showResults) {
    return (
      <Results
        listId={listId}
        questions={data?.nextQuestions as QuizQuestion[]}
        values={values}
        correctQuestions={correctQuestions}
        time={timeFinished - timeStarted}
        skip={skip}
      />
    )
  }

  const addIncorrect = (id: string) => {
    if (!skip || lives === null) return
    if (lives - 1 <= 0) {
      // navigate(-1)
      setShowSkipFailedModal(true)
    }
    setLives(lives - 1)
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <QuestionChunks
        chunks={chunks}
        list={listData?.list as List}
        listId={listId}
        values={values}
        setValue={setValue}
        setFinished={setFinished}
        addCorrect={addCorrect}
        addIncorrect={addIncorrect as any}
        lives={lives || 3}
        maxLives={maxLives || 3}
        skip={skip}
      />
      <SkipFailedModal
        visible={showSkipFailedModal}
        setVisible={setShowSkipFailedModal}
        submit={() => navigate(-1)}
      />
    </>
  )
}

export default CreateListQuiz
