import Span from '@/Span'
import View from '@/View'
import {ReactNode} from 'react'

type Props = {
  question: string
  children?: ReactNode
}
const QuestionTitle = ({question, children}: Props) => {
  return (
    <View className='h-40 flex justify-center items-center gap-2'>
      <Span className='text-3xl text-center'>{question}</Span>
      {children}
    </View>
  )
}
export default QuestionTitle
