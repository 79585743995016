import React, {useEffect, useState} from 'react'
import {
  PROGRESS_STATUS_COMPLETE,
  PROGRESS_STATUS_IN_PROGRESS,
} from '~/quizzes/helpers'
import {
  PracticeSession,
  SavePracticeSessionProgressInput,
} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import {api} from 'src/queries/practice-session.enhanced'
import Spinner from '@/Spinner'
import {useNavigate, useParams} from 'react-router-dom'
import Picture from '~/lists/includes/Picture'
import Button from '@/Button'
import RevealButton from '~/lists/includes/RevealButton'
import ScrollView from '@/ScrollView'
import {quizzesCreate} from 'src/routes/practice-sessions'
import StageCompleteModal from './includes/StageCompleteModal'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import Heading from '@/Heading'
import View from '@/View'
import Progress from '@/Progress'
import ProgressPanel from '~/lists/includes/ProgressPanel'
import Paragraph from '@/Paragraph'

const QUIZZES_TO_COMPLETE = 10
const THRESHOLD = 100

const Show = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {id} = useParams()
  const navigate = useNavigate()
  const [showStageCompleteModal, setShowStageCompleteModal] =
    useState<boolean>(false)
  const {data, isLoading, refetch, error} =
    api.endpoints.practiceSession.useQuery({id})
  // const [trigger, { data, isLoading }] = api.endpoints.practiceSession.useLazyQuery()
  // console.log({item})
  // useEffect(() => {
  //   console.log('refetching')
  //   trigger({ id })
  //     // .then(({ data }) => {
  //     //   calculate(data.list)
  //     //   // console.log('data', data.list)
  //     // })
  //   // refetch().then(x => {
  //   //   // console.log('fetched', {x})
  //   // })
  // }, [])
  // useEffect(() => {
  //   // Needed to refetch after going back
  //   const unsubscribe = navigation.addListener('focus', () => {
  //     console.log('reloading')
  //     fetch()
  //   })
  //   return unsubscribe
  // }, [navigation])

  // const fetch = () => {
  //   // setTimeout(() => {
  //     trigger({ id })
  //       .then(({ data }) => {
  //         console.log('calculating...')
  //         calculate(data.practiceSession)
  //       })
  //   // }, 1000)
  // }
  const [saveProgress, {reset}] =
    api.endpoints.savePracticeSessionProgress.useMutation()

  // const calculate = (practiceSession: PracticeSession) => {
  //   // console.log({practiceSession})
  //   // const scores = list.words.map(x => x.score?.phase_1 || 0)
  //   // const percent_complete = mean(scores)
  //   const percent_complete = (practiceSession?.quizzes?.length || 0) === 0
  //     ? 0
  //     : practiceSession.quizzes.length / QUIZZES_TO_COMPLETE * 100
  //   const input = {
  //     practice_session_id: practiceSession.id,
  //     percent_complete,
  //     progress_status_id: percent_complete < THRESHOLD
  //       ? PROGRESS_STATUS_IN_PROGRESS + ''
  //       : PROGRESS_STATUS_COMPLETE + '',
  //     // pieces_revealed: [],
  //   }
  //   console.log({input})
  //   saveProgress({ input })
  //     .unwrap()
  //     .then(async (response) => {
  //       // console.log(response)
  //       // await reset()
  //       // await refetch()
  //       await trigger({ id })
  //     })
  //   // console.log('calculate', scores, percent_complete, list.percent_complete)
  // }

  if (isLoading || !data?.practiceSession) return <Spinner />

  // console.log(error)
  const {practiceSession} = data

  const footer = (
    <Button
      className='m-2'
      onClick={() => navigate(quizzesCreate(practiceSession.id))}
    >
      {__('Start quiz')}
    </Button>
  )

  // console.log({practiceSession})
  return (
    <ScrollView
      title={practiceSession?.list?.name + ' ' + __('practice')}
      footer={footer}
    >
      <Heading size='h3'>{practiceSession.name}</Heading>

      <View className='m-3'>
        <ProgressPanel percent={practiceSession.percent_complete || 0} />

        <View className='space-y-3'>
          <Picture list={practiceSession} />
          <RevealButton
            record={practiceSession}
            setShowStageCompleteModal={setShowStageCompleteModal}
            saveProgress={(data: SavePracticeSessionProgressInput) => {
              saveProgress({
                input: {...data, practice_session_id: practiceSession.id},
              })
            }}
          />
        </View>
      </View>
      <StageCompleteModal
        visible={showStageCompleteModal}
        setVisible={setShowStageCompleteModal}
      >
        <Paragraph>
          {__(
            `You have unlocked this stage: :name, and :picture has been added to your collection`,
            {
              name: practiceSession.name,
              picture: practiceSession.picture?.title || __('a new picture'),
            }
          )}
          !
        </Paragraph>
      </StageCompleteModal>
    </ScrollView>
  )
}
export default Show
