import classNames from 'classnames'
import {ReactNode} from 'react'
import {Question, QuizQuestion, Translation} from 'src/queries/schema.generated'
import Button from '@/Button'
import View from '@/View'

type Props = {
  item: Question
  select: (item: Question) => void
  children: ReactNode
  isAnswered: boolean
  isSelected: boolean
  isGoodAnswer: boolean
  isBadAnswer: boolean
}
const MatchButton = ({
  item,
  select,
  isAnswered,
  isSelected,
  isGoodAnswer,
  isBadAnswer,
  children,
}: Props) => {
  return (
    <View className='h-full'>
      <Button
        className={classNames('btn-md h-full w-full', {
          'btn-disabled': isAnswered && !isGoodAnswer,
          'btn-outline': !isSelected && !isBadAnswer && !isGoodAnswer,
          // 'btn-success text-white': isSelected,
          'btn-success text-white': isGoodAnswer,
          'btn-error text-white': isBadAnswer,
        })}
        // variant={isBadAnswer ? 'error' : undefined}
        // disabled={isAnswered}
        onClick={() => select(item)}
      >
        {children}
      </Button>
    </View>
  )
}
export default MatchButton
