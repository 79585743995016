import {Link, useLocation, useNavigate} from 'react-router-dom'
import {index} from 'src/routes/app'
import {useTranslations} from 'src/utils/hooks'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import SettingsNav from './SettingsNav'
import {useSelector} from 'react-redux'
import {selectToken} from 'src/reducers/user'
import Icon from '@/Icon'
import Button from '@/Button'
import View from '@/View'
import Row from '@/Row'

const BackButton = () => {
  const navigate = useNavigate()
  const location = useLocation()
  if (location.pathname === '/') return null
  const iconType =
    location.pathname === '/'
      ? icon({name: 'home'})
      : icon({name: 'chevron-left'})
  return (
    <Button
      className='btn btn-square btn-ghost'
      onClick={() => (location.pathname === '/' ? null : navigate(-1))}
    >
      <FontAwesomeIcon icon={iconType} />
    </Button>
  )
}

type Props = {
  title?: string
}
const TopNav = ({...props}) => {
  const {__} = useTranslations()
  const token = useSelector(selectToken)
  const title = props.title || __('Word Magic')

  return (
    <Row className='navbar mb-0 flex justify-between bg-primary text-white'>
      <View className='w-14 place-content-start'>
        <BackButton />
      </View>
      <View className='flex-grow text-center place-content-center'>
        <Link
          to={index()}
          className='text-xl p-2'
        >
          <FontAwesomeIcon icon={icon({name: 'wand-magic-sparkles'})} /> {title}
        </Link>
      </View>
      <View className='w-14 place-content-end'>{token && <SettingsNav />}</View>
    </Row>
  )
}
export default TopNav
