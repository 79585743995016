import {ReactNode} from 'react'
import classNames from 'classnames'
import Pressable from '@/Pressable'
import {useNavigate} from 'react-router-dom'

export type TablLinkProps = {
  to: string
  path: string
  children: ReactNode
}
const TabLink = ({to, path, children}: TablLinkProps) => {
  const navigate = useNavigate()
  return (
    <Pressable
      role='tab'
      className={classNames('text-primary tab', {
        'tab-active': path === to,
      })}
      onClick={() => navigate(to)}
    >
      {children}
    </Pressable>
  )
}
export default TabLink
