import React, {ReactNode} from 'react'
import {useNavigate} from 'react-router-dom'
import {index} from 'src/routes/app'

import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Modal from '@/Modal'

type Props = {
  visible: boolean
  setVisible: Function
  submit?: () => void
  children: ReactNode
  title?: string
}
const StageCompleteModal = ({
  visible,
  setVisible,
  submit,
  children,
  title,
}: Props) => {
  const {__} = useTranslations()
  const navigate = useNavigate()
  const actions = [
    <Button
      key={0}
      className='btn-ghost'
      onClick={() => {
        setVisible(false)
        navigate(index())
      }}
    >
      {__('OK')}
    </Button>,
  ]
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      actions={actions}
      // title={__('Congratulations, you have unlocked this stage!')}>
      title={title || __('Stage complete!')}
    >
      {children}
    </Modal>
  )
}

export default StageCompleteModal
