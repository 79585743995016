import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Link, useNavigate} from 'react-router-dom'
import {useState} from 'react'
import {Translation, Word} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import useSpeech from 'src/utils/hooks/useSpeech'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import RevisionListButton from './RevisionListButton'
import {wordsShow} from 'src/routes/lists'
import Row from '@/Row'
import View from '@/View'
import Bar from '@/Bar'
import Pressable from '@/Pressable'

type Props = {
  word: any // Word // NOTE: not sure why it doesn't like this?
  addToRevisionList?: (id: string) => void
  removeFromRevisionList?: (id: string) => void
  isRevisionList?: boolean
  listId?: string
}
const WordRow = ({
  word,
  addToRevisionList,
  removeFromRevisionList,
  isRevisionList,
  listId,
}: Props) => {
  const {__} = useTranslations()
  const {playWord} = useSpeech()
  // const navigate = useNavigate()

  // const navigation = useAppNavigation()
  const score = word.score
  // const score = {
  //   phase_1: 60,
  //   phase_2: 30,
  // }
  // console.log({score})

  return (
    <>
      <View className='mx-1 mt-1'>
        <Bar
          style={{width: (score?.phase_1 || 0) + '%'}}
          className='bg-purple-200'
        />
      </View>
      <View className='mx-1 -mt-7'>
        <Bar
          style={{width: (score?.phase_2 || 0) + '%'}}
          className='bg-yellow-300'
        />
      </View>
      <Row className='-mt-7 px-3 py-1 border-b justify-between'>
        <Row className='gap-2'>
          <Link to={wordsShow(word.id, listId)}>{word.full_word}</Link>
          <Pressable
            className='text-primary'
            onClick={() => playWord(word.full_word)}
          >
            <FontAwesomeIcon icon={icon({name: 'ear-listen'})} />
          </Pressable>
        </Row>
        <Row className='gap-2'>
          <Link to={wordsShow(word.id, listId)}>
            <View className='italic'>
              {(word.translations || [])
                .map((translation: Translation) => translation.word)
                .join('; ')}
            </View>
          </Link>
          <RevisionListButton
            word={word}
            addToRevisionList={addToRevisionList}
            removeFromRevisionList={removeFromRevisionList}
            isRevisionList={isRevisionList}
          />
        </Row>
      </Row>
    </>
  )
}
export default WordRow
