import {ReactNode, useEffect, useState} from 'react'

import {api as translationApi} from 'src/queries/translation.enhanced'
import {api as wordApi} from 'src/queries/word.generated'
import {useTranslations} from 'src/utils/hooks'

import Question from './Question'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import classNames from 'classnames'
import CorrectAlert from './CorrectAlert'
import {Question as QuestionType} from 'src/queries/schema.generated'
import {isCorrect, isExactMatch} from '../helpers'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'
import IncorrectModal from './IncorrectModal'

// import Question from './Question'
// import CorrectAlert from './CorrectAlert'
// import IncorrectAlert from './IncorrectAlert'
// import Results from '../Results'
// import { Platform } from 'react-native'

type Props = {
  questions: QuestionType[]
  listId: any
  practiceSessionId?: any
  values: any
  children?: ReactNode
  setValue: (key: string, value: any) => void
  setFinished: () => void
  addCorrect: (id: string | string[]) => void
  addIncorrect?: (id: string | string[]) => void
}
const Questions = ({
  questions,
  listId,
  values,
  children,
  setValue,
  setFinished,
  addCorrect,
  addIncorrect,
}: Props) => {
  const {__} = useTranslations()
  const {playSound} = useSounds()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [submitted, setSubmitted] = useState(false)
  const [showCorrectAlert, setShowCorrectAlert] = useState(false)
  const [showIncorrectAlert, setShowIncorrectAlert] = useState(false)
  const [answer, setAnswer] = useState<string | null>(null)
  const [exactMatch, setExactMatch] = useState(false)
  const [triggerWords, {data: wordsData, isLoading: wordsIsLoading}] =
    wordApi.endpoints.quizWords.useMutation()
  const [
    triggerTranslations,
    {data: translationsData, isLoading: translationsIsLoading},
  ] = translationApi.endpoints.quizTranslations.useMutation()

  useEffect(() => {
    triggerWords({list_id: listId, limit: 50})
    triggerTranslations({list_id: listId, limit: 50})
  }, [])
  // useEffect(() => {
  //   setTimeStarted(Date.now())
  // }, [])

  // if (list?.stage === 1) {
  //   // TODO handle batches
  //   const chunks = chunk(questions, getChunkSize(questions.length))
  //   console.log({chunks})
  //   return <QuestionChunks chunks={chunks} />
  // }

  const question = questions[questionIndex]

  const submit = () => {
    const answer = values[question?.id] || null
    if (!answer) return

    setAnswer(answer)
    setSubmitted(true)

    const correct = isCorrect(
      answer,
      question.accepted_answers || '',
      question?.questionType?.slug
    )
    setExactMatch(isExactMatch(answer, question.accepted_answers || ''))
    // console.log({correct, answer, accepted})
    if (correct) {
      playSound(Sound.Success)
      setShowCorrectAlert(true)
      // setCorrectQuestions(correctQuestions.concat([question.id]))
      addCorrect(question.id)
    } else {
      playSound(Sound.Error)
      setShowIncorrectAlert(true)
      if (addIncorrect) addIncorrect(question.id)
    }
  }

  const next = () => {
    setSubmitted(false)
    setShowCorrectAlert(false)
    setShowIncorrectAlert(false)
    if (questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1)
    } else {
      // console.log('finished')
      setQuestionIndex(0)
      setFinished()
    }
  }

  const value = values[question?.id] || null

  const footer = (
    <Button
      className={classNames('m-2', {'btn-disabled': !value})}
      onClick={submit}
    >
      {__('Submit answer')}
    </Button>
  )

  // if (wordsIsLoading || translationsIsLoading) return <Spinner />  // Removed because of flickering

  return (
    <ScrollView footer={footer}>
      <>
        {children}
        <Question
          question={question}
          // words={wordsData?.words}
          words={wordsData?.quizWords}
          // translations={translationsData?.translations}
          translations={translationsData?.quizTranslations}
          submit={submit}
          value={value}
          setValue={(value: string) => setValue(question.id, value)}
          submitted={submitted}
        />
        {showCorrectAlert && (
          <CorrectAlert
            next={next}
            question={question}
            exactMatch={exactMatch}
          />
        )}
        <IncorrectModal
          question={question}
          visible={showIncorrectAlert}
          setVisible={setShowIncorrectAlert}
          submit={() => next()}
        />
      </>
    </ScrollView>
  )
}

export default Questions
