import {api as generated} from './question.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Lists'],
  endpoints: {
    nextQuestions: {
      // keepUnusedDataFor: 1,
      // providesTags: ['Lists'], // Don't invalidate - otherwise when a word is added to a revision list, it reloads the questions in progress
    },
  },
})
