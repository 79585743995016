import sample from 'lodash/sample'

import {useTranslations} from 'src/utils/hooks'
import {Translation} from 'src/queries/schema.generated'
import Button from '@/Button'
import {useEffect, useRef} from 'react'
import {useFocus} from 'src/utils/useFocus'
import {getAcceptedAnswers} from '../helpers'
import Column from '@/Column'
import View from '@/View'
import Paragraph from '@/Paragraph'
import Row from '@/Row'
import Span from '@/Span'

type Props = {
  next: any
  question: any
  exactMatch: boolean
}
const CorrectAlert = ({next, question, exactMatch}: Props) => {
  const {__} = useTranslations()
  const [btnRef, setBtnFocus] = useFocus()
  useEffect(() => {
    setBtnFocus()
  }, [btnRef])

  const messages = ['Correct!', 'Great work!', "That's right!", 'Amazing!']
  const message = sample(messages) as string

  return (
    <View className='absolute bottom-0 right-0 left-0 flex justify-center'>
      <View className='sm:w-[640px] bg-green-200 text-center w-full p-2 h-48 flex flex-col justify-between'>
        <Column className='flex-grow justify-center gap-4'>
          {exactMatch ? (
            <>
              <Paragraph className='text-2xl text-green-900'>
                {__(message)}
              </Paragraph>
              <Paragraph className='italic text-lg text-green-900'>
                {getAcceptedAnswers(question.accepted_answers).join('; ')}
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph className='text-green-900'>
                {__('Oops, you have a typo - please check carefully.')}
              </Paragraph>
              <Row className='justify-center items-center text-center gap-2 text-green-900'>
                <Span className='italic text-sm'>{__('Correct answer')}:</Span>
                <Span className='font-bold'>
                  {getAcceptedAnswers(question.accepted_answers).join('; ')}
                </Span>
              </Row>
            </>
          )}
          <Button
            ref={btnRef}
            variant='success'
            className='w-full mb-4'
            onClick={next}
          >
            {__('Continue')}
          </Button>
        </Column>
      </View>
    </View>
  )
}

export default CorrectAlert
