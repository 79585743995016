type Props = {
  label?: string
  checked: boolean
  onChange: (checked: any) => void
}
const Checkbox = ({label, checked, onChange}: Props) => {
  return (
    <label className='cursor-pointer flex items-center'>
      <input
        type='checkbox'
        checked={checked}
        onChange={onChange}
        className='checkbox'
      />
      {label && <span className='ml-2'>{label}</span>}
    </label>
  )
}
export default Checkbox
