import Alert from '@/Alert'
import Button from '@/Button'
import Column from '@/Column'
import Errors from '@/Errors'
import Paragraph from '@/Paragraph'
import ScrollView from '@/ScrollView'
import Spinner from '@/Spinner'
import View from '@/View'
import FormContainer from '@/form/FormContainer'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'
import pick from 'lodash/pick'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {api} from 'src/queries/user.generated'
import {selectUser, setToken, setUser} from 'src/reducers/user'
import {index} from 'src/routes/app'
import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'

export const initialValues = {
  email: '',
}

export const rules = {
  email: 'required|email',
}

const ForgottenPassword = () => {
  const {__} = useTranslations()
  const navigate = useNavigate()
  const [send, {isLoading}] =
    api.endpoints.sendForgottenPasswordEmail.useMutation()
  const user = useSelector(selectUser)
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues,
    rules,
  })
  const [message, setMessage] = useState<string | null>(null)

  // const [apiErrors, setApiErrors] = useState([])
  const dispatch = useDispatch()

  const submit = () => {
    if (validate()) {
      const input = values
      send(input).then(({data: {sendForgottenPasswordEmail: success}}: any) => {
        if (success) {
          // navigate(index())
          setMessage(__('The email has been sent to the address you provided'))
        } else {
          const errors = [
            __('There was a problem submitting this data. Please try again.'),
          ]
          setErrors({api: errors.join('\n')})
        }
      })
    }
  }

  if (isLoading) return <Spinner />

  const footer = (
    <Button
      className='m-2'
      onClick={submit}
    >
      {__('Send password reset link')}
    </Button>
  )

  return (
    <ScrollView
      footer={footer}
      title={__('Reset password')}
    >
      {message && (
        <View className='p-3'>
          <Alert
            variant='info'
            message={message}
          />
        </View>
      )}
      <FormContainer>
        <Errors errors={errors} />
        <Paragraph className='my-5'>
          {__(
            'Enter your email so that we can send a link that you can use to reset your password'
          )}
          .
        </Paragraph>
        <FormGroup label={__('Email address')}>
          <Input
            type='email'
            value={values.email || ''}
            onChange={(value: any) => setValue('email', value)}
          />
        </FormGroup>
      </FormContainer>
    </ScrollView>
  )
}
export default ForgottenPassword
