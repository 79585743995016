import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type PracticeSessionsQueryVariables = Types.Exact<{
  level_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type PracticeSessionsQuery = { __typename?: 'Query', practiceSessions: Array<{ __typename?: 'PracticeSession', id: string, list_id?: string | null, name?: string | null, icon?: string | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null }> };

export type PracticeSessionQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type PracticeSessionQuery = { __typename?: 'Query', practiceSession?: { __typename?: 'PracticeSession', id: string, list_id?: string | null, name?: string | null, icon?: string | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null, stage?: number | null, percent_complete: number, pieces_revealed: Array<number | null>, picture?: { __typename?: 'Picture', id: string, slug?: string | null, title: string, url: string, link?: string | null, attribution?: string | null, description?: string | null } | null, list?: { __typename?: 'List', id: string, name: string } | null, quizzes?: Array<{ __typename?: 'Quiz', id: string, date?: any | null, time_taken?: string | null } | null> | null } | null };

export type SavePracticeSessionProgressMutationVariables = Types.Exact<{
  input: Types.SavePracticeSessionProgressInput;
}>;


export type SavePracticeSessionProgressMutation = { __typename?: 'Mutation', savePracticeSessionProgress?: { __typename?: 'PracticeSessionProgress', id?: string | null, progress_status_id?: string | null, stage?: number | null, percent_complete?: number | null, pieces_revealed?: Array<number | null> | null } | null };


export const PracticeSessionsDocument = `
    query practiceSessions($level_id: ID) {
  practiceSessions(level_id: $level_id) {
    id
    list_id
    name
    icon
    statusName
    intro
    question_types
  }
}
    `;
export const PracticeSessionDocument = `
    query practiceSession($id: ID) {
  practiceSession(id: $id) {
    id
    list_id
    name
    icon
    statusName
    intro
    question_types
    picture {
      id
      slug
      title
      url
      link
      attribution
      description
    }
    list {
      id
      name
    }
    stage
    percent_complete
    pieces_revealed
    quizzes {
      id
      date
      time_taken
    }
  }
}
    `;
export const SavePracticeSessionProgressDocument = `
    mutation savePracticeSessionProgress($input: SavePracticeSessionProgressInput!) {
  savePracticeSessionProgress(input: $input) {
    id
    progress_status_id
    stage
    percent_complete
    pieces_revealed
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    practiceSessions: build.query<PracticeSessionsQuery, PracticeSessionsQueryVariables | void>({
      query: (variables) => ({ document: PracticeSessionsDocument, variables })
    }),
    practiceSession: build.query<PracticeSessionQuery, PracticeSessionQueryVariables | void>({
      query: (variables) => ({ document: PracticeSessionDocument, variables })
    }),
    savePracticeSessionProgress: build.mutation<SavePracticeSessionProgressMutation, SavePracticeSessionProgressMutationVariables>({
      query: (variables) => ({ document: SavePracticeSessionProgressDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


