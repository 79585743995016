import {api as generated} from './translation.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Lists', 'Levels'],
  endpoints: {
    updateTranslation: {
      invalidatesTags: ['Lists'],
    },
  },
})
