import React, {useEffect, useState} from 'react'
import sample from 'lodash/sample'
import shuffle from 'lodash/shuffle'
import {useSelector} from 'react-redux'

import {useSpeech, useTranslations} from 'src/utils/hooks'
// import {api} from 'src/queries/word.generated'
import {Translation, Word} from 'src/queries/schema.generated'
import {selectLanguage} from 'src/reducers/user'
import Spinner from '@/Spinner'
import ButtonOptions from '@/ButtonOptions'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'
import Column from '@/Column'
import QuestionTitle from './includes/QuestionTitle'
import View from '@/View'

const ANSWER_NUMBER = 7

// English > German
const TranslateOut = ({
  id,
  question,
  submit,
  value,
  setValue,
  submitted,
  words,
}: any) => {
  const {__} = useTranslations()
  const language_id = useSelector(selectLanguage)
  const answer = sample(question?.accepted_answers.split('|'))
  // const {data, isLoading} = api.endpoints.words.useQuery()
  const {playWord, init: speechInit, enableSpeech} = useSpeech()
  const {playSound, enableSounds} = useSounds()
  const [options, setOptions] = useState<any[]>([])
  const word = question.word.translations
    .map((translation: Translation) => translation.word)
    .join('; ')
  // console.log(JSON.stringify(question, null, 2))
  useEffect(() => {
    if (!words) return
    // console.log(data.words, language_id)
    const filtered = words
      .filter((x: Word) => x.id !== question.word_id)
      .filter((x: Word) => x.word_type_id === question.word.word_type_id)
    const otherAnswers = shuffle(filtered).slice(
      0,
      Math.min(ANSWER_NUMBER, words.length)
    )

    // console.log(
    //   'otherAnswers out',
    //   otherAnswers.map((x) => x.id),
    //   question.word_id
    // )
    setOptions(shuffle(otherAnswers.map((x) => x.word).concat(answer)))
  }, [words, question])
  return (
    <Column className='h-full justify-between'>
      <QuestionTitle question={word} />
      <View className='mx-4 flex-1'>
        <ButtonOptions
          options={options}
          value={value}
          setValue={setValue}
          disabled={submitted}
          onClick={(item: string) => {
            if (enableSpeech) playWord(item)
            if (enableSounds) playSound(Sound.Tap)
          }}
        />
      </View>
    </Column>
  )
}

export default TranslateOut
