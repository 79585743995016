import {useTranslations} from 'src/utils/hooks'
import {PROGRESS_STATUS_COMPLETE} from 'src/features/quizzes/helpers'
import {getSquaresToReveal} from '../helpers'
import {
  List,
  PracticeSession,
  SaveListProgressInput,
} from 'src/queries/schema.generated'
import Button from '@/Button'

const THRESHOLD = 100

type Props = {
  record: any // List | PracticeSession // list or practice esssion
  setShowStageCompleteModal: any
  saveProgress: any
}
const RevealButton = ({
  record,
  setShowStageCompleteModal,
  saveProgress,
}: Props) => {
  const {__} = useTranslations()
  const progress = Math.round(record.percent_complete)
  const canReveal = record.pieces_revealed.length < progress
  // console.log({progress, canReveal})
  const reveal = () => {
    const isComplete = Math.round(record.percent_complete) >= THRESHOLD
    const toReveal = Math.round(
      record.percent_complete - record.pieces_revealed.length
    )
    const squaresToReveal = getSquaresToReveal(toReveal, record.pieces_revealed)
    const pieces_revealed = record.pieces_revealed
      .concat(squaresToReveal)
      .sort()
    // console.log(pieces_revealed, pieces_revealed.length)
    const input = {
      pieces_revealed,
    } as SaveListProgressInput
    if (isComplete) {
      // TODO: redirect/show modal saying congratulations, you have completed this stage
      input.progress_status_id = PROGRESS_STATUS_COMPLETE + ''
      setShowStageCompleteModal(true)
    }
    saveProgress(input)
  }

  if (!canReveal) return null

  return (
    <Button
      variant='success'
      className='text-white w-full'
      onClick={reveal}
    >
      {__('Reveal picture')}
    </Button>
  )
}
export default RevealButton
