import {useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {api} from 'src/queries/list.enhanced'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Spinner from '@/Spinner'
import Picture from './includes/Picture'
import RevealButton from './includes/RevealButton'
import {SaveListProgressInput} from 'src/queries/schema.generated'
import {quizzesCreate, quizzesSkip} from 'src/routes/lists'
import ScrollView from '@/ScrollView'
import Header from './includes/Header'
import StageCompleteModal from '~/practice-sessions/includes/StageCompleteModal'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import Progress from '@/Progress'
import Heading from '@/Heading'
import View from '@/View'
import Paragraph from '@/Paragraph'
import Column from '@/Column'
import ProgressPanel from './includes/ProgressPanel'

const Dashboard = () => {
  useRedirectIfNotLoggedIn()
  const {id} = useParams()
  const {__} = useTranslations()
  const navigate = useNavigate()
  const {data, isLoading, error} = api.endpoints.list.useQuery({id})
  const [saveProgress, {reset}] = api.endpoints.saveListProgress.useMutation()
  const [showStageCompleteModal, setShowStageCompleteModal] =
    useState<boolean>(false)

  if (isLoading || !data?.list) {
    return <Spinner />
  }
  const {list} = data

  const footer = (
    <Button
      className='m-2'
      onClick={() => navigate(quizzesCreate(list.id))}
    >
      {__('Start quiz')}
    </Button>
  )

  return (
    <ScrollView
      header={<Header list={list} />}
      footer={footer}
    >
      <View className='m-3'>
        <ProgressPanel percent={list.percent_complete || 0} />
        <Column className='gap-3'>
          <Picture list={list} />
          <RevealButton
            record={list}
            setShowStageCompleteModal={setShowStageCompleteModal}
            saveProgress={(data: SaveListProgressInput) => {
              saveProgress({input: {...data, list_id: list.id}})
            }}
          />
          {list.statusName === 'Complete' || (
            <Button
              variant='secondary'
              className='text-white w-full btn-outline'
              onClick={() => navigate(quizzesSkip(list.id))}
            >
              {__('Skip this list?')}
            </Button>
          )}
        </Column>
      </View>
      <StageCompleteModal
        visible={showStageCompleteModal}
        setVisible={setShowStageCompleteModal}
      >
        <Paragraph>
          {__(
            `You have unlocked this stage: :name, and :picture has been added to your collection`,
            {
              name: list.name,
              picture: list.picture?.title || __('a new picture'),
            }
          )}
          !
        </Paragraph>
      </StageCompleteModal>
    </ScrollView>
  )
}
export default Dashboard
