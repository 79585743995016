import {api as generated} from './picture.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Lists'],
  endpoints: {
    picture: {
      providesTags: ['Lists'],
    },
    pictures: {
      providesTags: ['Lists'],
    },
  },
})
