import {List} from 'src/queries/schema.generated'
import Icon from '@/Icon'
import Practice from './Practice'
import PracticePath from './PracticePath'
import ListPath from './ListPath'
import {getLinkColour} from '../helpers'
import Pressable from '@/Pressable'
import Row from '@/Row'
import View from '@/View'

type Props = {
  record: List
  onClick: () => void
  variation: 'l' | 'r'
  colour: string
  isFirst: boolean
  isLast: boolean
}
const ListItem = ({record, onClick, variation, isFirst, isLast}: Props) => {
  const isDisabled = record.statusName === 'Locked'

  const colour = getLinkColour(record.statusName || '')
  const practice = record.practiceSessions?.[0] || null
  const cursor = isDisabled ? 'cursor-not-allowed' : ''

  // console.log({practice})

  const getSlots = () => {
    const slots = [
      <Pressable
        onClick={onClick}
        className={`z-10 border-2 rounded-full h-24 w-24 inline-flex items-center justify-center ${colour} ${cursor}`}
      >
        {/* {record.name} */}
        <Icon
          name={record?.icon || 'fa/user'}
          faSize='3x'
          size={60}
        />
      </Pressable>,
      <Practice
        record={practice}
        variation={variation}
        isLast={isLast}
      />,
      null,
    ]
    return variation === 'r' ? slots.slice().reverse() : slots
  }
  const slots = getSlots()

  return (
    <>
      {practice && !isLast && (
        <PracticePath
          status={practice.statusName || ''}
          variation={variation}
          locked={practice.statusName === 'Locked'}
        />
      )}
      {record && !isFirst && (
        <ListPath
          status={record.statusName || ''}
          variation={variation}
          locked={record.statusName === 'Locked'}
        />
      )}
      <Row className='text-center'>
        <View className='w-1/3 z-0'>{slots[0]}</View>
        <View className='w-1/3 z-[1]'>{slots[1]}</View>
        <View className='w-1/3 z-0'>{slots[2]}</View>
      </Row>
    </>
  )
}

export default ListItem
