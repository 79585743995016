import {url} from 'src/config'
import Collection from '~/pictures/Collection'
import PicturesShow from '~/pictures/Show'

export default [
  {path: 'collection', element: <Collection />},
  {path: 'pictures/:id', element: <PicturesShow />},
]

export const collection = () => `${url}collection`
export const picturesShow = (id: string) => `${url}pictures/${id}`
