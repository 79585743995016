import {api as generated} from './practice-session.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['Levels', 'Lists'],
  endpoints: {
    practiceSession: {
      providesTags: ['Lists'],
    },
    savePracticeSessionProgress: {
      invalidatesTags: ['Lists'],
    },
  },
})
