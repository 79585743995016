import * as config from 'src/resources/lang'

export const locales = {
  de: 'Deutsch',
  en: 'English',
  es: 'Espanõl',
  fr: 'Français',
  it: 'Italiano',
  pl: 'Polski',
}

// TODO: handle translations
const __ = (x: string): string => x
// const __ = (x: string): string => `@@!!${x}`

export const translate =
  (locale: any) =>
  (input: string, data?: any): string => {
    if (Object.keys(locales).includes(locale)) {
      return translateFromDictionary(
        config[locale as keyof typeof config] || {},
        input,
        data
      )
    }
    return input // fallback if locale doesn't exist
  }

const translateFromDictionary = (
  dictionary: any,
  input: string,
  data?: any
): string => {
  if (Object.keys(dictionary).includes(input)) {
    // return strings[input]
    return replaceStrings(dictionary[input], data)
  }
  return replaceStrings(input, data) // fallback if string doesn't exist in locale
}

export const replaceStrings = (input: string, data?: any): string => {
  const matches = [...input.matchAll(/\:([a-zA-Z0-9\_\-]+)/g)]
  const processed = matches.reduce((acc: string, match: any) => {
    const item = match[1]
    const replace = data[item]
    return replace ? acc.replace(`:${item}`, replace) : acc
  }, input)
  return processed
}

export default __
