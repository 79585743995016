import DropdownNav from '@/layout/DropdownNav'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useState} from 'react'
import {useDispatch} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {clearAll, setIsSignOut} from 'src/reducers/user'
import {account, changePassword, settings} from 'src/routes/account'
import {index} from 'src/routes/app'
import {collection} from 'src/routes/pictures'
import {revisionList} from 'src/routes/revision-lists'
import {api} from 'src/services/api'
import {useTranslations} from 'src/utils/hooks'
import Anchor from '@/Link'

const SettingsNav = () => {
  const {__} = useTranslations()
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const logout = () => {
    // dispatch(setUser(null))
    // dispatch(setToken(null))
    dispatch(api.util.resetApiState())
    dispatch(clearAll())
    dispatch(setIsSignOut(true))
    navigate(index())
  }

  // TODO: fix this behaviour somehow (glitches if you click out of the dropdown)
  const closeDropdown = () => {
    const elem = document.activeElement as any
    if (elem && isOpen) {
      elem?.blur()
      setIsOpen(false)
    }
  }

  const items = [
    <Link to={account()}>{__('Update account details')}</Link>,
    <Link to={changePassword()}>{__('Change password')}</Link>,
    <Link to={collection()}>{__('My collection')}</Link>,
    <Link to={revisionList()}>{__('Revision list')}</Link>,
    <Link to={settings()}>{__('Settings')}</Link>,
    <Anchor onClick={logout}>{__('Logout')}</Anchor>,
  ]

  return (
    <DropdownNav
      onClick={() => {
        setIsOpen(!isOpen)
        if (isOpen) closeDropdown()
      }}
      closeDropdown={closeDropdown}
      items={items}
    />
  )
}
export default SettingsNav
