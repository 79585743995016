import MaterialCommunityIcon from '@mdi/react'
import {
  mdiFruitGrapesOutline,
  mdiBroom,
  mdiSilverwareForkKnife,
  mdiToothbrushPaste,
  mdiAbacus,
  mdiTrainCar,
  mdiChatProcessingOutline,
  mdiChatQuestion,
  mdiFood,
  mdiThoughtBubbleOutline,
  mdiPeriodicTable,
} from '@mdi/js'

const getPath = (icon: string) => {
  switch (icon) {
    case 'abacus':
      return mdiAbacus
    case 'broom':
      return mdiBroom
    case 'chat-processing-outline':
      return mdiChatProcessingOutline
    case 'chat-question':
      return mdiChatQuestion
    case 'food':
      return mdiFood
    case 'fruit-grapes-outline':
      return mdiFruitGrapesOutline
    case 'periodic-table':
      return mdiPeriodicTable
    case 'silverware-fork-knife':
      return mdiSilverwareForkKnife
    case 'toothbrush-paste':
      return mdiToothbrushPaste
    case 'thought-bubble-outline':
      return mdiThoughtBubbleOutline
    case 'train-car':
      return mdiTrainCar
  }
  return null
}

type Props = {
  icon: string
  size?: number
}
const Icon = ({icon, ...props}: Props) => {
  const adjustedSize = (props.size || 20) + 'px'
  const path = getPath(icon)
  if (!path) return null
  return (
    <MaterialCommunityIcon
      path={path}
      size={adjustedSize}
    />
  )
}
export default Icon
