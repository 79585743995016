import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type LevelsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type LevelsQuery = { __typename?: 'Query', levels: Array<{ __typename?: 'Level', id: string, name?: string | null, sequence?: number | null, lists?: Array<{ __typename?: 'List', id: string, name: string, sequence?: number | null, version?: number | null, icon?: string | null } | null> | null }> };

export type LevelQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type LevelQuery = { __typename?: 'Query', level?: { __typename?: 'Level', id: string, name?: string | null, sequence?: number | null, lists?: Array<{ __typename?: 'List', id: string, name: string, icon?: string | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null, version?: number | null, sequence?: number | null, practiceSessions?: Array<{ __typename?: 'PracticeSession', id: string, name?: string | null, icon?: string | null, sequence?: number | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null } | null> | null } | null> | null } | null };

export type SortListsAlphabeticMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type SortListsAlphabeticMutation = { __typename?: 'Mutation', sortListsAlphabetic?: boolean | null };


export const LevelsDocument = `
    query levels {
  levels {
    id
    name
    sequence
    lists {
      id
      name
      sequence
      version
      icon
    }
  }
}
    `;
export const LevelDocument = `
    query level($id: ID) {
  level(id: $id) {
    id
    name
    sequence
    lists {
      id
      name
      icon
      statusName
      intro
      question_types
      version
      sequence
      practiceSessions {
        id
        name
        icon
        sequence
        statusName
        intro
        question_types
      }
    }
  }
}
    `;
export const SortListsAlphabeticDocument = `
    mutation sortListsAlphabetic($id: ID!) {
  sortListsAlphabetic(id: $id)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    levels: build.query<LevelsQuery, LevelsQueryVariables | void>({
      query: (variables) => ({ document: LevelsDocument, variables })
    }),
    level: build.query<LevelQuery, LevelQueryVariables | void>({
      query: (variables) => ({ document: LevelDocument, variables })
    }),
    sortListsAlphabetic: build.mutation<SortListsAlphabeticMutation, SortListsAlphabeticMutationVariables>({
      query: (variables) => ({ document: SortListsAlphabeticDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


