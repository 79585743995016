import {Link, useParams} from 'react-router-dom'
import {api} from 'src/queries/list.enhanced'
import {useTranslations} from 'src/utils/hooks'
import Spinner from '@/Spinner'
import WordRow from '~/words/includes/WordRow'
import {api as revisionApi} from 'src/queries/revision-word.enhanced'
import Button from '@/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import ScrollView from '@/ScrollView'
import Header from './includes/Header'
import ResetStatsButton from '~/words/includes/ResetStatsButton'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import Row from '@/Row'

const Words = () => {
  useRedirectIfNotLoggedIn()
  const {id} = useParams()
  const {__} = useTranslations()

  const {data, isLoading, error} = api.endpoints.list.useQuery({id})
  const [addToRevisionList] =
    revisionApi.endpoints.createRevisionWord.useMutation()
  const [removeFromRevisionList] =
    revisionApi.endpoints.deleteRevisionWord.useMutation()

  if (isLoading || !data?.list) {
    return <Spinner />
  }
  const {list} = data

  const footer = (
    <Row>
      <ResetStatsButton list={list} />
    </Row>
  )
  return (
    <ScrollView
      header={<Header list={list} />}
      footer={footer}
    >
      {(list.words || []).map((word, i) => {
        if (!word) return null
        return (
          <WordRow
            key={i}
            listId={list.id}
            word={word}
            addToRevisionList={async (word_id) => {
              await addToRevisionList({word_id, list_id: list.id})
              // await refetch()
            }}
            removeFromRevisionList={async (id) => {
              // console.log('id', id)
              await removeFromRevisionList({id})
              // await refetch()
            }}
          />
        )
      })}
    </ScrollView>
  )
}
export default Words
