import {adminUrl} from 'src/config'
import Index from '~/admin/levels/Index'

export default [
  {
    path: 'admin/levels',
    element: <Index />,
  },
]

export const levels = () => `${adminUrl}levels`
