import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type QuizQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type QuizQuery = { __typename?: 'Query', quiz?: { __typename?: 'Quiz', id: string, user_id?: string | null, list_id?: string | null, date?: any | null, time_taken?: string | null, list?: { __typename?: 'List', id: string, name: string } | null, questions?: Array<{ __typename?: 'QuizQuestion', id: string, quiz_id?: string | null, question_id?: string | null, answer?: string | null, correct?: boolean | null, time_taken?: string | null, question_type_id?: string | null, question_type_template_id?: string | null, word_id?: string | null, question_text?: string | null, answer_type_id?: string | null, accepted_answers?: string | null, word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null } | null } | null> | null } | null };

export type QuizzesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QuizzesQuery = { __typename?: 'Query', quizzes: Array<{ __typename?: 'Quiz', id: string, user_id?: string | null, list_id?: string | null, date?: any | null, time_taken?: string | null, list?: { __typename?: 'List', id: string, name: string } | null, questions?: Array<{ __typename?: 'QuizQuestion', id: string, quiz_id?: string | null, question_id?: string | null, answer?: string | null, correct?: boolean | null, time_taken?: string | null, question_type_id?: string | null, question_type_template_id?: string | null, word_id?: string | null, question_text?: string | null, answer_type_id?: string | null, accepted_answers?: string | null, word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null } | null } | null> | null }> };

export type CreateQuizMutationVariables = Types.Exact<{
  input: Types.SaveQuizInput;
}>;


export type CreateQuizMutation = { __typename?: 'Mutation', createQuiz?: { __typename?: 'Quiz', id: string } | null };


export const QuizDocument = `
    query quiz($id: ID) {
  quiz(id: $id) {
    id
    user_id
    list_id
    date
    time_taken
    list {
      id
      name
    }
    questions {
      id
      quiz_id
      question_id
      answer
      correct
      time_taken
      question_type_id
      question_type_template_id
      word_id
      question_text
      answer_type_id
      accepted_answers
      word {
        id
        word_type_id
        word
        article
        plural
        full_word
        translations {
          word
        }
      }
    }
  }
}
    `;
export const QuizzesDocument = `
    query quizzes {
  quizzes {
    id
    user_id
    list_id
    date
    time_taken
    list {
      id
      name
    }
    questions {
      id
      quiz_id
      question_id
      answer
      correct
      time_taken
      question_type_id
      question_type_template_id
      word_id
      question_text
      answer_type_id
      accepted_answers
      word {
        id
        word_type_id
        word
        article
        plural
        full_word
        translations {
          word
        }
      }
    }
  }
}
    `;
export const CreateQuizDocument = `
    mutation createQuiz($input: SaveQuizInput!) {
  createQuiz(input: $input) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    quiz: build.query<QuizQuery, QuizQueryVariables | void>({
      query: (variables) => ({ document: QuizDocument, variables })
    }),
    quizzes: build.query<QuizzesQuery, QuizzesQueryVariables | void>({
      query: (variables) => ({ document: QuizzesDocument, variables })
    }),
    createQuiz: build.mutation<CreateQuizMutation, CreateQuizMutationVariables>({
      query: (variables) => ({ document: CreateQuizDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


