import {forwardRef, useEffect} from 'react'

import __ from 'src/utils/lang'
import Input from '@/form/Input'
import {useFocus} from 'src/utils/useFocus'
import View from '@/View'

type Props = {
  value: string
  setValue: (value: string) => void
  submit: () => void
}
const FreeTextInput = forwardRef(({value, setValue, submit}: Props, ref) => {
  const [inputRef, setInputFocus] = useFocus()
  useEffect(() => {
    setInputFocus()
  }, [inputRef])
  return (
    <View className='mx-10 flex justify-center'>
      <Input
        ref={inputRef}
        value={value || ''}
        onChange={setValue}
        onKeyUp={(e) => {
          if (e.key === 'Enter') submit()
        }}
      />
    </View>
  )
})
export default FreeTextInput
