import classNames from 'classnames'
import {ViewProps} from '@/types'
import Column from '@/Column'

const FormContainer = ({children, ...props}: ViewProps) => {
  const defaults = 'm-2 gap-3 px-2 sm:px-8'
  const className = classNames(defaults, props.className)
  return (
    <Column
      {...props}
      className={className}
    >
      {children}
    </Column>
  )
}
export default FormContainer
