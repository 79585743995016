import sample from 'lodash/sample'
import React, {ReactNode, useEffect} from 'react'
import {useTranslations} from 'src/utils/hooks'
import {useFocus} from 'src/utils/useFocus'
import Button from '@/Button'
import Modal from '@/Modal'
import {getAcceptedAnswers} from '../helpers'
import Column from '@/Column'
import Span from '@/Span'
import Row from '@/Row'

type Props = {
  visible: boolean
  setVisible: Function
  question: any
  submit: () => void
}
const IncorrectModal = ({visible, setVisible, submit, question}: Props) => {
  const {__} = useTranslations()
  const [btnRef, setBtnFocus] = useFocus()
  useEffect(() => {
    setBtnFocus()
  }, [btnRef])
  const messages = ['Bad luck!', 'Never mind!', 'The answer is incorrect']
  const message = sample(messages) as string

  const actions = [
    <Button
      key={0}
      ref={btnRef}
      variant='error'
      className='w-full'
      onClick={submit}
    >
      {__('Continue')}
    </Button>,
  ]
  return (
    <Modal
      // variant='error'
      visible={visible}
      setVisible={setVisible}
      actions={actions}
      title={__(message)}
    >
      <Column>
        <Row className='justify-center items-center text-center gap-2'>
          <Span className='italic text-sm'>{__('Correct answer')}:</Span>
          <Span className='font-bold'>
            {' '}
            {getAcceptedAnswers(question.accepted_answers).join('; ')}
          </Span>
        </Row>
      </Column>
    </Modal>
  )
}

export default IncorrectModal
