import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '.'

export const initialState = {
  current: null,
  token: null,
  language: '1',
  level: '',
  isSignOut: false,
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearAll: () => initialState,
    setUser: (state: any, {payload}) => ({...state, current: payload}),
    setToken: (state: any, {payload}) => ({...state, token: payload}),
    setLanguage: (state: any, {payload}) => ({
      ...state,
      language: payload,
    }),
    setLevel: (state: any, {payload}) => ({...state, level: payload}),
    setIsSignOut: (state: any, {payload}) => ({
      ...state,
      isSignOut: payload,
    }),
  },
})

// selectors
export const selectToken = ({user}: RootState) => user.token
export const selectUser = ({user}: RootState) => user.current
export const selectLanguage = ({user}: RootState) => '1' // user.language
export const selectLevel = ({user}: RootState) => user.level
export const selectIsSignOut = ({user}: RootState) => user.isSignOut

export const {
  clearAll,
  setToken,
  setUser,
  setLanguage,
  setLevel,
  setIsSignOut,
} = slice.actions

export default slice.reducer
