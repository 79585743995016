import {ReactNode} from 'react'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {ViewProps} from '../types'

type Props = {
  onClick: () => void
  closeDropdown: () => void
  items: ReactNode[]
}
const DropdownNav = ({onClick, closeDropdown, items, ...props}: Props) => {
  return (
    <div className='dropdown dropdown-end'>
      <div
        tabIndex={0}
        role='button'
        className='btn btn-ghost btn-circle avatar'
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon({name: 'bars'})} />
      </div>
      <ul
        tabIndex={0}
        className='menu menu-sm dropdown-content mt-3 z-[2] p-2 shadow bg-base-100 rounded-box w-52 [&>*>a]:block [&>*>*]:text-right text-slate-700'
        onClick={closeDropdown}
      >
        {items.map((item, i) => {
          return <li key={i}>{item}</li>
        })}
      </ul>
    </div>
  )
}
export default DropdownNav
