import {createSlice} from '@reduxjs/toolkit'
import {RootState} from '.'

export const initialState = {
  id: '',
  word: '',
  translation: '',
}

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearAll: () => initialState,
    setValue: {
      prepare: (key: string, value: string): any => ({payload: {key, value}}),
      reducer: (state: any, {payload: {key, value}}) => ({
        ...state,
        [key]: value,
      }),
    },
  },
  // extraReducers: {
  //   init: () => initialState,
  // },
})

export const {setValue} = slice.actions

export const selectSearch = ({search}: RootState) => search

export default slice.reducer
