import random from 'lodash/random'
import sample from 'lodash/sample'

export const getSquaresToReveal = (count: number, current: number[] = []) => {
  // console.log({count, current})
  const items = Array(count).fill(0)
  return items.map((x) => {
    let n = random(0, 99)
    while (current.includes(n)) {
      n = random(0, 99)
    }
    current = current.concat([n])
    return n
  })
}

export const getGrid = (rowCount = 10, columnCount = 10) => {
  const shades = [
    'bg-purple-300',
    'bg-purple-400',
    'bg-purple-500',
    'bg-purple-600',
  ]
  const rows = Array(rowCount).fill(0)
  const columns = Array(columnCount).fill(0)
  const items = rows.map((_, row) => {
    return columns.map((_, col) => {
      const index = row * 10 + col
      return {
        index,
        row,
        col,
        shade: sample(shades),
      }
    })
  })
  return items
  // console.log(items)
}

export const getLinkColour = (status: string) => {
  switch (status) {
    case 'Locked':
      return 'text-slate-400 border-slate-400 bg-slate-100'
    case 'Complete':
      return 'text-success border-success bg-green-200'
    case 'Skipped':
      return 'text-fuchsia-500 border-fuchsia-500 bg-fuchsia-200'
  }
  return 'text-primary border-primary bg-purple-200'
}

export const getPathColour = (status: string) => {
  switch (status) {
    case 'Locked':
      return 'border-slate-400'
    case 'Complete':
      return 'border-green-600'
    case 'Skipped':
      return 'border-fuchsia-500'
  }
  return 'border-purple-400'
}
