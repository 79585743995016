import {useDispatch, useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {RootState} from 'src/reducers'
import {setEnableSounds, setEnableSpeech} from 'src/reducers/preferences'
import {index} from 'src/routes/app'
import {api} from 'src/services/api'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import useTranslations from 'src/utils/hooks/useTranslations'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import FormGroup from '@/form/FormGroup'
import View from '@/View'
import Column from '@/Column'
import Checkbox from '@/form/Checkbox'
import FormContainer from '@/form/FormContainer'

const Settings = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const preferences = useSelector(({preferences}: RootState) => preferences)

  return (
    <ScrollView>
      <FormContainer className='mt-5'>
        <Button
          className='btn-block btn-outline'
          onClick={() => {
            dispatch(api.util.resetApiState())
            navigate(index())
          }}
        >
          {__('Clear cache')}
        </Button>
        <FormGroup>
          <Checkbox
            checked={preferences.enableSounds}
            onChange={(e) => dispatch(setEnableSounds(e.target.checked))}
            label={__('Enable sounds')}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={preferences.enableSpeech}
            onChange={(e) => dispatch(setEnableSpeech(e.target.checked))}
            label={__('Enable speech')}
          />
        </FormGroup>
      </FormContainer>
    </ScrollView>
  )
}

export default Settings
