import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {EntypoIcon, EntypoSprite} from '@entypo-icons/react'
import {SizeProp} from '@fortawesome/fontawesome-svg-core'
import MaterialIcon, {IconOwnProps} from '@mui/material/Icon'
import MaterialCommunityIcon from './icon/MaterialCommunityIcon'

type Props = {
  name: string
  faSize?: SizeProp
  size?: number
  className?: string
}
const Icon = ({name, ...props}: Props) => {
  if (!name || name.indexOf('/') < 0) return null
  const [iconType, icon] = name.split('/') as any
  let adjustedSize = props.size
  // console.log(name, props)
  switch (iconType) {
    case 'fa':
    case 'fa5':
      return (
        <FontAwesomeIcon
          size={props.faSize || 'lg'}
          icon={icon}
        />
      )
    case 'entypo':
      return (
        <EntypoIcon
          size={props.size || 20}
          // color='green'
          icon={icon}
        />
      )
    case 'material-icons':
    case 'mi':
      adjustedSize = props?.size || 20
      return (
        <div
          style={{fontSize: adjustedSize}}
          className='inline-flex justify-center items-center'
        >
          <MaterialIcon fontSize='inherit'>
            {icon.replace('-', '_')}
          </MaterialIcon>
          {/* <span className='material-icons notranslate MuiIcon-root css-kp9ftd-MuiIcon-root'>
            {icon}
          </span> */}
        </div>
      )
    case 'material-community-icons':
    case 'mci':
      return (
        <MaterialCommunityIcon
          icon={icon}
          size={props.size}
        />
      )
  }
  return null
}
export default Icon
