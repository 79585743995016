import {url} from 'src/config'
import RevisionList from '~/revision-list/Show'
import CreateRevisionListQuiz from '~/quizzes/CreateRevisionListQuiz'
import Settings from '~/revision-list/Settings'

export default [
  {
    path: '/revision-list',
    element: <RevisionList />,
  },
  {
    path: '/revision-list/settings',
    element: <Settings />,
  },
  {path: 'revision-list/quiz', element: <CreateRevisionListQuiz />},
]

export const revisionList = () => `${url}revision-list`
export const revisionListSettings = () => `${url}revision-list/settings`
export const revisionListQuiz = () => `${url}revision-list/quiz`
