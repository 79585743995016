import classNames from 'classnames'
import {ViewProps} from './types'
import View from './View'

const getDefaults = (cols: number | string) => {
  const map = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3',
    4: 'grid-cols-4',
    5: 'grid-cols-5',
    6: 'grid-cols-6',
    7: 'grid-cols-7',
    8: 'grid-cols-8',
    9: 'grid-cols-9',
    10: 'grid-cols-10',
    11: 'grid-cols-11',
    12: 'grid-cols-12',
  }
  return map?.[cols as keyof typeof map] || ''
}

interface Props extends ViewProps {
  cols: number | string
}
const Grid = ({cols, children, ...props}: Props) => {
  const defaults = getDefaults(cols)
  const className = classNames('grid', defaults, props.className)
  return (
    <View
      {...props}
      className={className}
    >
      {children}
    </View>
  )
}
export default Grid
