import Heading from '@/Heading'
import Progress from '@/Progress'
import View from '@/View'
import {useTranslations} from 'src/utils/hooks'

const ProgressPanel = ({percent}: {percent: number}) => {
  const {__} = useTranslations()
  return (
    <View className='p-3 border-2 rounded-2xl border-purple-600 bg-purple-200'>
      <Heading
        size='h5'
        className='text-center'
      >
        {__('Stage progress')}
      </Heading>
      <Progress
        variant='primary'
        value={percent}
      />
    </View>
  )
}
export default ProgressPanel
