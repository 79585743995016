export const getQuestionTypeName = (name: string) => {
  // TODO
  switch (name) {
    case 'Translate in':
      return 'Translate (German to English)'
    case 'Translate out':
      return 'Translate (English to German)'
    case 'Nominative articles (definite)':
      return 'Der, die or das?'
  }
  return name
}
