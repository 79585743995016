import {useState} from 'react'
// import { Translation } from 'src/queries/schema.generated'
import {cancelDefault} from 'src/utils'
import Translation from '../../translations/includes/Translation'
import EditWord from './EditWord'

type Props = {
  word: any
  index: number
  sortWord: (oldIndex: number, newIndex: number) => void
}
const Word = ({word, index, sortWord}: Props) => {
  const [isDragging, setIsDragging] = useState(false)
  const [editing, setEditing] = useState(false)
  const [editingTranslation, setEditingTranslation] = useState(false)

  const handleDrop = (e: React.DragEvent, index: number) => {
    e.stopPropagation()
    const type = e.dataTransfer.getData('entityType')
    const id = e.dataTransfer.getData('entityId')
    const oldIndex = e.dataTransfer.getData('index')
    console.log('wordDrop', {type, oldIndex, id, index})
    switch (type) {
      case 'word':
        sortWord(parseInt(oldIndex), index)
        return
    }
    // console.log(`Move item ${oldIndex} to position ${index}`)
  }

  const handleDragStart = (e: React.DragEvent, index: number) => {
    e.stopPropagation()
    setIsDragging(true)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('entityId', word.id)
    e.dataTransfer.setData('entityType', 'word')
    e.dataTransfer.setData('index', index + '')
    // cancelDefault(e)
  }

  return (
    <li
      className={`bg-slate-50 border px-4 py-2 text-sm flex justify-between ${
        isDragging ? 'opacity-25' : ''
      }`}
      onDragStart={(e) => handleDragStart(e, index)}
      onDragEnd={(e) => setIsDragging(false)}
      onDragEnter={(e) => cancelDefault(e)}
      onDragOver={(e) => cancelDefault(e)}
      onDrop={(e) => handleDrop(e, index)}
      draggable
    >
      {editing ? (
        <EditWord
          word={word}
          close={() => setEditing(false)}
        />
      ) : (
        <>
          {editingTranslation || (
            <>
              <div onClick={() => setEditing(true)}>{word.full_word}</div>
              <div>{word.level}</div>
            </>
          )}
          <Translation
            word={word}
            editing={editingTranslation}
            setEditing={setEditingTranslation}
          />
        </>
      )}
    </li>
  )
}

export default Word
