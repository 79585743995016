import {pick} from 'lodash'
import {useEffect} from 'react'
import {api} from 'src/queries/list.enhanced'
import {ListQueryVariables} from 'src/queries/schema.generated'
import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Errors from '@/Errors'
import Spinner from '@/Spinner'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'

export const initialValues = {
  name: '',
  icon: '',
}
export const rules = {
  name: 'required',
}
type Props = {
  list: ListQueryVariables
  close: () => void
}
const Edit = ({list, close}: Props) => {
  const {__} = useTranslations()
  const {values, setValue, setValues, validate, setErrors, errors} = useForm({
    initialValues,
    rules,
  })
  const {data, isLoading, error} = api.endpoints.list.useQuery({id: list.id})
  const [updateList] = api.endpoints.updateList.useMutation()

  useEffect(() => {
    if (data?.list) setValues({...initialValues, ...data.list})
  }, [data])
  // const isLoading = false

  const submit = () => {
    if (!list.id) return
    const input = pick(values, Object.keys(initialValues))
    updateList({id: list.id, input})
    close()
  }

  if (isLoading) return <Spinner />

  return (
    <div className='p-5'>
      <Errors errors={errors} />
      <FormGroup label={__('Name')}>
        <Input
          value={values.name}
          onChange={(value: any) => setValue('name', value)}
        />
      </FormGroup>
      <FormGroup label={__('Icon')}>
        <Input
          value={values.icon || ''}
          onChange={(value: any) => setValue('icon', value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') submit()
          }}
        />
      </FormGroup>
      <div className='mt-5'>
        <Button onClick={submit}>{__('Save')}</Button>
      </div>
    </div>
  )
}
export default Edit
