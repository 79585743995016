import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Translation} from 'src/queries/schema.generated'
import {useSpeech, useTranslations} from 'src/utils/hooks'
import RevisionListButton from '~/words/includes/RevisionListButton'
import {getAcceptedAnswers} from '../helpers'
import AnswerCard from '@/AnswerCard'
import Row from '@/Row'
import classNames from 'classnames'
import Column from '@/Column'
import Span from '@/Span'
import View from '@/View'
import Pressable from '@/Pressable'

type Props = {
  question: any
  listId?: string
  correct: boolean
}
const AnswerRow = ({correct, question, listId}: Props) => {
  const {__} = useTranslations()
  const {playWord} = useSpeech()

  return (
    <Row
      className={classNames(
        'justify-between border-b-2 text-xs text-gray-800 pb-3 last:border-b-0 last:pb-0',
        {'border-emerald-400': correct},
        {'border-amber-500': !correct}
      )}
    >
      <Row className='gap-2'>
        {correct && (
          <FontAwesomeIcon
            icon={icon({name: 'check'})}
            className='text-emerald-500 flex items-center'
            size='lg'
          />
        )}
        <Column className='items-start justify-center'>
          {question.word ? (
            <>
              <Row className='gap-2'>
                <Span className='font-bold'>{question.word?.full_word}</Span>
                <Pressable
                  className={classNames(
                    {'text-emerald-600': correct},
                    {'text-amber-700': !correct}
                  )}
                  onClick={() => playWord(question.word?.full_word)}
                >
                  <FontAwesomeIcon icon={icon({name: 'ear-listen'})} />
                </Pressable>
              </Row>
              <Span className='italic'>
                {getAcceptedAnswers(question.accepted_answers).join('; ')}
              </Span>
            </>
          ) : (
            <Span>{question.question}</Span>
          )}
        </Column>
      </Row>
      <Row className='text-right items-center justify-end'>
        {question.word ? (
          <Column className='gap-1 items-end'>
            {correct || (
              <View className='italic text-amber-700'>
                <FontAwesomeIcon icon={icon({name: 'times'})} />{' '}
                {question.answer}
              </View>
            )}
            <RevisionListButton
              listId={listId}
              word={question.word}
              defaultColour='text-gray-300'
            />
          </Column>
        ) : (
          <Column
            className={classNames('italic', {'text-amber-700': !correct})}
          >
            {correct || (
              <Span>
                <FontAwesomeIcon icon={icon({name: 'times'})} />{' '}
                {question.answer}
              </Span>
            )}
            <Span className='font-bold text-slate-700 text-right'>
              {getAcceptedAnswers(question.accepted_answers).join('; ')}
            </Span>
          </Column>
        )}
      </Row>
    </Row>
  )
}

export default AnswerRow
