import levenshtein from 'fast-levenshtein'

export const cleanString = (input: string) => {
  return (
    input
      .trim()
      .toLowerCase()
      // .replace(/[^a-z0-9\s]+/, '')
      .replace(/[^0-9a-zA-Z\u00C0-\u024F\u1E00-\u1EFF]/, '')
  )
}

export const normalizeString = (input: string) => {
  return cleanString(input)
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
}

export const isSimilar = (a: string, b: string) => {
  const distance = getDistance(a, b)
  const percentSimilarity = getPercentSimilarity(a, b)
  if (percentSimilarity >= 84 && distance < 3) return true
  return false
}

export const getPercentSimilarity = (a: string, b: string) => {
  const longer = a.length >= b.length ? a : b
  const shorter = a.length >= b.length ? b : a
  const longerLength = longer.length
  if (longerLength === 0) return 100
  return ((longerLength - getDistance(longer, shorter)) / longerLength) * 100
}

export const getDistance = (a: string, b: string) => {
  return levenshtein.get(a, b)
}
