import {SyntheticEvent} from 'react'
import Column from './Column'
import Checkbox from './form/Checkbox'
import Span from './Span'
import View from './View'
import {CheckboxProps} from '@mui/material'

export type LabelValue = {
  key: string
  label: string
  description?: string | null
}

export type CheckboxListProps = {
  items: Array<LabelValue>
  value: Array<any>
  onChange?: any
  add: Function
  remove: Function
}

const CheckboxList = ({
  items,
  value,
  onChange,
  add,
  remove,
}: CheckboxListProps) => {
  // const add = (key) => {
  //   console.log('add', {key, value})
  //   // const values = value.filter((x: any) => x !== key).concat([key])
  //   const values = value.concat([key])
  //   onChange(values)
  // }

  // const remove = (key) => {
  //   console.log('remove', {key, value})
  //   const values = value.filter((x: any) => x !== key)
  //   onChange(values)
  // }

  // console.log({value})

  return (
    <Column className='gap-3'>
      {items.map(({key, label, description}, i) => (
        <Checkbox
          key={i}
          label={label}
          checked={value.indexOf(key) >= 0}
          onChange={(event: SyntheticEvent) => {
            const target = event.target as HTMLInputElement
            target.checked ? add(key) : remove(key)
          }}
        />
      ))}
    </Column>
  )
}
export default CheckboxList
