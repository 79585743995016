import {url} from 'src/config'
import Show from '~/practice-sessions/Show'
import CreatePracticeQuiz from '~/quizzes/CreatePracticeQuiz'

export default [
  {path: 'practice-sessions/:id', element: <Show />},
  {
    path: 'practice-sessions/:practiceSessionId/quiz',
    element: <CreatePracticeQuiz />,
  },
]

export const practiceSessionsShow = (id: string) =>
  `${url}practice-sessions/${id}`
export const quizzesCreate = (id: string) =>
  `${url}practice-sessions/${id}/quiz`
