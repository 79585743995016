import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import {v4 as uuidv4} from 'uuid'
import Toast from '@/Toast'

export type Toast = {
  id: string
  message: string
  placement?: string
  duration?: number
}

interface ToastContextType {
  queue: Toast[]
  setQueue?: any
}

export const ToastContext = createContext<ToastContextType>({
  queue: [],
})

type ProviderProps = {
  children: ReactNode
}
export const ToastProvider = ({children}: ProviderProps) => {
  const [queue, setQueue] = useState<Toast[]>([])
  const state = {
    queue,
    setQueue,
  }
  return <ToastContext.Provider value={state}>{children}</ToastContext.Provider>
}

const useToast = () => {
  const {queue, setQueue} = useContext(ToastContext)
  useEffect(() => {
    const timer = setTimeout(() => {
      setQueue(queue.slice(1))
    }, 2000) // TODO
    return () => clearTimeout(timer)
  }, [queue])
  const add = (message: string, duration: number = 2000) => {
    setQueue([...queue, {message, id: uuidv4(), duration}])
  }
  const remove = (id: string) => {
    setQueue(queue.filter((x) => x.id !== id))
  }
  return {
    queue,
    add,
    remove,
  }
}
export default useToast
