import View from '@/View'
import {ViewProps} from '@/types'
import classNames from 'classnames'

interface Props extends ViewProps {
  label?: string
}
const FormGroup = ({label, children, ...props}: Props) => {
  const defaults = 'form-control'
  const className = classNames(defaults, props.className)

  return (
    <View className={className}>
      {label && (
        <div className='label'>
          <span className='label-text'>{label}</span>
        </div>
      )}
      {children}
    </View>
  )
}
export default FormGroup
