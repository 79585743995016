import {List, Translation, Word} from 'src/queries/schema.generated'

const SearchResultItem = ({word}: {word: Word}) => {
  return (
    <div className='flex justify-between gap-2'>
      <div className='flex gap-5 w-3/4'>
        <div className='w-1/2'>{word.full_word}</div>
        <div className='w-1/2 italic'>
          {(word?.translations || ([] as Array<any>))
            .map((x: Translation) => x.word)
            .join('; ')}
        </div>
      </div>
      <div className=''>
        {(word?.lists || ([] as Array<any>)).map((list: List, j: number) => {
          return (
            <div
              key={j}
              className='text-xs'
            >
              {list?.level?.name || ''}: {list.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default SearchResultItem
