import {mdiLabel} from '@mdi/js'
import {Variant, ViewProps} from './types'
import classNames from 'classnames'

interface Props extends ViewProps {
  label: string
  variant?: Variant
  bg?: string
}
const Stat = ({label, children, ...props}: Props) => {
  const defaults = 'card text-white'
  const className = classNames(defaults, props.bg, props.className)
  return (
    <div className={className}>
      <div className='card-body p-1'>
        <div className='stat-title text-white'>{label}</div>
        <div className='stat-value font-semibold text-lg'>{children}</div>
      </div>
    </div>
  )
}
export default Stat
