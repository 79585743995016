import AnswerCard from '@/AnswerCard'
import AnswerRow from './AnswerRow'

type Props = {
  items: any
  listId?: string
}
const CorrectAnswers = ({items, listId}: Props) => {
  return (
    <AnswerCard
      textColour='text-emerald-500'
      borderColour='border-emerald-500'
    >
      {items.map((question: any, i: number) => {
        return (
          <AnswerRow
            correct={true}
            question={question}
            listId={listId}
            key={i}
          />
        )
      })}
    </AnswerCard>
  )
}

export default CorrectAnswers
