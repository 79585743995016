import {url} from 'src/config'

// import Login from '~/account/Login'
import Edit from '~/account/Edit'
import ChangePassword from '~/account/ChangePassword'
import ForgottenPassword from '~/account/ForgottenPassword'
import ResetPassword from '~/account/ResetPassword'
import Settings from '~/account/Settings'
import CreateAccount from '~/account/Create'

export default [
  // {path: 'login', element: <Login />},
  {path: 'create-account', element: <CreateAccount />},
  {path: 'account', element: <Edit />},
  {path: 'change-password', element: <ChangePassword />},
  {path: 'forgotten-password', element: <ForgottenPassword />},
  {path: 'reset-password/:token', element: <ResetPassword />},
  {path: 'settings', element: <Settings />},
]

// export const login = () => `${url}login`
export const createAccount = () => `${url}create-account`
export const account = () => `${url}account`
export const changePassword = () => `${url}change-password`
export const forgottenPassword = () => `${url}forgotten-password`
export const resetPassword = (token: string) => `${url}reset-password/${token}`
export const settings = () => `${url}settings`
