import classNames from 'classnames'
import useSound from 'use-sound'
import shuffle from 'lodash/shuffle'
import {useEffect, useState} from 'react'
import {Question, Translation} from 'src/queries/schema.generated'
import {useSpeech, useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import MatchButton from './MatchButton'
import {resourceUrl} from 'src/config'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'
import Column from '@/Column'
import Grid from '@/Grid'

type Props = {
  questions: Question[]
  next: () => void
  addCorrect: (id: string | string[]) => void
}

const MatchWords = ({questions, next, addCorrect}: Props) => {
  const {__} = useTranslations()
  const {playWord, init: speechInit, enableSpeech} = useSpeech()
  const {playSound} = useSounds()
  const [left, setLeft] = useState<Question[]>([])
  const [right, setRight] = useState<Question[]>([])
  const [selectedLeft, setSelectedLeft] = useState<string | null>(null)
  const [selectedRight, setSelectedRight] = useState<string | null>(null)
  const [answered, setAnswered] = useState<string[]>([])
  const [incorrect, setIncorrect] = useState<string[]>([])
  const [goodAnswers, setGoodAnswers] = useState<string[]>([])
  const [badAnswers, setBadAnswers] = useState<string[]>([])

  useEffect(() => {
    setLeft(shuffle(questions))
    setRight(shuffle(questions))
  }, [questions])
  // console.log({questions})

  useEffect(() => {
    const timer = setTimeout(() => {
      setGoodAnswers([])
    }, 500)
    return () => clearTimeout(timer)
  }, [goodAnswers])

  useEffect(() => {
    const timer = setTimeout(() => {
      setBadAnswers([])
    }, 500)
    return () => clearTimeout(timer)
  }, [badAnswers])

  const checkAnswer = (id1: string, id2: string) => {
    const correct = id1 === id2
    if (correct) {
      setAnswered(answered.concat([id1]))
    } else {
      setIncorrect(incorrect.concat([id1, id2])) // Set both as incorrect?
    }
    setSelectedLeft(null)
    setSelectedRight(null)
    return correct
  }

  const selectLeft = (question: Question) => {
    if (question.id === selectedLeft) {
      playSound(Sound.Tap)
      setSelectedLeft(null)
    } else {
      if (selectedRight) {
        const correct = checkAnswer(selectedRight, question.id)
        if (correct) {
          playSound(Sound.Success)
          setGoodAnswers([question.id, selectedRight])
        } else {
          playSound(Sound.Error)
          setBadAnswers([question.id, selectedRight])
        }
      } else {
        playSound(Sound.Tap)
        setSelectedLeft(question.id)
      }
    }
  }
  const selectRight = (question: Question) => {
    if (question.id === selectedRight) {
      setSelectedRight(null)
      playSound(Sound.Tap)
    } else {
      if (enableSpeech && question?.word?.full_word) {
        playWord(question.word.full_word)
      }
      if (selectedLeft) {
        const correct = checkAnswer(selectedLeft, question.id)
        if (correct) {
          playSound(Sound.Success)
          setGoodAnswers([selectedLeft, question.id])
        } else {
          playSound(Sound.Error)
          setBadAnswers([selectedLeft, question.id])
        }
      } else {
        playSound(Sound.Tap)
        setSelectedRight(question.id)
      }
    }
  }

  const submit = () => {
    const correct = answered.filter((id) => !incorrect.includes(id))
    addCorrect(correct)
    setAnswered([])
    setIncorrect([])
    next()
  }

  const footer = (
    <Button
      onClick={submit}
      className='m-2'
      disabled={answered.length < questions.length}
    >
      {__('Next')}
    </Button>
  )

  return (
    <ScrollView footer={footer}>
      <Column className='p-5 h-full justify-center'>
        <Grid
          cols='2'
          className='flex-grow gap-6 sm:gap-8'
        >
          <Column className='flex-grow gap-6 sm:gap-8 justify-evenly'>
            {left.map((item, i) => {
              return (
                <MatchButton
                  key={i}
                  item={item}
                  select={selectLeft}
                  isAnswered={answered.includes(item.id)}
                  isSelected={selectedLeft === item.id}
                  isGoodAnswer={goodAnswers?.[0] === item.id}
                  isBadAnswer={badAnswers?.[0] === item.id}
                >
                  {(item.word?.translations || [])
                    .map((item: Translation | any) => item.word)
                    .join(', ')}
                </MatchButton>
              )
            })}
          </Column>
          <Column className='flex-grow gap-6 sm:gap-8 justify-evenly'>
            {right.map((item, i) => {
              return (
                <MatchButton
                  key={i}
                  item={item}
                  select={selectRight}
                  isSelected={selectedRight === item.id}
                  isAnswered={answered.includes(item.id)}
                  isGoodAnswer={goodAnswers?.[1] === item.id}
                  isBadAnswer={badAnswers?.[1] === item.id}
                >
                  {item.word?.word}
                </MatchButton>
              )
            })}
          </Column>
        </Grid>
      </Column>
    </ScrollView>
  )
}
export default MatchWords
