import Column from '@/Column'
import Grid from '@/Grid'
import Paragraph from '@/Paragraph'
import ScrollView from '@/ScrollView'
import Spinner from '@/Spinner'
import View from '@/View'
import {api} from 'src/queries/picture.enhanced'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import ListItem from './includes/ListItem'

const Collection = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {data, isLoading, refetch, error} = api.endpoints.pictures.useQuery()

  if (isLoading || !data) return <Spinner />
  return (
    <ScrollView title={__('My collection')}>
      {data.pictures.length > 0 ? (
        <Grid cols='4'>
          {data.pictures.map((picture, i) => {
            return (
              <View
                key={i}
                className='block w-full h-0 aspect-w-1 aspect-h-1'
              >
                <ListItem picture={picture} />
              </View>
            )
          })}
        </Grid>
      ) : (
        <Column className='mx-5 pb-20 h-full justify-center text-center text-slate-600'>
          <Paragraph className='text-xl italic'>
            {__('You do not have any items in your collection yet')}.
          </Paragraph>
          <Paragraph className='text-xl italic'>
            {__('Complete stages to unlock items')}!
          </Paragraph>
        </Column>
      )}
    </ScrollView>
  )
}
export default Collection
