import {useLocation, useNavigate} from 'react-router-dom'
import Nav from '@/TabNav'
import {listsShow, listsWords} from 'src/routes/lists'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

type Props = {
  listId: string
}
const TabNav = ({listId}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const items = [
    {
      to: listsShow(listId),
      path: location.pathname,
      children: <FontAwesomeIcon icon={icon({name: 'play'})} />,
    },
    {
      to: listsWords(listId),
      path: location.pathname,
      children: <FontAwesomeIcon icon={icon({name: 'book'})} />,
    },
  ]
  return <Nav items={items} />
}
export default TabNav
