import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import sample from 'lodash/sample'
import shuffle from 'lodash/shuffle'

import {useSpeech, useTranslations} from 'src/utils/hooks'
// import {api} from 'src/queries/translation.enhanced'
import {selectLanguage} from 'src/reducers/user'
import Spinner from '@/Spinner'
import ButtonOptions from '@/ButtonOptions'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {Translation} from 'src/queries/schema.generated'
import Column from '@/Column'
import QuestionTitle from './includes/QuestionTitle'
import Pressable from '@/Pressable'
import View from '@/View'
import useSounds, {Sound} from 'src/utils/hooks/useSounds'

const ANSWER_NUMBER = 7

// German > English
const Listen = ({
  id,
  question,
  submit,
  value,
  setValue,
  submitted,
  translations,
}: any) => {
  const {__} = useTranslations()
  const language_id = useSelector(selectLanguage)
  const answer = sample(question?.accepted_answers.split('|'))
  // const {data, isLoading} = api.endpoints.translations.useQuery({limit: 20})
  const [options, setOptions] = useState<any[]>([])
  const {playWord, init: speechInit, enableSpeech} = useSpeech()
  const {playSound, enableSounds} = useSounds()

  // console.log(JSON.stringify(question, null, 2))
  useEffect(() => {
    if (!translations) return
    const filtered = translations
      .filter((x: Translation) => x.word_id !== question.word_id)
      .filter(
        (x: Translation) =>
          x?.translatedWord?.word_type_id === question.word.word_type_id
      )
    const otherAnswers = shuffle(filtered).slice(
      0,
      Math.min(ANSWER_NUMBER, translations.length)
    )
    // console.log(
    //   'otherAnswers in',
    //   otherAnswers.map((x) => x.word_id),
    //   question.word_id
    // )
    setOptions(shuffle(otherAnswers.map((x) => x.word).concat(answer)))
  }, [translations, question])
  // useEffect(() => {
  //   if (!data) return
  //   const filtered = data.translations
  //     .filter((x) => x.word_id !== question.word_id)
  //     .filter(
  //       (x) => x?.translatedWord?.word_type_id === question.word.word_type_id
  //     )
  //   const otherAnswers = shuffle(filtered).slice(
  //     0,
  //     Math.min(ANSWER_NUMBER, data.translations.length)
  //   )
  //   // console.log(
  //   //   'otherAnswers in',
  //   //   otherAnswers.map((x) => x.word_id),
  //   //   question.word_id
  //   // )
  //   setOptions(shuffle(otherAnswers.map((x) => x.word).concat(answer)))
  // }, [data, question])

  useEffect(() => {
    if (!speechInit || !enableSpeech) return
    playWord(question.word.full_word)
  }, [question, speechInit])

  return (
    <Column className='h-full justify-between'>
      <Column className='h-40 justify-center'>
        <Pressable
          className='text-primary text-8xl'
          onClick={() => playWord(question.word.full_word)}
        >
          <FontAwesomeIcon icon={icon({name: 'volume-high'})} />
        </Pressable>
      </Column>
      <View className='mx-4 flex-1'>
        <ButtonOptions
          options={options}
          value={value}
          setValue={setValue}
          disabled={submitted}
          onClick={() => {
            if (enableSounds) playSound(Sound.Tap)
          }}
        />
      </View>
    </Column>
  )
}

export default Listen
