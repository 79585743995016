import {useEffect, useRef, useState} from 'react'
import {arrayMoveImmutable} from 'array-move'

import {api} from 'src/queries/list.enhanced'
import {Level, List} from 'src/queries/schema.generated'

import ListComponent from '~/admin/lists/includes/List'
import {cancelDefault} from 'src/utils'

type Props = {
  list: List
  close: () => void
}
const Component = ({list, close}: Props) => {
  const [updateList] = api.endpoints.updateList.useMutation()
  const [newName, setNewName] = useState<string>(list.name)
  const submit = () => {
    if (!newName) return
    const input = {
      name: newName,
    }
    // console.log({input})
    updateList({id: list.id, input})
    close()
  }
  return (
    <div className='flex flex-row justify-between gap-2'>
      <input
        className='border px-3 py-1 flex-1'
        type='text'
        placeholder='Add new list'
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') submit()
        }}
      />
      <button
        className='border px-2'
        onClick={() => close()}
      >
        Cancel
      </button>
      <button
        className='bg-green-600 text-white px-2'
        onClick={submit}
      >
        Update
      </button>
    </div>
  )
}
export default Component
