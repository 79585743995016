import {createBrowserRouter} from 'react-router-dom'

import app from './app'
import admin from './admin'
import NotFound from '~/layout/NotFound'

const router = createBrowserRouter([
  {
    children: [...app, ...admin],
    errorElement: <NotFound />,
  },
])

export default router
