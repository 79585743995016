/* global AudioContext:true,
 */

// var clickingBuffer = null
// Fix up prefixing
// window.AudioContext = window.AudioContext
const context = new AudioContext()

export const loadSound = async (url: string) => {
  const response = await fetch(url)
  // console.log({response})
  if (!response.ok) {
    throw new Error(`HTTP error, status = ${response.status}`)
  }
  const buffer = await response.arrayBuffer()
  const decoded = await context.decodeAudioData(buffer)
  return decoded
  // const source = new AudioBufferSourceNode(context)
  // source.buffer = decoded
  // source.connect(context.destination)
  // return source
  // return fetch("viper.ogg")
  //   .then((response) => {
  //     if (!response.ok) {
  //       throw new Error(`HTTP error, status = ${response.status}`);
  //     }
  //     return response.arrayBuffer();
  //   })
  //   .then((buffer) => audioCtx.decodeAudioData(buffer))
  //   .then((decodedData) => {
  //     const source = new AudioBufferSourceNode();
  //     source.buffer = decodedData;
  //     source.connect(audioCtx.destination);
  //     return source;
  //   });
  // var request = new XMLHttpRequest()
  // request.open('GET', url, true)
  // request.responseType = 'arraybuffer'
  // // Decode asynchronously

  // console.log({request, url})
  // request.onload = function () {
  //   console.log('here')
  //   context.decodeAudioData(request.response, function (buffer) {
  //     if (!buffer) {
  //       console.log('Error decoding file data: ' + url)
  //       return
  //     }
  //     console.log('loaded', url)
  //     clickingBuffer = buffer
  //   })
  //   request.onerror = function () {
  //     console.log('BufferLoader: XHR error')
  //   }
  //   request.send()
  // }
}
// export const loadSound = (url: string) => {
//   var request = new XMLHttpRequest()
//   request.open('GET', url, true)
//   request.responseType = 'arraybuffer'
//   // Decode asynchronously

//   console.log({request, url})
//   request.onload = function () {
//     console.log('here')
//     context.decodeAudioData(request.response, function (buffer) {
//       if (!buffer) {
//         console.log('Error decoding file data: ' + url)
//         return
//       }
//       console.log('loaded', url)
//       clickingBuffer = buffer
//     })
//     request.onerror = function () {
//       console.log('BufferLoader: XHR error')
//     }
//     request.send()
//   }
// }

export const playSound = (
  buffer: any,
  time: number = 0,
  volume: number = 0.1
) => {
  var source = context.createBufferSource() // creates a sound source
  source.buffer = buffer // tell the source which sound to play
  // source.connect(context.destination) // connect the source to the context's destination (the speakers)
  var gainNode = context.createGain() // Create a gain node
  source.connect(gainNode) // Connect the source to the gain node
  gainNode.connect(context.destination) // Connect the gain node to the destination
  // gainNode.gain.value = volume // Set the volume
  gainNode.gain.setValueAtTime(volume, time) // Set the volume
  gainNode.connect(context.destination)
  source.start(time) // play the source at the deisred time 0=now
}

// You call with in your document ready
// loadClickSound('sounds/click.wav')
//and this plays the sound
// playSound(clickingBuffer, 0, 1)
