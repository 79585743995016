import {List} from 'src/queries/schema.generated'
import TabNav from './TabNav'
import PageTitle from '@/PageTitle'
import {useTranslations} from 'src/utils/hooks'
import Heading from '@/Heading'
import Span from '@/Span'

const Header = ({list}: {list: any}) => {
  const {__} = useTranslations()
  return (
    <>
      <PageTitle>{list.name}</PageTitle>
      <>
        <Heading
          size='h2'
          className=''
        >
          {list.name}{' '}
          {list.statusName === 'Skipped' && (
            <Span className='italic text-sm align-middle'>
              ({__('Skipped')})
            </Span>
          )}
        </Heading>
        <TabNav listId={list.id} />
      </>
    </>
  )
}
export default Header
