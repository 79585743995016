import classNames from 'classnames'
import {ViewProps} from './types'

const Bar = ({...props}: ViewProps) => {
  const defaults = 'h-7'
  const className = classNames(defaults, props.className)
  return (
    <div
      {...props}
      className={className}
    />
  )
}
export default Bar
