import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type WordScoreQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type WordScoreQuery = { __typename?: 'Query', wordScore?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null };

export type WordScoresQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type WordScoresQuery = { __typename?: 'Query', wordScores: Array<{ __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null }> };

export type CreateWordScoreMutationVariables = Types.Exact<{
  input: Types.SaveWordScoreInput;
}>;


export type CreateWordScoreMutation = { __typename?: 'Mutation', createWordScore?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null };

export type UpdateWordScoreMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SaveWordScoreInput;
}>;


export type UpdateWordScoreMutation = { __typename?: 'Mutation', updateWordScore?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null };

export type DeleteWordScoreMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteWordScoreMutation = { __typename?: 'Mutation', deleteWordScore?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null };

export type ResetWordScoresMutationVariables = Types.Exact<{
  wordIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']['input']>> | Types.InputMaybe<Types.Scalars['ID']['input']>>;
  listId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ResetWordScoresMutation = { __typename?: 'Mutation', resetWordScores?: boolean | null };


export const WordScoreDocument = `
    query wordScore($id: ID) {
  wordScore(id: $id) {
    id
    user_id
    word_id
    correct
    incorrect
    phase_1
    phase_2
    phase_3
    phase_1_completed_at
    phase_2_completed_at
  }
}
    `;
export const WordScoresDocument = `
    query wordScores {
  wordScores {
    id
    user_id
    word_id
    correct
    incorrect
    phase_1
    phase_2
    phase_3
    phase_1_completed_at
    phase_2_completed_at
  }
}
    `;
export const CreateWordScoreDocument = `
    mutation createWordScore($input: SaveWordScoreInput!) {
  createWordScore(input: $input) {
    id
    user_id
    word_id
    correct
    incorrect
    phase_1
    phase_2
    phase_3
    phase_1_completed_at
    phase_2_completed_at
  }
}
    `;
export const UpdateWordScoreDocument = `
    mutation updateWordScore($id: ID!, $input: SaveWordScoreInput!) {
  updateWordScore(id: $id, input: $input) {
    id
    user_id
    word_id
    correct
    incorrect
    phase_1
    phase_2
    phase_3
    phase_1_completed_at
    phase_2_completed_at
  }
}
    `;
export const DeleteWordScoreDocument = `
    mutation deleteWordScore($id: ID!) {
  deleteWordScore(id: $id) {
    id
    user_id
    word_id
    correct
    incorrect
    phase_1
    phase_2
    phase_3
    phase_1_completed_at
    phase_2_completed_at
  }
}
    `;
export const ResetWordScoresDocument = `
    mutation resetWordScores($wordIds: [ID], $listId: ID) {
  resetWordScores(wordIds: $wordIds, list_id: $listId)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    wordScore: build.query<WordScoreQuery, WordScoreQueryVariables | void>({
      query: (variables) => ({ document: WordScoreDocument, variables })
    }),
    wordScores: build.query<WordScoresQuery, WordScoresQueryVariables | void>({
      query: (variables) => ({ document: WordScoresDocument, variables })
    }),
    createWordScore: build.mutation<CreateWordScoreMutation, CreateWordScoreMutationVariables>({
      query: (variables) => ({ document: CreateWordScoreDocument, variables })
    }),
    updateWordScore: build.mutation<UpdateWordScoreMutation, UpdateWordScoreMutationVariables>({
      query: (variables) => ({ document: UpdateWordScoreDocument, variables })
    }),
    deleteWordScore: build.mutation<DeleteWordScoreMutation, DeleteWordScoreMutationVariables>({
      query: (variables) => ({ document: DeleteWordScoreDocument, variables })
    }),
    resetWordScores: build.mutation<ResetWordScoresMutation, ResetWordScoresMutationVariables | void>({
      query: (variables) => ({ document: ResetWordScoresDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


