import {useTranslations} from 'src/utils/hooks'
import {api} from 'src/queries/user.generated'
import {setToken, setUser} from 'src/reducers/user'
import ScrollView from '@/ScrollView'
import {useDispatch, useSelector} from 'react-redux'
import {selectUser} from 'src/reducers/user'
import {useForm} from 'src/utils/form'
import pick from 'lodash/pick'
import {useNavigate} from 'react-router-dom'
import {index} from 'src/routes/app'
import Spinner from '@/Spinner'
import Button from '@/Button'
import Errors from '@/Errors'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import FormContainer from '@/form/FormContainer'

export const rules = {
  name: 'required',
  email: 'required|email',
}

const Edit = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const [update, {isLoading}] = api.endpoints.updateMe.useMutation()
  const user = useSelector(selectUser)
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues: pick(user, ['name', 'email']),
    rules,
  })
  const navigate = useNavigate()
  // const [apiErrors, setApiErrors] = useState([])
  const dispatch = useDispatch()

  const submit = () => {
    if (validate()) {
      // setApiErrors([])
      const input = values
      console.log({input})
      update({input})
        // .then((response: any) => {
        //   console.log(JSON.stringify(response, null, 2))
        // })
        .then(({data: {updateMe: response}}: any) => {
          if (response.status !== 'success') {
            const errors =
              response.errors && response.errors.length > 0
                ? response.errors
                : [
                    __(
                      'There was a problem submitting this data. Please try again.'
                    ),
                  ]
            setErrors({api: errors.join('\n')})
          } else {
            // console.log(JSON.stringify(response, null, 2))
            dispatch(setUser(response.user))
            // navigation.goBack()
            // navigate(-1)
            navigate(index())
          }
        })
    }
  }

  if (isLoading) return <Spinner />

  const footer = (
    <Button
      className='m-2'
      onClick={submit}
    >
      {__('Save')}
    </Button>
  )

  return (
    <ScrollView
      footer={footer}
      title={__('Update account')}
    >
      <FormContainer>
        <Errors errors={errors} />
        <FormGroup label={__('Name')}>
          <Input
            value={values.name}
            onChange={(value: any) => setValue('name', value)}
          />
        </FormGroup>
        <FormGroup label={__('Email')}>
          <Input
            type='email'
            value={values.email}
            onChange={(value: any) => setValue('email', value)}
          />
        </FormGroup>
      </FormContainer>
    </ScrollView>
  )
}
export default Edit
