import React, {useState} from 'react'
import sample from 'lodash/sample'

import {useTranslations} from 'src/utils/hooks'
import {replaceStrings} from 'src/utils/lang'
import {QuestionTypeTemplateOption} from 'src/queries/schema.generated'
import ButtonGroup from '@/ButtonGroup'

type Props = {
  template: any
  value: any
  setValue: any
  submitted: any
}
const MultipleChoice = ({template, value, setValue, submitted}: Props) => {
  const options = template.options.map(
    (option: QuestionTypeTemplateOption) => option.answer
  )
  return (
    <ButtonGroup
      options={options}
      value={value}
      setValue={setValue}
      disabled={submitted}
    />
  )
}

export default MultipleChoice
