import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import pick from 'lodash/pick'
import {v4 as uuid4} from 'uuid'
import {api} from 'src/queries/quiz.enhanced'
import {Question, Quiz, SaveQuizInput, User} from 'src/queries/schema.generated'
import {selectUser} from 'src/reducers/user'
import {useTranslations} from 'src/utils/hooks'
import {TIMESTAMP_FORMAT, formatDate} from 'src/utils/dateHelpers'
import Spinner from '@/Spinner'
import {calculateWordScores} from '../helpers'
import Button from '@/Button'
import {useNavigate} from 'react-router-dom'
import {useFocus} from 'src/utils/useFocus'
import StageCompleteModal from '~/practice-sessions/includes/StageCompleteModal'
import View from '@/View'
import Paragraph from '@/Paragraph'

// const saveScores = async (
//   user: User,
//   questions: Array<Question>,
//   allQuizzes: Array<Quiz>,
//   scores: Array<any>,
//   create: any,
//   update: any
// ) => {
//   const newScores = calculateWordScores(questions, allQuizzes)
//   console.log(JSON.stringify(newScores, null, 2))
//   const items = await Promise.all(
//     newScores.map(async (score: any) => {
//       const input = {
//         ...score,
//         user_id: user.id,
//       }
//       const existing = scores.find((x) => x.word_id === score.word_id)
//       // TODO: exclude already passed phase_1 etc?
//       if (existing) {
//         console.log('updateWordScore', existing.id, input)
//         await update({id: existing.id, input})
//       } else {
//         input.id = uuid4()
//         console.log('createWordScore', input)
//         await create({input})
//         // .then(x => console.log(x))
//       }
//       return input
//     })
//   )
// }

type Props = {
  listId: string | undefined
  practiceSessionId?: string
  answers: Array<any>
  time: string
  skip?: boolean
}
const SaveScores = ({
  listId,
  practiceSessionId,
  answers,
  time,
  skip = false,
}: Props) => {
  const {__} = useTranslations()
  const user = useSelector(selectUser) as any
  const [loading, setLoading] = useState(false)
  const {data, isLoading} = api.endpoints.quizzes.useQuery()
  const [showStageCompleteModal, setShowStageCompleteModal] =
    useState<boolean>(false)

  // const {data: wordScoreData, isLoading: wordScoreIsLoading} =
  // wordScoreApi.endpoints.wordScores.useQuery()
  const [createRecord] = api.endpoints.createQuiz.useMutation()
  // const [createWordScore, {reset: resetCreate}] =
  // wordScoreApi.endpoints.createWordScore.useMutation()
  // const [updateWordScore, {reset: resetUpdate}] =
  // wordScoreApi.endpoints.updateWordScore.useMutation()
  const navigate = useNavigate()
  const [btnRef, setBtnFocus] = useFocus()
  useEffect(() => {
    setBtnFocus()
  }, [btnRef])

  const save = () => {
    if (!data) return
    setLoading(true)
    const input = {
      // id: uuid4(),
      list_id: listId,
      practice_session_id: practiceSessionId || null,
      user_id: user.id,
      date: formatDate(null, TIMESTAMP_FORMAT),
      time_taken: time,
      questions: answers.map((answer) => {
        return pick(answer, [
          'id',
          'question_type_id',
          'accepted_answers',
          'word_id',
          'answer',
          'correct',
        ])
      }),
      skip,
    } as SaveQuizInput
    // console.log('saving', JSON.stringify(wordScoreData, null, 2))
    // saveScores(answers, data.quizzes.concat(input), wordScoreData.wordScores, createWordScore, updateWordScore)
    createRecord({input}).then(async (response) => {
      // Calculate word scores
      // await saveScores(
      //   user,
      //   answers,
      //   data.quizzes.concat([input as any]),
      //   wordScoreData.wordScores,
      //   createWordScore,
      //   updateWordScore
      // )
      // await resetUpdate()
      // await resetCreate()
      // console.log({newScores, wordScores})
      setLoading(false)
      if (skip) {
        setShowStageCompleteModal(true)
        // return
      } else {
        navigate(-1)
      }
    })
  }

  if (loading)
    return (
      <View className='flex w-full items-center justify-center'>
        <Spinner />
      </View>
    )
  return (
    <>
      <Button
        ref={btnRef}
        onClick={save}
        className='w-full'
        disabled={isLoading}
      >
        {__('Continue')}
      </Button>
      <StageCompleteModal
        visible={showStageCompleteModal}
        setVisible={setShowStageCompleteModal}
        title={__('Well done!')}
      >
        <Paragraph>
          {__(
            `You have skipped this stage, but you can go back and practice any time!`
          )}
        </Paragraph>
      </StageCompleteModal>
    </>
  )
}

export default SaveScores
