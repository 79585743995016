import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {api} from 'src/queries/level.enhanced'
import {selectLevel, setLevel} from 'src/reducers/user'
import {useTranslations} from 'src/utils/hooks'
import Select from '@/form/Select'

const LevelSelect = () => {
  const {__} = useTranslations()
  const dispatch = useDispatch()
  const levelId = useSelector(selectLevel)
  const {data, isLoading, error} = api.endpoints.levels.useQuery()
  if (isLoading || !data) return null
  const items = data.levels.map((item) => ({
    key: item.id,
    label: __('Level :level', {level: item.name || ''}),
  }))

  return (
    <Select
      items={items}
      value={levelId}
      onChange={(value) => dispatch(setLevel(value))}
    />
  )
}
export default LevelSelect
