export const sentenceCase = (s?: string): string =>
  (s && s[0].toUpperCase() + s.slice(1).toLowerCase()) || ''

export const labelize = (input?: string): string => {
  if (!input) return ''
  input = input.toLowerCase().replace(/[_-]+/g, ' ')
  return sentenceCase(input)
}

export const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0')
