import {ReactNode, createContext, useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {url} from 'src/config'
import {loadSound, playSound as playSoundFromBuffer} from './audioBuffer'
// import useSound from 'use-sound'

export enum Sound {
  Success,
  Error,
  Tap,
}

const correctAnswerSound = `/resources/sound/correct-answer.mp3`
// const correctAnswerSound = `${url}/sound/correct-short.wav`
const wrongAnswerSound = `/resources/sound/wrong-answer.mp3`
// const wrongAnswerSound = `${url}/sound/error.wav`
// const tapSound = `${url}/sound/press.mp3`
const tapSound = `/resources/sound/tap.mp3`

const initSound = (src: string, volume = 0.15) => {
  // console.log('init sound')
  const audio = new Audio(src)
  audio.volume = volume
  return audio
}

interface SoundsContextType {
  // success: any
  // error: any
  // tap: any
  playSound: any
  enableSounds: any
}
export const SoundsContext = createContext<SoundsContextType>(
  {} as SoundsContextType
)

type ProviderProps = {
  children: ReactNode
}
export const SoundsProvider = ({children}: ProviderProps) => {
  const [success, setSuccess] = useState<any | null>(null)
  const [error, setError] = useState<any | null>(null)
  const [tap, setTap] = useState<any | null>(null)

  useEffect(() => {
    loadSound(correctAnswerSound).then((source) => setSuccess(source))
    loadSound(wrongAnswerSound).then((source) => setError(source))
    loadSound(tapSound).then((source) => setTap(source))
  }, [])
  // const [success] = useSound(correctAnswerSound)
  // const [error] = useSound(wrongAnswerSound)
  // const [tap] = useSound(tapSound)

  // const [success] = useState(initSound(correctAnswerSound))
  // const [error] = useState(initSound(wrongAnswerSound))
  // const [tap] = useState(initSound(tapSound, 0.01))
  const {enableSounds} = useSelector(({preferences}) => preferences)

  const playSound = async (sound: Sound) => {
    // NOTE: remove cloneNode() and replace with AudioContext implementation
    // https://stackoverflow.com/questions/61453760/how-to-rapidly-play-multiple-copies-of-a-soundfile-in-javascript/61457101#61457101
    if (!enableSounds) return
    // success.start(0)
    // playSoundFromBuffer(tap)
    // return
    // switch (sound) {
    //   case Sound.Success:
    //     return (success.cloneNode() as any).play()
    //   case Sound.Error:
    //     return (error.cloneNode() as any).play()
    //   case Sound.Tap:
    //     return (tap.cloneNode() as any).play()
    // }
    switch (sound) {
      case Sound.Success:
        return playSoundFromBuffer(success)
      case Sound.Error:
        return playSoundFromBuffer(error)
      case Sound.Tap:
        return playSoundFromBuffer(tap)
    }
  }
  const state = {
    // success,
    // error,
    // tap,
    playSound,
    enableSounds,
  }
  return (
    <SoundsContext.Provider value={state}>{children}</SoundsContext.Provider>
  )
}

const useSounds = () => {
  // const {success, error, tap, playSound, enableSounds} =
  const {playSound, enableSounds} = useContext(SoundsContext)

  return {
    // success,
    // error,
    // tap,
    playSound,
    enableSounds,
  }
}
export default useSounds
