import React, {
  createContext,
  Dispatch,
  ReactNode,
  useContext,
  useState,
} from 'react'

import {translate, locales} from 'src/utils/lang'

interface TranslationContextType {
  locale: string
  setLocale?: Dispatch<string>
}
export const TranslationContext = createContext<TranslationContextType>({
  locale: 'en',
})

type ProviderProps = {
  locale?: string
  children: ReactNode
}
export const TranslationProvider = ({
  locale: defaultLocale,
  children,
}: ProviderProps) => {
  const [locale, setLocale] = useState(defaultLocale || 'en')
  const state = {
    locale,
    setLocale,
  }
  return (
    <TranslationContext.Provider value={state}>
      {children}
    </TranslationContext.Provider>
  )
}

const useTranslations = () => {
  const {locale, setLocale} = useContext(TranslationContext)
  return {
    locales,
    locale,
    setLocale,
    translate,
    __: translate(locale),
  }
}
export default useTranslations
