import {ReactNode, useState} from 'react'
import {List, Question as QuestionType} from 'src/queries/schema.generated'
import ShowWord from './ShowWord'
import Questions from './Questions'
import MatchWords from './MatchWords'

enum ScreenType {
  ShowWords,
  Questions,
}
type Props = {
  questions: QuestionType[]
  next: () => void
  listId: any
  practiceSessionId?: any
  list?: List | null
  skip: Boolean
  values: any
  children?: ReactNode
  setValue: (key: string, value: any) => void
  // setFinished: () => void
  addCorrect: (id: string | string[]) => void
  addIncorrect?: (id: string | string[]) => void
}
const QuestionChunk = ({
  questions,
  next,
  listId,
  practiceSessionId,
  list,
  skip,
  values,
  children,
  setValue,
  // setFinished,
  addCorrect,
  addIncorrect,
}: Props) => {
  const [questionIndex, setQuestionIndex] = useState<number>(0)
  const [screenType, setScreenType] = useState<ScreenType>(
    !skip && list?.stage === 1 ? ScreenType.ShowWords : ScreenType.Questions
  )

  const question = questions[questionIndex]
  const nextQuestion = () => {
    if (questionIndex < questions.length - 1) {
      setQuestionIndex(questionIndex + 1)
    } else if (screenType === ScreenType.ShowWords) {
      setQuestionIndex(0)
      setScreenType(ScreenType.Questions)
    } else {
      nextChunk()
    }
  }

  const nextChunk = () => {
    setScreenType(ScreenType.ShowWords)
    setQuestionIndex(0)
    next()
  }

  if (!skip && list?.stage === 2) {
    // TODO: get this from APi getQuestions?
    return (
      <MatchWords
        questions={questions}
        next={nextChunk}
        addCorrect={addCorrect}
      />
    )
  }

  if (screenType === ScreenType.ShowWords && question.word) {
    return (
      <ShowWord
        word={question.word}
        next={nextQuestion}
      />
    )
  }
  return (
    <Questions
      // questions={questions.map((x, i) => ({...x, index: i}))}
      questions={questions}
      listId={listId}
      values={values}
      setValue={setValue}
      setFinished={nextChunk}
      // setFinished={setFinished}
      addCorrect={addCorrect}
      addIncorrect={addIncorrect}
    >
      {children}
    </Questions>
  )
}

export default QuestionChunk
