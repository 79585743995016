import React, {useState} from 'react'

import {api} from 'src/queries/word-score.generated'

import ResetStatsModal from './ResetStatsModal'
import {Word} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import useToast from 'src/utils/hooks/useToast'

type Props = {
  word?: any
  list?: any
  refetch?: any
}
const ResetStatsButton = ({word, list, refetch}: Props) => {
  const {__} = useTranslations()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [resetWordScores] = api.endpoints.resetWordScores.useMutation()
  const type = word ? 'word' : 'list'
  const {add: addToast} = useToast()

  return (
    <>
      <ResetStatsModal
        type={type}
        visible={showModal}
        setVisible={setShowModal}
        submit={() => {
          const wordIds = word
            ? [word.id]
            : list.words.map((word: Word) => word.id)
          resetWordScores({wordIds, listId: list?.id || null})
            .unwrap()
            .then((response) => {
              console.log({response})
              setShowModal(false)
              if (refetch) refetch()
              addToast(__('The stats have been reset for this :type.', {type}))
            })
        }}
      />
      <Button
        className='m-2 btn-outline flex-1'
        variant='error'
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={icon({name: 'trash'})} />
        {__('Reset stats for this :type', {type})}
      </Button>
    </>
  )
}
export default ResetStatsButton
