import {useTranslations} from 'src/utils/hooks'
import {api} from 'src/queries/user.generated'
import {setToken, setUser} from 'src/reducers/user'
import ScrollView from '@/ScrollView'
import {useDispatch, useSelector} from 'react-redux'
import {selectUser} from 'src/reducers/user'
import {useForm} from 'src/utils/form'
import pick from 'lodash/pick'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {index} from 'src/routes/app'
import Spinner from '@/Spinner'
import Button from '@/Button'
import Errors from '@/Errors'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import FormContainer from '@/form/FormContainer'

export const initialValues = {
  email: '',
  password: '',
  confirm: '',
}

export const rules = {
  email: 'required|email',
  password: 'required|min:8',
  confirm: 'required',
}

const ResetPassword = () => {
  const {__} = useTranslations()
  const {token} = useParams()
  const [searchParams] = useSearchParams()
  // const {email} = use
  const navigate = useNavigate()
  const [reset, {isLoading}] = api.endpoints.resetPassword.useMutation()
  const user = useSelector(selectUser)
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues: {...initialValues, email: searchParams.get('email')},
    rules,
  })
  // const [apiErrors, setApiErrors] = useState([])
  const dispatch = useDispatch()

  const submit = () => {
    const passwordMatchError =
      values.new_password !== values.confirm
        ? __('The passwords need to match')
        : null
    if (validate(passwordMatchError ? {general: passwordMatchError} : {})) {
      const input = {
        email: values.email,
        password: values.password,
        token: token || '',
      }
      // console.log({input})
      reset(input).then(({data: {resetPassword: success}}: any) => {
        if (success) {
          navigate(index())
        } else {
          const errors = [
            __('There was a problem submitting this data. Please try again.'),
          ]
          setErrors({api: errors.join('\n')})
        }
      })
    }
  }

  if (isLoading) return <Spinner />

  const footer = (
    <Button
      className='m-2'
      onClick={submit}
    >
      {__('Save')}
    </Button>
  )

  return (
    <ScrollView
      footer={footer}
      title={__('Update account')}
    >
      <FormContainer>
        <Errors errors={errors} />

        <FormGroup label={__('Email')}>
          <Input
            type='email'
            value={values.email || ''}
            onChange={(value: any) => setValue('email', value)}
          />
        </FormGroup>
        <FormGroup label={__('New password')}>
          <Input
            type='password'
            value={values.password}
            onChange={(value: any) => setValue('password', value)}
          />
        </FormGroup>
        <FormGroup label={__('Confirm new password')}>
          <Input
            type='password'
            value={values.confirm}
            onChange={(value: any) => setValue('confirm', value)}
          />
        </FormGroup>
      </FormContainer>
    </ScrollView>
  )
}
export default ResetPassword
