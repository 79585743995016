import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type RevisionWordsQueryVariables = Types.Exact<{
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type RevisionWordsQuery = { __typename?: 'Query', revisionWords: Array<{ __typename?: 'RevisionWord', id: string, list_id?: string | null, word_id?: string | null, word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, score?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null } | null }> };

export type RevisionWordQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type RevisionWordQuery = { __typename?: 'Query', revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null, word_id?: string | null, word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, score?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null } | null } | null };

export type CreateRevisionWordMutationVariables = Types.Exact<{
  word_id: Types.Scalars['ID']['input'];
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type CreateRevisionWordMutation = { __typename?: 'Mutation', createRevisionWord?: { __typename?: 'RevisionWord', id: string } | null };

export type DeleteRevisionWordMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteRevisionWordMutation = { __typename?: 'Mutation', deleteRevisionWord?: { __typename?: 'RevisionWord', id: string } | null };


export const RevisionWordsDocument = `
    query revisionWords($list_id: ID) {
  revisionWords(list_id: $list_id) {
    id
    list_id
    word_id
    word {
      id
      word_type_id
      word
      article
      plural
      full_word
      translations {
        word
      }
      score {
        id
        user_id
        word_id
        correct
        incorrect
        phase_1
        phase_2
        phase_3
        phase_1_completed_at
        phase_2_completed_at
      }
      revisionWord {
        id
        list_id
      }
    }
  }
}
    `;
export const RevisionWordDocument = `
    query revisionWord($id: ID) {
  revisionWord(id: $id) {
    id
    list_id
    word_id
    word {
      id
      word_type_id
      word
      article
      plural
      full_word
      translations {
        word
      }
      score {
        id
        user_id
        word_id
        correct
        incorrect
        phase_1
        phase_2
        phase_3
        phase_1_completed_at
        phase_2_completed_at
      }
      revisionWord {
        id
        list_id
      }
    }
  }
}
    `;
export const CreateRevisionWordDocument = `
    mutation createRevisionWord($word_id: ID!, $list_id: ID) {
  createRevisionWord(word_id: $word_id, list_id: $list_id) {
    id
  }
}
    `;
export const DeleteRevisionWordDocument = `
    mutation deleteRevisionWord($id: ID!) {
  deleteRevisionWord(id: $id) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    revisionWords: build.query<RevisionWordsQuery, RevisionWordsQueryVariables | void>({
      query: (variables) => ({ document: RevisionWordsDocument, variables })
    }),
    revisionWord: build.query<RevisionWordQuery, RevisionWordQueryVariables | void>({
      query: (variables) => ({ document: RevisionWordDocument, variables })
    }),
    createRevisionWord: build.mutation<CreateRevisionWordMutation, CreateRevisionWordMutationVariables>({
      query: (variables) => ({ document: CreateRevisionWordDocument, variables })
    }),
    deleteRevisionWord: build.mutation<DeleteRevisionWordMutation, DeleteRevisionWordMutationVariables>({
      query: (variables) => ({ document: DeleteRevisionWordDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


