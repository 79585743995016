import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type PictureQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type PictureQuery = { __typename?: 'Query', picture?: { __typename?: 'Picture', id: string, slug?: string | null, title: string, url: string, link?: string | null, attribution?: string | null, description?: string | null } | null };

export type PicturesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PicturesQuery = { __typename?: 'Query', pictures: Array<{ __typename?: 'Picture', id: string, slug?: string | null, title: string, url: string }> };


export const PictureDocument = `
    query picture($id: ID) {
  picture(id: $id) {
    id
    slug
    title
    url
    link
    attribution
    description
  }
}
    `;
export const PicturesDocument = `
    query pictures {
  pictures(collection: true) {
    id
    slug
    title
    url
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    picture: build.query<PictureQuery, PictureQueryVariables | void>({
      query: (variables) => ({ document: PictureDocument, variables })
    }),
    pictures: build.query<PicturesQuery, PicturesQueryVariables | void>({
      query: (variables) => ({ document: PicturesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


