import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {api} from 'src/queries/user.generated'
import {setToken, setUser} from 'src/reducers/user'
import {createAccount, forgottenPassword} from 'src/routes/account'
import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'

import Button from '@/Button'
import Column from '@/Column'
import Errors from '@/Errors'
import FormGroup from '@/form/FormGroup'
import Input from '@/form/Input'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'
import Row from '@/Row'
import ScrollView from '@/ScrollView'
import Spinner from '@/Spinner'

export const initialValues = {
  email: '',
  password: '',
  // email: 'j_s_tierney@yahoo.co.uk',
  // password: 'password',
}

export const rules = {
  email: 'required|email',
  password: 'required|min:8',
}
const Login = () => {
  const {__} = useTranslations()
  const {values, setValue, validate, setErrors, errors} = useForm({
    initialValues,
    rules,
  })

  const [login, {isLoading}] = api.endpoints.login.useMutation()
  const dispatch = useDispatch()

  const submit = () => {
    if (validate()) {
      const {email, password} = values
      login({email, password})
        .unwrap()
        // .then(({ data: { login: response } }: any) => {
        .then((rawResponse: any) => {
          // console.log(JSON.stringify(rawResponse, null, 2))
          const {login: response} = rawResponse
          if (response.status !== 'success') {
            const errors =
              response.errors && response.errors.length > 0
                ? response.errors
                : [
                    __(
                      'There was a problem submitting this data. Please try again.'
                    ),
                  ]
            setErrors({api: errors.join('\n')})
          } else {
            console.log('success', JSON.stringify(response, null, 2))
            dispatch(setUser(response.user))
            dispatch(setToken(response.token.access_token))
            // navigation.navigate('Dashboard')
          }
        })
        .catch((response) => {
          console.log({response})
        })
    }
  }

  if (isLoading) return <Spinner />

  return (
    <ScrollView>
      <Column className='m-5 sm:mx-8'>
        <Heading size='h2'>{__('Sign in')}</Heading>

        <Errors errors={errors} />

        <Column className='my-6 gap-3'>
          <Paragraph>{__('Please sign in to continue')}.</Paragraph>
          <Row className='gap-2 justify-start items-center'>
            <Paragraph>{__(`Don't have an account?`)}</Paragraph>
            <Link
              to={createAccount()}
              className='btn-link text-lg'
            >
              {__('Sign up here')}!
            </Link>
          </Row>
        </Column>

        <FormGroup label={__('Email')}>
          <Input
            type='email'
            value={values.email}
            onChange={(value: any) => setValue('email', value)}
          />
        </FormGroup>
        <FormGroup label={__('Password')}>
          <Input
            type='password'
            value={values.password}
            onChange={(value: any) => setValue('password', value)}
          />
        </FormGroup>

        <Row className='mt-5 justify-between items-center'>
          <Button onClick={submit}>{__('Sign in')}</Button>
          <Link
            to={forgottenPassword()}
            className='btn-link text-lg'
          >
            {__('Forgotten password')}?
          </Link>
        </Row>
      </Column>
    </ScrollView>
  )
}
export default Login
