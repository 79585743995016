import classNames from 'classnames'
import {ViewProps} from './types'
import View from './View'

const Column = ({children, ...props}: ViewProps) => {
  const defaults = 'flex flex-col'
  const className = classNames(defaults, props.className)
  return (
    <View
      {...props}
      className={className}
    >
      {children}
    </View>
  )
}
export default Column
