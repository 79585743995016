import {useState} from 'react'

import {api} from 'src/queries/word.enhanced'
import {List, Word} from 'src/queries/schema.generated'
import {useFocus} from 'src/utils/useFocus'
import {useDispatch} from 'react-redux'
import {setValue} from 'src/reducers/search'
import SearchResultItem from './SearchResultItem'

type Props = {
  list: List
}
const Component = ({list}: Props) => {
  const [trigger, {data, isLoading, error}] =
    api.endpoints.searchWords.useLazyQuery()
  const dispatch = useDispatch()
  const [createWord] = api.endpoints.createWord.useMutation()
  const [wordRef, setWordFocus] = useFocus()
  const [translationRef, setTranslationFocus] = useFocus()
  const [word, setWord] = useState<string>('')
  const [translation, setTranslation] = useState<string>('')

  const submit = () => {
    if (!word || !translation) return
    const input = {
      language_id: '1',
      list_id: list.id,
      word: word,
      translations: [translation],
    }
    // console.log({input})
    setWord('')
    setTranslation('')
    createWord({input})
    setWordFocus()
  }

  const matches = (data?.searchWords || []).filter(
    (item) => (item?.full_word || '').toLowerCase() === word.toLowerCase()
  )

  return (
    <>
      <div className={`flex flex-row justify-between gap-2`}>
        <input
          ref={wordRef}
          className={`border px-3 py-1 flex-1 ${
            matches.length > 0 ? ' bg-red-200' : ''
          }`}
          type='text'
          placeholder='Add new word'
          value={word}
          onChange={(e) => {
            setWord(e.target.value)
            // dispatch(setValue('word', e.target.value))
            const word = e.target.value
              .replace(/^der /, '')
              .replace(/^die /, '')
              .replace(/^das /, '')
            trigger({word})
          }}
          onKeyUp={(e) => {
            // if (e.key === 'Enter') submit()
            if (e.key === 'Enter') if (word) setTranslationFocus()
          }}
        />
        <input
          ref={translationRef}
          className='border px-3 py-1 flex-1'
          type='text'
          placeholder='Translation'
          value={translation}
          onChange={(e) => setTranslation(e.target.value)}
          onKeyUp={(e) => {
            if (e.key === 'Enter') if (translation) submit()
          }}
        />
        {word && (
          <button
            className='bg-green-600 text-white px-2'
            onClick={submit}
          >
            Add
          </button>
        )}
      </div>
      {matches && (
        <div>
          {matches.map((word: any, i) => {
            return (
              <SearchResultItem
                key={i}
                word={word}
              />
            )
          })}
        </div>
      )}
    </>
  )
}
export default Component
