import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Translation} from 'src/queries/schema.generated'
import {useSpeech, useTranslations} from 'src/utils/hooks'
import RevisionListButton from '~/words/includes/RevisionListButton'
import {ViewProps} from './types'
import classNames from 'classnames'

interface Props extends ViewProps {
  title?: string
  borderColour: string
  textColour: string
}
const AnswerCard = ({
  title,
  borderColour,
  textColour,
  children,
  ...props
}: Props) => {
  const {__} = useTranslations()
  const {playWord} = useSpeech()

  return (
    <div
      className={classNames(
        'card border-4 bg-gradient-to-b from-white to-yellow-50',
        borderColour
      )}
    >
      <div className='card-body p-3'>
        {title && (
          <h4
            className={classNames(
              'card-title text-center m-2 self-center text-lg',
              textColour
            )}
          >
            <FontAwesomeIcon icon={icon({name: 'info-circle'})} />
            {title}
          </h4>
        )}
        {children}
      </div>
    </div>
  )
}

export default AnswerCard
