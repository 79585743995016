import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type QuestionTypesQueryVariables = Types.Exact<{
  language_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type QuestionTypesQuery = { __typename?: 'Query', questionTypes: Array<{ __typename?: 'QuestionType', id: string, slug: string, name: string, description?: string | null, language_id?: string | null, word_type_id?: string | null, apply_to_words?: boolean | null, templates?: Array<{ __typename?: 'QuestionTypeTemplate', id: string, question_type_id: string, answer_type_id: string, question: string, accepted_answers?: string | null, options?: Array<{ __typename?: 'QuestionTypeTemplateOption', id: string, answer?: string | null } | null> | null } | null> | null }> };


export const QuestionTypesDocument = `
    query questionTypes($language_id: ID) {
  questionTypes(language_id: $language_id) {
    id
    slug
    name
    description
    language_id
    word_type_id
    apply_to_words
    templates {
      id
      question_type_id
      answer_type_id
      question
      accepted_answers
      options {
        id
        answer
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    questionTypes: build.query<QuestionTypesQuery, QuestionTypesQueryVariables | void>({
      query: (variables) => ({ document: QuestionTypesDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


