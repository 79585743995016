import {useTranslations} from 'src/utils/hooks'
import AnswerCard from '@/AnswerCard'
import AnswerRow from './AnswerRow'

type Props = {
  items: any
  listId?: string
}
const IncorrectAnswers = ({items, listId}: Props) => {
  const {__} = useTranslations()

  return (
    <AnswerCard
      textColour='text-amber-600'
      borderColour='border-amber-500'
      title={__('Incorrect answers to review')}
    >
      {items.map((question: any, i: number) => {
        return (
          <AnswerRow
            correct={false}
            question={question}
            listId={listId}
            key={i}
          />
        )
      })}
    </AnswerCard>
  )
}

export default IncorrectAnswers
