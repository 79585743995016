import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import PageTitle from '@/PageTitle'
import Spinner from '@/Spinner'
import {useSelector} from 'react-redux'
import {api as practiceApi} from 'src/queries/practice-session.enhanced'
import {api} from 'src/queries/question.enhanced'
import {List, QuizQuestion} from 'src/queries/schema.generated'
import {selectEnableSpeech, selectQuestiontypes} from 'src/reducers/preferences'
import {useForm} from 'src/utils/form'
import {useTranslations} from 'src/utils/hooks'
import {useRedirectIfNotLoggedIn} from 'src/utils/hooks/useRedirectIfNotLoggedIn'
import {getEvenChunks} from './helpers'
import NoQuestions from './includes/NoQuestions'
import QuestionChunks from './includes/QuestionChunks'
import Results from './Results'

const CreatePracticeQuiz = () => {
  useRedirectIfNotLoggedIn()
  const {__} = useTranslations()
  const {values, setValue} = useForm()
  const [timeStarted, setTimeStarted] = useState<number>(0)
  const [timeFinished, setTimeFinished] = useState<number>(0)
  const [showResults, setShowResults] = useState(false)
  const [correctQuestions, setCorrectQuestions] = useState<Array<string>>([])
  const [trigger, {data, isLoading}] = api.endpoints.nextQuestions.useMutation()
  const enableSpeech = useSelector(selectEnableSpeech)
  const questionTypes = useSelector(selectQuestiontypes) as string[]

  useEffect(() => {
    const types = questionTypes || []
    trigger({
      fromRevisionList: true,
      questionTypes: enableSpeech ? types : ['!listen'],
    })
  }, [])

  useEffect(() => {
    setTimeStarted(Date.now())
  }, [])

  const setFinished = () => {
    setTimeFinished(Date.now())
    setShowResults(true)
  }

  const addCorrect = (id: string | string[]) => {
    setCorrectQuestions(correctQuestions.concat(Array.isArray(id) ? id : [id]))
  }

  if (isLoading || !data) return <Spinner />

  const title = __('Quiz')

  const chunks = [data?.nextQuestions]

  // console.log(data?.nextQuestions?.[0]?.word?.word)

  if (data?.nextQuestions && data.nextQuestions.length === 0) {
    return <NoQuestions />
  }
  if (showResults) {
    return (
      <Results
        questions={data?.nextQuestions as QuizQuestion[]}
        values={values}
        correctQuestions={correctQuestions}
        time={timeFinished - timeStarted}
      />
    )
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <QuestionChunks
        chunks={chunks}
        values={values}
        setValue={setValue}
        setFinished={setFinished}
        addCorrect={addCorrect}
      />
    </>
  )
}

export default CreatePracticeQuiz
