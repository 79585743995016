import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React, {useState} from 'react'
// import { Box, Pressable, Row, Spinner, Text, View, useToast } from 'native-base'
// import * as Speech from 'expo-speech'

import {api as revisionApi} from 'src/queries/revision-word.enhanced'
import {useTranslations} from 'src/utils/hooks'
import Icon from '@/Icon'
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro'
import Toast from '@/Toast'
import useToast from 'src/utils/hooks/useToast'
import Toasts from '@/Toasts'
import Pressable from '@/Pressable'
import Span from '@/Span'

// import Icon from 'components/Icon'
// import { useAppNavigation } from 'navigators/hooks'
// import { useTranslations } from 'utils'

type Props = {
  word: any
  listId?: string
  addToRevisionList?: Function
  removeFromRevisionList?: Function
  isRevisionList?: boolean
  defaultColour?: string
}
const RevisionListButton = ({
  word,
  listId,
  isRevisionList,
  ...props
}: Props) => {
  const {__} = useTranslations()
  const [add] = revisionApi.endpoints.createRevisionWord.useMutation()
  const [remove] = revisionApi.endpoints.deleteRevisionWord.useMutation()
  const [inList, setInList] = useState<boolean>(!!word?.revisionWord) // Set optimistic updates - NOTE: revisionWord must be provided in the query!
  const {add: addToast} = useToast()
  // const inList = !!revisionWord
  // const toast = useToast()
  const revisionWord = word?.revisionWord || false
  const revisionWordIconColour = inList
    ? 'text-purple-500'
    : props.defaultColour || 'text-gray-300' // change it to the new one straight away

  const addToRevisionList =
    props.addToRevisionList ||
    (async (word_id: string) => {
      await add({word_id, list_id: listId || null})
    })
  const removeFromRevisionList =
    props.removeFromRevisionList ||
    (async (id: string) => {
      await remove({id})
    })

  return (
    <>
      {isRevisionList ? (
        <Pressable
          className='text-error'
          onClick={async () => {
            if (!revisionWord?.id) return
            await removeFromRevisionList(revisionWord.id)
          }}
        >
          <FontAwesomeIcon icon={icon({name: 'minus-circle'})} />
        </Pressable>
      ) : (
        <Pressable
          // p={3} m={-3}
          onClick={async () => {
            if (inList) {
              if (!revisionWord?.id) return
              setInList(false)
              addToast(
                __(':word has been removed from your revision list.', {
                  word: word.full_word,
                })
              )
              await removeFromRevisionList(revisionWord.id)
            } else {
              setInList(true)
              addToast(
                __(':word has been added to your revision list.', {
                  word: word.full_word,
                })
              )
              await addToRevisionList(word.id)
            }
          }}
        >
          <Span className={revisionWordIconColour}>
            <Icon
              name='fa/list'
              size={16}
            />
          </Span>
        </Pressable>
      )}
    </>
  )
}

export default RevisionListButton
