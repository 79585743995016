// import Icon from 'components/Icon'
// import { useAppNavigation } from 'navigators/hooks'
import {useNavigate} from 'react-router-dom'
import {List, PracticeSession} from 'src/queries/schema.generated'
import {practiceSessionsShow} from 'src/routes/practice-sessions'
import Icon from '@/Icon'
import {getLinkColour} from '../helpers'
import Pressable from '@/Pressable'

type Props = {
  record: PracticeSession | null
  variation: 'l' | 'r'
  isLast: boolean
}
const Practice = ({record, variation, isLast}: Props) => {
  const navigate = useNavigate()
  if (isLast || !record) return null
  const isDisabled = record.statusName === 'Locked'
  // const colour = isDisabled
  //   ? 'text-slate-400 border-slate-400 bg-slate-100'
  //   : 'text-primary border-primary bg-purple-200'
  const colour = getLinkColour(record.statusName || '')
  const cursor = isDisabled ? 'cursor-not-allowed' : ''

  const canAccess = !isDisabled
  return (
    <>
      <Pressable
        onClick={() => {
          if (canAccess) navigate(practiceSessionsShow(record.id))
        }}
        className={`z-10 mt-4 border-2 rounded-full h-16 w-16 inline-flex items-center justify-center ${colour} ${cursor}`}
      >
        <Icon
          // name='fa/dumbbell'
          name={record?.icon || 'fa/dumbbell'}
          faSize='lg'
          size={30}
        />
      </Pressable>
    </>
  )
}

export default Practice
