import {useState} from 'react'
import {Word} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import WordPanel from '~/words/includes/WordPanel'
import Column from '@/Column'

type Props = {
  word: Word
  next: () => void
}
const ShowWord = ({word, next}: Props) => {
  const {__} = useTranslations()
  const footer = (
    <Button
      className='m-2'
      onClick={next}
    >
      {__('Next')}
    </Button>
  )
  return (
    <ScrollView footer={footer}>
      <Column className='h-full justify-center'>
        <WordPanel word={word} />
      </Column>
    </ScrollView>
  )
}

export default ShowWord
