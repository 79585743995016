import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type ListsQueryVariables = Types.Exact<{
  level_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  status?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type ListsQuery = { __typename?: 'Query', lists: Array<{ __typename?: 'List', id: string, name: string, icon?: string | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null, version?: number | null, sequence?: number | null, level?: { __typename?: 'Level', id: string, name?: string | null, sequence?: number | null } | null, words?: Array<{ __typename?: 'Word', id: string, word: string, full_word: string, translations?: Array<{ __typename?: 'Translation', id: string, word: string, word_id: string } | null> | null } | null> | null, picture?: { __typename?: 'Picture', id: string, title: string, url: string } | null, practiceSessions?: Array<{ __typename?: 'PracticeSession', id: string, name?: string | null, icon?: string | null, sequence?: number | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null, picture?: { __typename?: 'Picture', id: string, title: string, url: string } | null } | null> | null }> };

export type ListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ListQuery = { __typename?: 'Query', list?: { __typename?: 'List', id: string, name: string, icon?: string | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null, stage?: number | null, percent_complete: number, pieces_revealed: Array<number | null>, picture?: { __typename?: 'Picture', id: string, slug?: string | null, title: string, url: string, link?: string | null, attribution?: string | null, description?: string | null } | null, practiceSessions?: Array<{ __typename?: 'PracticeSession', id: string, name?: string | null, icon?: string | null, sequence?: number | null, statusName?: string | null, intro?: string | null, question_types?: Array<string | null> | null } | null> | null, words?: Array<{ __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, score?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null } | null> | null } | null };

export type CreateListMutationVariables = Types.Exact<{
  input: Types.SaveListInput;
}>;


export type CreateListMutation = { __typename?: 'Mutation', createList?: { __typename?: 'List', id: string, name: string, icon?: string | null, sequence?: number | null } | null };

export type UpdateListMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SaveListInput;
}>;


export type UpdateListMutation = { __typename?: 'Mutation', updateList?: { __typename?: 'List', id: string, name: string, icon?: string | null, sequence?: number | null } | null };

export type DeleteListMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteListMutation = { __typename?: 'Mutation', deleteList?: { __typename?: 'List', id: string } | null };

export type SortListsMutationVariables = Types.Exact<{
  ids: Array<Types.InputMaybe<Types.Scalars['ID']['input']>> | Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type SortListsMutation = { __typename?: 'Mutation', sortLists?: boolean | null };

export type SortWordsAlphabeticMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type SortWordsAlphabeticMutation = { __typename?: 'Mutation', sortWordsAlphabetic?: boolean | null };

export type SaveListProgressMutationVariables = Types.Exact<{
  input: Types.SaveListProgressInput;
}>;


export type SaveListProgressMutation = { __typename?: 'Mutation', saveListProgress?: { __typename?: 'ListProgress', id?: string | null, progress_status_id?: string | null, stage?: number | null, percent_complete: number, pieces_revealed: Array<number | null> } | null };


export const ListsDocument = `
    query lists($level_id: ID, $status: String) {
  lists(level_id: $level_id, status: $status) {
    id
    name
    icon
    statusName
    intro
    question_types
    version
    sequence
    level {
      id
      name
      sequence
    }
    words {
      id
      word
      full_word
      translations {
        id
        word
        word_id
      }
    }
    picture {
      id
      title
      url
    }
    practiceSessions {
      id
      name
      icon
      sequence
      statusName
      intro
      question_types
      picture {
        id
        title
        url
      }
    }
  }
}
    `;
export const ListDocument = `
    query list($id: ID) {
  list(id: $id) {
    id
    name
    icon
    statusName
    intro
    question_types
    stage
    percent_complete
    pieces_revealed
    picture {
      id
      slug
      title
      url
      link
      attribution
      description
    }
    practiceSessions {
      id
      name
      icon
      sequence
      statusName
      intro
      question_types
    }
    words {
      id
      word_type_id
      word
      article
      plural
      full_word
      translations {
        word
      }
      score {
        id
        user_id
        word_id
        correct
        incorrect
        phase_1
        phase_2
        phase_3
        phase_1_completed_at
        phase_2_completed_at
      }
      revisionWord {
        id
        list_id
      }
    }
  }
}
    `;
export const CreateListDocument = `
    mutation createList($input: SaveListInput!) {
  createList(input: $input) {
    id
    name
    icon
    sequence
  }
}
    `;
export const UpdateListDocument = `
    mutation updateList($id: ID!, $input: SaveListInput!) {
  updateList(id: $id, input: $input) {
    id
    name
    icon
    sequence
  }
}
    `;
export const DeleteListDocument = `
    mutation deleteList($id: ID!) {
  deleteList(id: $id) {
    id
  }
}
    `;
export const SortListsDocument = `
    mutation sortLists($ids: [ID]!) {
  sortLists(ids: $ids)
}
    `;
export const SortWordsAlphabeticDocument = `
    mutation sortWordsAlphabetic($id: ID!) {
  sortWordsAlphabetic(id: $id)
}
    `;
export const SaveListProgressDocument = `
    mutation saveListProgress($input: SaveListProgressInput!) {
  saveListProgress(input: $input) {
    id
    progress_status_id
    stage
    percent_complete
    pieces_revealed
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    lists: build.query<ListsQuery, ListsQueryVariables | void>({
      query: (variables) => ({ document: ListsDocument, variables })
    }),
    list: build.query<ListQuery, ListQueryVariables | void>({
      query: (variables) => ({ document: ListDocument, variables })
    }),
    createList: build.mutation<CreateListMutation, CreateListMutationVariables>({
      query: (variables) => ({ document: CreateListDocument, variables })
    }),
    updateList: build.mutation<UpdateListMutation, UpdateListMutationVariables>({
      query: (variables) => ({ document: UpdateListDocument, variables })
    }),
    deleteList: build.mutation<DeleteListMutation, DeleteListMutationVariables>({
      query: (variables) => ({ document: DeleteListDocument, variables })
    }),
    sortLists: build.mutation<SortListsMutation, SortListsMutationVariables>({
      query: (variables) => ({ document: SortListsDocument, variables })
    }),
    sortWordsAlphabetic: build.mutation<SortWordsAlphabeticMutation, SortWordsAlphabeticMutationVariables>({
      query: (variables) => ({ document: SortWordsAlphabeticDocument, variables })
    }),
    saveListProgress: build.mutation<SaveListProgressMutation, SaveListProgressMutationVariables>({
      query: (variables) => ({ document: SaveListProgressDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


