import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type NextQuestionsMutationVariables = Types.Exact<{
  listId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  practiceSessionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  fromRevisionList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  questionTypes?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['String']['input']>> | Types.InputMaybe<Types.Scalars['String']['input']>>;
  wordIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']['input']>> | Types.InputMaybe<Types.Scalars['ID']['input']>>;
  skip?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type NextQuestionsMutation = { __typename?: 'Mutation', nextQuestions: Array<{ __typename?: 'Question', id: string, question_type_id: string, question_type_template_id?: string | null, word_id?: string | null, question?: string | null, answer_type_id?: string | null, accepted_answers?: string | null, questionType?: { __typename?: 'QuestionType', id: string, slug: string, name: string, description?: string | null } | null, questionTypeTemplate?: { __typename?: 'QuestionTypeTemplate', id: string, question_type_id: string, answer_type_id: string, question: string, accepted_answers?: string | null, options?: Array<{ __typename?: 'QuestionTypeTemplateOption', id: string, answer?: string | null, sequence?: number | null } | null> | null } | null, word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null } | null }> };


export const NextQuestionsDocument = `
    mutation nextQuestions($listId: ID, $practiceSessionId: ID, $fromRevisionList: Boolean, $questionTypes: [String], $wordIds: [ID], $skip: Boolean) {
  nextQuestions(
    list_id: $listId
    practice_session_id: $practiceSessionId
    from_revision_list: $fromRevisionList
    question_types: $questionTypes
    word_ids: $wordIds
    skip: $skip
  ) {
    id
    question_type_id
    question_type_template_id
    word_id
    question
    answer_type_id
    accepted_answers
    questionType {
      id
      slug
      name
      description
    }
    questionTypeTemplate {
      id
      question_type_id
      answer_type_id
      question
      accepted_answers
      options {
        id
        answer
        sequence
      }
    }
    word {
      id
      word_type_id
      word
      article
      plural
      full_word
      translations {
        word
      }
      revisionWord {
        id
        list_id
      }
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    nextQuestions: build.mutation<NextQuestionsMutation, NextQuestionsMutationVariables | void>({
      query: (variables) => ({ document: NextQuestionsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


