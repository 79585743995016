import React, {ReactNode} from 'react'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Modal from '@/Modal'
import Paragraph from '@/Paragraph'

type Props = {
  visible: boolean
  setVisible: Function
  submit: () => void
}
const SkipFailedModal = ({visible, setVisible, submit}: Props) => {
  const {__} = useTranslations()
  const actions = [
    <Button
      key={0}
      onClick={submit}
    >
      {__('OK')}
    </Button>,
  ]
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      actions={actions}
      title={__('Bad luck!')}
    >
      <Paragraph>
        {__('Unfortunately, you have failed to skip this level.')}
      </Paragraph>
      <Paragraph>
        {__('You can try again, or do some more practice.')}
      </Paragraph>
    </Modal>
  )
}

export default SkipFailedModal
