import {useEffect, useState} from 'react'
import {arrayMoveImmutable} from 'array-move'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'

import {api} from 'src/queries/list.enhanced'
import {api as wordApi} from 'src/queries/word.enhanced'
import {cancelDefault} from 'src/utils'
import {Word} from 'src/queries/schema.generated'

import WordComponent from '../../words/includes/Word'
import EditList from './EditList'
import NewWord from '~/admin/words/includes/NewWord'
import Edit from './Edit'
import Icon from '@/Icon'

type Props = {
  item: any
  index: number
  sortList: (oldIndex: number, newIndex: number) => void
}
const List = ({item, index, sortList}: Props) => {
  const [showWords, setShowWords] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [editing, setEditing] = useState(false)
  const [trigger, {data, isLoading, error}] = api.endpoints.list.useLazyQuery()
  const [sortWords] = wordApi.endpoints.sortWords.useMutation()
  const [sortWordsAlphabetic] = api.endpoints.sortWordsAlphabetic.useMutation()
  const [moveWord] = wordApi.endpoints.moveWord.useMutation()
  const [deleteList] = api.endpoints.deleteList.useMutation()
  const [wordIds, setWordIds] = useState<Array<string>>(
    (data?.list?.words || []).map((word: any) => word.id)
  )

  useEffect(() => {
    setWordIds((data?.list?.words || []).map((word: any) => word.id))
  }, [data?.list?.words])

  const sortWord = (fromIndex: number, toIndex: number) => {
    const ids = arrayMoveImmutable(wordIds, fromIndex, toIndex)
    console.log({ids})
    sortWords({listId: item.id, ids})
    setWordIds(ids)
    // console.log(listIds, ids)
  }

  const handleDragStart = (e: React.DragEvent, index: number) => {
    setIsDragging(true)
    e.dataTransfer.effectAllowed = 'move'
    e.dataTransfer.setData('index', index + '')
    e.dataTransfer.setData('entityId', item.id)
    e.dataTransfer.setData('entityType', 'list')
  }
  const handleDragOver = (e: React.DragEvent, index: number) => {
    cancelDefault(e)
  }

  const handleDrop = (e: React.DragEvent, index: number) => {
    e.stopPropagation()
    const type = e.dataTransfer.getData('entityType')
    const id = e.dataTransfer.getData('entityId')
    const oldIndex = e.dataTransfer.getData('index')
    console.log({type, oldIndex, id})
    switch (type) {
      case 'list':
        sortList(parseInt(oldIndex), index)
        return
      case 'word':
        console.log('move word to list', id, item.id)
        moveWord({wordId: id, listId: item.id})
        return
    }
    // console.log(`Move item ${oldIndex} to position ${index}`)
  }

  const toggleWords = () => {
    if (!showWords) trigger({id: item.id})
    setShowWords(!showWords)
  }

  const remove = () => {
    deleteList({id: item.id})
  }

  const exportList = () => {
    console.log('export', item.id)
  }

  const words = data?.list?.words
  const itemHeight = 38 // TODO
  const itemsHeight = (words?.length || 0) * itemHeight // TODO

  return (
    <li
      draggable
      onDragStart={(e) => handleDragStart(e, index)}
      onDragEnd={(e) => setIsDragging(false)}
      onDragEnter={(e) => cancelDefault(e)}
      onDragOver={(e) => handleDragOver(e, index)}
      onDrop={(e) => handleDrop(e, index)}
    >
      <div
        className={`flex justify-between border p-2 ${
          isDragging ? 'opacity-25' : ''
        }`}
      >
        {editing ? (
          <EditList
            list={item}
            close={() => setEditing(false)}
          />
        ) : (
          <h4
            className='text-md font-semibold flex-1'
            onClick={() => setEditing(true)}
          >
            <div className='float-left mr-3'>
              <Icon
                name={item.icon}
                size={20}
                faSize='lg'
              />
            </div>
            {/* {item.sequence} */} {item.name}
            {words && <span> ({words?.length})</span>}
          </h4>
        )}

        <div className='flex gap-2'>
          <button
            className='text-sm font-normal px-2 bg-blue-600 text-white'
            onClick={() => setShowEdit(!showEdit)}
          >
            Edit
          </button>
          {words && words.length === 0 && (
            <button
              className='text-sm font-normal border px-2 bg-red-600 text-white'
              onClick={remove}
            >
              Del
            </button>
          )}
          {showWords && (
            <>
              {/* <button
                className='text-sm font-normal border px-2'
                onClick={() => sortWordsAlphabetic({id: item.id})}
              >
                Sort
              </button>
              */}
              <a
                className='text-sm font-normal border px-2 inline-block flex items-center justify-center'
                href={`http://lists.test/lists/${item.id}/export`}
                target='_blank'
              >
                Export
              </a>
            </>
          )}
          <button
            className='text-sm font-normal border px-2'
            onClick={() => toggleWords()}
          >
            {showWords ? 'Hide' : 'Show'}
          </button>
        </div>
      </div>
      {showEdit ? (
        <div>
          <Edit
            list={data?.list || item}
            close={() => setShowEdit(false)}
          />
        </div>
      ) : (
        showWords &&
        words && (
          <>
            <ul
              className='bg-slate-100'
              style={{minHeight: itemsHeight + itemHeight * 2}}
            >
              {words.map((word: any, i: number) => {
                return (
                  <WordComponent
                    key={i}
                    index={i}
                    word={word}
                    sortWord={sortWord}
                  />
                )
              })}
            </ul>
            <NewWord list={item} />
          </>
        )
      )}
    </li>
  )
}

export default List
