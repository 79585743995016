import Pressable from '@/Pressable'
import Row from '@/Row'
import Span from '@/Span'
import View from '@/View'
import {useState} from 'react'
import {Picture} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'

type Props = {
  picture: Picture
}
const Attribution = ({picture}: Props) => {
  const {__} = useTranslations()
  const [showAttribution, setShowAttribution] = useState(false)

  if (!picture.attribution) return null

  return (
    <View className='my-1 mx-3 text-xs'>
      {showAttribution ? (
        <Row className='justify-between items-start'>
          <Span className='italic'>{picture.attribution}</Span>
          <Pressable
            className='text-primary'
            onClick={() => setShowAttribution(false)}
          >
            [{__('hide')}]
          </Pressable>
        </Row>
      ) : (
        <View className='text-right'>
          <Pressable
            className='text-primary'
            onClick={() => setShowAttribution(true)}
          >
            {__('Show attribution')}
          </Pressable>
        </View>
      )}
    </View>
  )
}

export default Attribution
