import {useDispatch} from 'react-redux'

import {api} from 'src/queries/level.enhanced'

import LevelComponent from './includes/Level'
import {Level} from 'src/queries/schema.generated'
import Search from '~/admin/words/includes/Search'
import SearchResults from '~/admin/words/includes/SearchResults'

const getLists = (level: Level, version: number) => {
  return (level?.lists || []).filter((list: any) => list.version === version)
}

const Levels = () => {
  const dispatch = useDispatch()
  const {data} = api.endpoints.levels.useQuery()

  if (!data?.levels) return <div>loading</div>

  return (
    <div className='m-3 h-full'>
      <div className='flex justify-between my-2'>
        <h1 className='text-3xl font-bold'>
          Lists{' '}
          <button
            className='text-sm font-normal px-2 text-white bg-red-600 grow-0'
            onClick={() => dispatch(api.util.resetApiState())}
          >
            Reset
          </button>
        </h1>
        <Search />
      </div>
      <SearchResults />
      <div className='flex flex-row gap-2 h-full'>
        <div className='h-full flex-1 overflow-scroll'>
          {data.levels.map((level: any, i) => (
            <LevelComponent
              key={i}
              level={level}
              version={1}
              lists={getLists(level, 1)}
            />
          ))}
        </div>
        <div className='h-100 flex-1 overflow-scroll'>
          {data.levels.map((level: any, i) => (
            <LevelComponent
              key={i}
              level={level}
              version={2}
              lists={getLists(level, 2)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
export default Levels
