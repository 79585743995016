import {useTranslations} from 'src/utils/hooks'

import IncorrectAnswers from './includes/IncorrectAnswers'
import CorrectAnswers from './includes/CorrectAnswers'
import SaveScores from './includes/SaveScores'
import {getTimeFromMilliseconds} from 'src/utils/dateHelpers'
import ResultStats from './includes/ResultStats'
import ScrollView from '@/ScrollView'
import Row from '@/Row'
import Column from '@/Column'
import Heading from '@/Heading'

type Props = {
  listId?: string | undefined
  practiceSessionId?: string
  questions: Array<any>
  values: any
  correctQuestions: Array<string>
  time: number
  skip?: boolean
}
const Results = ({
  practiceSessionId,
  listId,
  questions,
  values,
  correctQuestions,
  skip = false,
  ...props
}: Props) => {
  const {__} = useTranslations()

  const answers = questions.map((question) => ({
    ...question,
    answer: values[question.id],
    correct: correctQuestions.includes(question.id),
  }))
  const incorrectAnswers = answers.filter((x) => !x.correct)
  const correctAnswers = answers.filter((x) => x.correct)
  const time = getTimeFromMilliseconds(props.time)
  // console.log({answers})

  const footer = (
    <Row className='m-2'>
      <SaveScores
        listId={listId}
        practiceSessionId={practiceSessionId}
        answers={answers}
        time={time}
        skip={skip}
      />
    </Row>
  )
  return (
    <ScrollView footer={footer}>
      <Column className='p-3'>
        <Heading size='h2'>{__('Your results')}</Heading>
        <Column className='gap-4'>
          <ResultStats
            time={time}
            questions={questions}
            correctQuestions={correctQuestions}
          />
          {incorrectAnswers.length > 0 ? (
            <IncorrectAnswers
              listId={listId}
              items={incorrectAnswers}
            />
          ) : null}
          {correctAnswers.length > 0 ? (
            <CorrectAnswers
              listId={listId}
              items={correctAnswers}
            />
          ) : null}
        </Column>
      </Column>
    </ScrollView>
  )
}

export default Results
