import React, {ReactNode} from 'react'
import {useTranslations} from 'src/utils/hooks'
import Button from '@/Button'
import Modal from '@/Modal'
import Paragraph from '@/Paragraph'

type Props = {
  visible: boolean
  setVisible: Function
  submit: () => void
  type: 'word' | 'list'
}
const ResetStatsModal = ({visible, setVisible, submit, type}: Props) => {
  const {__} = useTranslations()
  const actions = [
    <Button
      key={0}
      className='btn-ghost'
      onClick={() => setVisible(null)}
    >
      {__('Cancel')}
    </Button>,
    <Button
      key={1}
      onClick={submit}
    >
      {__('OK')}
    </Button>,
  ]
  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      actions={actions}
      title={__('Reset all stats for this :type?', {type})}
    >
      <Paragraph>
        {__(
          'Are you sure you want to reset all your progress with this :type?',
          {type}
        )}
      </Paragraph>
      <Paragraph>{__('This cannot be undone')}.</Paragraph>
    </Modal>
  )
}

export default ResetStatsModal
