import classNames from 'classnames'
import {ButtonHTMLAttributes} from 'react'

const Pressable = ({
  children,
  ...props
}: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const defaults = ''
  const className = classNames(defaults, props.className)
  return (
    <button
      {...props}
      className={className}
    >
      {children}
    </button>
  )
}
export default Pressable
