import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {selectUser} from 'src/reducers/user'
import {index} from 'src/routes/app'

export const useRedirectIfNotLoggedIn = () => {
  const navigate = useNavigate()
  const user = useSelector(selectUser)
  useEffect(() => {
    if (!user) navigate(index())
  })
  return user
}
