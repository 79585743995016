import {useNavigate} from 'react-router-dom'
import {QuizQuestion} from 'src/queries/schema.generated'
import {useTranslations} from 'src/utils/hooks'
import {numberToWord} from 'src/utils/numberHelpers'
import Button from '@/Button'
import ScrollView from '@/ScrollView'
import SkipLives from './SkipLives'
import Row from '@/Row'
import Heading from '@/Heading'
import Column from '@/Column'
import View from '@/View'

type Props = {
  title: string
  intro: string
  next: () => void
}
const Intro = ({title, intro, next}: Props) => {
  const {__} = useTranslations()
  const navigate = useNavigate()
  const footer = (
    <Row>
      <Button
        key={0}
        className='m-2 flex-grow'
        variant='outline'
        onClick={() => navigate(-1)}
      >
        {__('Back')}
      </Button>
      <Button
        key={1}
        className='m-2 flex-grow'
        onClick={next}
      >
        {__('Next')}
      </Button>
    </Row>
  )
  return (
    <ScrollView footer={footer}>
      <Column className='p-5 h-full gap-8'>
        <Heading size='h2'>{title}</Heading>
        <Column className='gap-5 text-xl text-center'>
          <View
            className='px-5'
            dangerouslySetInnerHTML={{__html: intro}}
          />
        </Column>
      </Column>
    </ScrollView>
  )
}
export default Intro
