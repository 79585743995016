import classNames from 'classnames'
import {ViewProps} from './types'

const sizeClasses = [
  'text-sm',
  'text-md',
  'text-lg',
  'text-xl',
  'text-2xl',
  'text-3xl',
]
const marginPrefixes = ['m', 'mt', 'mb']

const isSizeClass = (className: string) => sizeClasses.includes(className)
const isMarginClass = (className: string) => {
  return marginPrefixes.some((prefix) => {
    const regex = `^${prefix}-[0-9]+$`
    return RegExp(regex).test(className)
  })
}
const getDefaults = (customClasses: string = '') => {
  const classes = customClasses.split(' ')
  const hasSize = classes.some(isSizeClass)
  const hasMargin = classes.some(isMarginClass)
  // console.log({customClasses, hasSize, hasMargin})
  return `${hasSize ? '' : 'text-lg'} ${hasMargin ? '' : 'mt-2 mb-1'}`
}
const Paragraph = ({children, ...props}: ViewProps) => {
  const defaults = getDefaults(props.className)
  const className = classNames(defaults, props.className)
  return (
    <p
      {...props}
      className={className}
    >
      {children}
    </p>
  )
}
export default Paragraph
