import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type LoginMutationVariables = Types.Exact<{
  email?: Types.InputMaybe<Types.Scalars['String']['input']>;
  password?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'SaveUserResponse', status?: string | null, errors?: Array<string | null> | null, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, languages?: Array<{ __typename?: 'Language', id: string, name?: string | null } | null> | null } | null, token?: { __typename?: 'ApiToken', access_token?: string | null, expires_in?: string | null, token_type?: string | null, refresh_token?: string | null } | null } | null };

export type CreateUserMutationVariables = Types.Exact<{
  input: Types.SaveUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'SaveUserResponse', status?: string | null, errors?: Array<string | null> | null, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, languages?: Array<{ __typename?: 'Language', id: string, name?: string | null } | null> | null } | null, token?: { __typename?: 'ApiToken', access_token?: string | null, expires_in?: string | null, token_type?: string | null, refresh_token?: string | null } | null } | null };

export type UpdateMeMutationVariables = Types.Exact<{
  input: Types.SaveUserInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe?: { __typename?: 'SaveUserResponse', status?: string | null, errors?: Array<string | null> | null, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, languages?: Array<{ __typename?: 'Language', id: string, name?: string | null } | null> | null } | null, token?: { __typename?: 'ApiToken', access_token?: string | null, expires_in?: string | null, token_type?: string | null, refresh_token?: string | null } | null } | null };

export type UpdatePasswordMutationVariables = Types.Exact<{
  old_password?: Types.InputMaybe<Types.Scalars['String']['input']>;
  new_password?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword?: { __typename?: 'SaveUserResponse', status?: string | null, errors?: Array<string | null> | null, user?: { __typename?: 'User', id: string, name?: string | null, email?: string | null, languages?: Array<{ __typename?: 'Language', id: string, name?: string | null } | null> | null } | null, token?: { __typename?: 'ApiToken', access_token?: string | null, expires_in?: string | null, token_type?: string | null, refresh_token?: string | null } | null } | null };

export type ResetPasswordMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  token: Types.Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: boolean | null };

export type SendForgottenPasswordEmailMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input'];
}>;


export type SendForgottenPasswordEmailMutation = { __typename?: 'Mutation', sendForgottenPasswordEmail?: boolean | null };


export const LoginDocument = `
    mutation login($email: String, $password: String) {
  login(email: $email, password: $password) {
    status
    errors
    user {
      id
      name
      email
      languages {
        id
        name
      }
    }
    token {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
}
    `;
export const CreateUserDocument = `
    mutation createUser($input: SaveUserInput!) {
  createUser(input: $input) {
    status
    errors
    user {
      id
      name
      email
      languages {
        id
        name
      }
    }
    token {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
}
    `;
export const UpdateMeDocument = `
    mutation updateMe($input: SaveUserInput!) {
  updateMe(input: $input) {
    status
    errors
    user {
      id
      name
      email
      languages {
        id
        name
      }
    }
    token {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
}
    `;
export const UpdatePasswordDocument = `
    mutation updatePassword($old_password: String, $new_password: String) {
  updatePassword(old_password: $old_password, new_password: $new_password) {
    status
    errors
    user {
      id
      name
      email
      languages {
        id
        name
      }
    }
    token {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
}
    `;
export const ResetPasswordDocument = `
    mutation resetPassword($email: String!, $password: String!, $token: String!) {
  resetPassword(email: $email, password: $password, token: $token)
}
    `;
export const SendForgottenPasswordEmailDocument = `
    mutation sendForgottenPasswordEmail($email: String!) {
  sendForgottenPasswordEmail(email: $email)
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation<LoginMutation, LoginMutationVariables | void>({
      query: (variables) => ({ document: LoginDocument, variables })
    }),
    createUser: build.mutation<CreateUserMutation, CreateUserMutationVariables>({
      query: (variables) => ({ document: CreateUserDocument, variables })
    }),
    updateMe: build.mutation<UpdateMeMutation, UpdateMeMutationVariables>({
      query: (variables) => ({ document: UpdateMeDocument, variables })
    }),
    updatePassword: build.mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables | void>({
      query: (variables) => ({ document: UpdatePasswordDocument, variables })
    }),
    resetPassword: build.mutation<ResetPasswordMutation, ResetPasswordMutationVariables>({
      query: (variables) => ({ document: ResetPasswordDocument, variables })
    }),
    sendForgottenPasswordEmail: build.mutation<SendForgottenPasswordEmailMutation, SendForgottenPasswordEmailMutationVariables>({
      query: (variables) => ({ document: SendForgottenPasswordEmailDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


