import {api as generated} from './revision-word.generated'

export const api = generated.enhanceEndpoints({
  addTagTypes: ['revision-words', 'Lists'],
  endpoints: {
    revisionWord: {
      providesTags: ['revision-words', 'Lists'],
    },
    revisionWords: {
      providesTags: ['revision-words', 'Lists'],
    },
    createRevisionWord: {
      invalidatesTags: ['revision-words', 'Lists'],
    },
    deleteRevisionWord: {
      invalidatesTags: ['revision-words', 'Lists'],
    },
  },
})
