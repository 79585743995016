import {url} from 'src/config'

import App from '~/App'
import Layout from '~/layout/Index'
import account from './account'
import lists from './lists'
import revisionLists from './revision-lists'
import pictures from './pictures'
import practiceSessions from './practice-sessions'

export default [
  {
    path: '/',
    element: <Layout />,
    children: [
      {path: '', element: <App />},
      ...account,
      ...lists,
      ...pictures,
      ...practiceSessions,
      ...revisionLists,
    ],
  },
]

export const index = () => `${url}`
