import {createApi} from '@reduxjs/toolkit/query/react'
import {graphqlRequestBaseQuery} from '@rtk-query/graphql-request-base-query'
import {GraphQLClient} from 'graphql-request'
import {apiUrl} from 'src/config'
import {RootState} from 'src/reducers'

const client = new GraphQLClient(apiUrl, {
  credentials: 'same-origin',
}) as any

export const api = createApi({
  keepUnusedDataFor: 60,
  baseQuery: graphqlRequestBaseQuery({
    client,
    prepareHeaders: (headers, {getState}) => {
      const state = getState() as RootState
      const token = state.user?.token
      if (token) {
        // console.log(token)
        headers.set('Authorization', 'Bearer ' + token)
      }
      return headers
    },
    customErrors: ({name, stack, response}) => {
      const {errorMessage = '', errorCode = 500} = response?.errors?.length
        ? response?.errors[0]?.extensions
        : {}
      console.log(JSON.stringify(response, null, 2))
      console.log({name, stack, errorMessage, errorCode})
      return {
        name,
        message: errorMessage,
        errorCode,
        stack,
      }
    },
  }),
  endpoints: () => ({}),
})
