import classNames from 'classnames'
import {Variant} from './types'

type Props = {
  message: string
  variant: Variant
  className?: string
}

const getVariantClasses = (variant: Variant) => {
  switch (variant) {
    case 'error':
      return 'alert-error'
    case 'info':
      return 'alert-info'
    case 'primary':
      return 'alert-primary'
    case 'secondary':
      return 'alert-secondary'
    case 'success':
      return 'alert-success'
    case 'warning':
      return 'alert-warning'
  }
}

const Errors = ({message, variant, ...props}: Props) => {
  if (!message) return null
  const variantClasses = getVariantClasses(variant)
  return (
    <div
      role='alert'
      className={classNames('alert', variantClasses, props.className)}
    >
      <div className='flex space-x-2'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='stroke-current shrink-0 h-6 w-6'
          fill='none'
          viewBox='0 0 24 24'
        >
          <path
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='2'
            d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z'
          />
        </svg>
        <div className='flex flex-col'>{message}</div>
      </div>
    </div>
  )
}
export default Errors
