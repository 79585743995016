import {useTranslations} from 'src/utils/hooks'
import Layout from './Layout'
import {Link} from 'react-router-dom'
import {index} from 'src/routes/app'
import Column from '@/Column'
import Heading from '@/Heading'
import Paragraph from '@/Paragraph'

const NotFound = () => {
  const {__} = useTranslations()
  return (
    <Layout>
      <Column className='p-8 items-center'>
        <Column className='mt-8 gap-5 w-full sm:w-96'>
          <Heading size='h2'>{__('Oops, there was a problem')}</Heading>
          <Paragraph>
            {__(
              'There was a problem retrieving the resource you were looking for.'
            )}
          </Paragraph>
          <Paragraph>
            {__('Please try again, or go back to the')}{' '}
            <Link
              className='text-lg text-primary font-bold'
              to={index()}
            >
              {__('home screen')}
            </Link>
          </Paragraph>
        </Column>
      </Column>
    </Layout>
  )
}
export default NotFound
