import * as Types from './schema.generated';

import { api } from 'src/services/api';
export type WordsQueryVariables = Types.Exact<{
  language_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type WordsQuery = { __typename?: 'Query', words: Array<{ __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, score?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null }> };

export type WordQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type WordQuery = { __typename?: 'Query', word?: { __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null, score?: { __typename?: 'WordScore', id: string, user_id?: string | null, word_id?: string | null, correct?: number | null, incorrect?: number | null, phase_1?: number | null, phase_2?: number | null, phase_3?: number | null, phase_1_completed_at?: any | null, phase_2_completed_at?: any | null } | null, revisionWord?: { __typename?: 'RevisionWord', id: string, list_id?: string | null } | null, wordType?: { __typename?: 'WordType', name?: string | null } | null } | null };

export type SearchWordsQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  word?: Types.InputMaybe<Types.Scalars['String']['input']>;
  translation?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type SearchWordsQuery = { __typename?: 'Query', searchWords?: Array<{ __typename?: 'Word', id: string, word: string, full_word: string, translations?: Array<{ __typename?: 'Translation', id: string, word: string } | null> | null, lists?: Array<{ __typename?: 'List', id: string, name: string, icon?: string | null, statusName?: string | null, version?: number | null, sequence?: number | null, level?: { __typename?: 'Level', id: string, name?: string | null, sequence?: number | null } | null } | null> | null } | null> | null };

export type MoveWordMutationVariables = Types.Exact<{
  wordId: Types.Scalars['ID']['input'];
  listId: Types.Scalars['ID']['input'];
}>;


export type MoveWordMutation = { __typename?: 'Mutation', moveWord?: { __typename?: 'Word', id: string, word: string, full_word: string } | null };

export type SortWordsMutationVariables = Types.Exact<{
  listId: Types.Scalars['ID']['input'];
  ids: Array<Types.InputMaybe<Types.Scalars['ID']['input']>> | Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type SortWordsMutation = { __typename?: 'Mutation', sortWords?: boolean | null };

export type CreateWordMutationVariables = Types.Exact<{
  input: Types.SaveWordInput;
}>;


export type CreateWordMutation = { __typename?: 'Mutation', createWord?: { __typename?: 'Word', id: string } | null };

export type UpdateWordMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  input: Types.SaveWordInput;
}>;


export type UpdateWordMutation = { __typename?: 'Mutation', updateWord?: { __typename?: 'Word', id: string } | null };

export type DeleteWordMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteWordMutation = { __typename?: 'Mutation', deleteWord?: { __typename?: 'Word', id: string } | null };

export type QuizWordsMutationVariables = Types.Exact<{
  language_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  list_id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type QuizWordsMutation = { __typename?: 'Mutation', quizWords: Array<{ __typename?: 'Word', id: string, word_type_id?: string | null, word: string, article?: string | null, plural?: string | null, full_word: string, translations?: Array<{ __typename?: 'Translation', word: string } | null> | null }> };


export const WordsDocument = `
    query words($language_id: ID, $list_id: ID, $limit: Int) {
  words(language_id: $language_id, list_id: $list_id, limit: $limit) {
    id
    word_type_id
    word
    article
    plural
    full_word
    translations {
      word
    }
    score {
      id
      user_id
      word_id
      correct
      incorrect
      phase_1
      phase_2
      phase_3
      phase_1_completed_at
      phase_2_completed_at
    }
    revisionWord {
      id
      list_id
    }
  }
}
    `;
export const WordDocument = `
    query word($id: ID) {
  word(id: $id) {
    id
    word_type_id
    word
    article
    plural
    full_word
    translations {
      word
    }
    score {
      id
      user_id
      word_id
      correct
      incorrect
      phase_1
      phase_2
      phase_3
      phase_1_completed_at
      phase_2_completed_at
    }
    revisionWord {
      id
      list_id
    }
    wordType {
      name
    }
  }
}
    `;
export const SearchWordsDocument = `
    query searchWords($id: ID, $word: String, $translation: String) {
  searchWords(id: $id, word: $word, translation: $translation) {
    id
    word
    full_word
    translations {
      id
      word
    }
    lists {
      id
      name
      icon
      statusName
      version
      sequence
      level {
        id
        name
        sequence
      }
    }
  }
}
    `;
export const MoveWordDocument = `
    mutation moveWord($wordId: ID!, $listId: ID!) {
  moveWord(wordId: $wordId, listId: $listId) {
    id
    word
    full_word
  }
}
    `;
export const SortWordsDocument = `
    mutation sortWords($listId: ID!, $ids: [ID]!) {
  sortWords(listId: $listId, ids: $ids)
}
    `;
export const CreateWordDocument = `
    mutation createWord($input: SaveWordInput!) {
  createWord(input: $input) {
    id
  }
}
    `;
export const UpdateWordDocument = `
    mutation updateWord($id: ID!, $input: SaveWordInput!) {
  updateWord(id: $id, input: $input) {
    id
  }
}
    `;
export const DeleteWordDocument = `
    mutation deleteWord($id: ID!) {
  deleteWord(id: $id) {
    id
  }
}
    `;
export const QuizWordsDocument = `
    mutation quizWords($language_id: ID, $list_id: ID, $limit: Int) {
  quizWords(language_id: $language_id, list_id: $list_id, limit: $limit) {
    id
    word_type_id
    word
    article
    plural
    full_word
    translations {
      word
    }
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    words: build.query<WordsQuery, WordsQueryVariables | void>({
      query: (variables) => ({ document: WordsDocument, variables })
    }),
    word: build.query<WordQuery, WordQueryVariables | void>({
      query: (variables) => ({ document: WordDocument, variables })
    }),
    searchWords: build.query<SearchWordsQuery, SearchWordsQueryVariables | void>({
      query: (variables) => ({ document: SearchWordsDocument, variables })
    }),
    moveWord: build.mutation<MoveWordMutation, MoveWordMutationVariables>({
      query: (variables) => ({ document: MoveWordDocument, variables })
    }),
    sortWords: build.mutation<SortWordsMutation, SortWordsMutationVariables>({
      query: (variables) => ({ document: SortWordsDocument, variables })
    }),
    createWord: build.mutation<CreateWordMutation, CreateWordMutationVariables>({
      query: (variables) => ({ document: CreateWordDocument, variables })
    }),
    updateWord: build.mutation<UpdateWordMutation, UpdateWordMutationVariables>({
      query: (variables) => ({ document: UpdateWordDocument, variables })
    }),
    deleteWord: build.mutation<DeleteWordMutation, DeleteWordMutationVariables>({
      query: (variables) => ({ document: DeleteWordDocument, variables })
    }),
    quizWords: build.mutation<QuizWordsMutation, QuizWordsMutationVariables | void>({
      query: (variables) => ({ document: QuizWordsDocument, variables })
    }),
  }),
});

export { injectedRtkApi as api };


