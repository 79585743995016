import TabLink, {TablLinkProps} from './TabLink'

type Props = {
  items: TablLinkProps[]
}
const TabNav = ({items}: Props) => {
  return (
    <div
      role='tablist'
      className='tabs tabs-bordered'
    >
      {items.map((item, i) => {
        return (
          <TabLink
            key={i}
            {...item}
          />
        )
      })}
    </div>
  )
}
export default TabNav
