import {useSelector} from 'react-redux'
import {selectToken} from 'src/reducers/user'
import List from '~/lists/Index'
import Login from './account/Login'

const App = () => {
  const token = useSelector(selectToken)
  if (!token) return <Login />
  return <List />
}

export default App
