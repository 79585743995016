import {ReactNode} from 'react'
import {Helmet} from 'react-helmet-async'
import {appTitle} from 'src/config'

const PageTitle = ({children}: {children: ReactNode}) => {
  return (
    <Helmet>
      <title>
        {children} | {appTitle}
      </title>
    </Helmet>
  )
}
export default PageTitle
