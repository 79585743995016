import classNames from 'classnames'
import {InputHTMLAttributes, ReactNode, forwardRef} from 'react'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  type?: string
  placeholder?: string
  value: string
  onChange: (value: any) => void
}
const Input = forwardRef(
  ({type, placeholder, value, onChange, ...props}: Props, ref: any) => {
    return (
      <input
        {...props}
        ref={ref}
        type={type || 'text'}
        placeholder={placeholder}
        className={classNames('input input-bordered', props.className)}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    )
  }
)
export default Input
