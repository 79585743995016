import {ButtonHTMLAttributes, forwardRef, ReactNode} from 'react'

const getVariantClasses = (variant: string = 'primary') => {
  switch (variant) {
    case 'primary':
      return 'btn-primary'
    // return 'text-white bg-purple-500 focus:ring-purple-300 hover:bg-purple-600'
    case 'success':
      return 'btn-success text-white'
    case 'error':
      return 'btn-error'
    case 'secondary':
      return 'btn-secondary'
    case 'accent':
      return 'btn-accent'
  }
  // return `btn-${variant}`
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  children: ReactNode
  variant?: string
}
const Button = forwardRef(({onClick, children, ...props}: Props, ref: any) => {
  const defaultClasses = getVariantClasses(props?.variant)
  const className = `btn ${defaultClasses} ${props.className}`
  // console.log({defaultClasses, className})
  return (
    <button
      {...props}
      ref={ref}
      className={className}
      onClick={onClick}
    >
      {children}
    </button>
  )
})
export default Button
